<template>
  <div>
    <router-view v-slot="{ Component }">
      <keep-alive include="Moment">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "Contents"
}
</script>
