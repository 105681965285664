<template>
  <img class="image" :src="message.message" @click="onImage(message.message)" />
</template>

<script>
export default {
  name: "ImageItem",
  emits: [],
  props: { message: { type: Object } },
  data() {
    return {
    }
  },
  methods: {
    onImage: function (url) {
      if (url != '') {
        window.open(url, '_blank');
      }
    },
  }
}
</script>

<style scoped>
.image {
  background-color: black;
  border-radius: 10px;
  width:200px;height:200px;
}
</style>