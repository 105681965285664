export default {
  app_name: 'viewchat',
  app_error: {
    title: 'Произошла непредвиденная ошибка.',
    msg: 'Информация об ошибке передана команде поддержки. Пожалуйста, попробуйте снова.',
    network: 'Произошла ошибка сетевого соединения.',
    other_device_login: 'На другом устройстве выполнена регистрация с тем же номером.',
    image_not_supported: 'Неподдерживаемый формат изображения.',
    upload_file_not_exist: "Файл для загрузки отсутствует.",
  },
  common: {
    male_short: 'M',
    female_short: 'F',
    male: "Мужчина",
    female: "Женщина",
    age: "",
    preparing: "Подготовка",
    yes: "Да",
    no: "Нет",
    ok: "OK",
    cancel: "Отмена",
    point: "Баллы",
    cash: "Кэш",
    bread: "Хлеб",
    close: "Закрыть",
    delete: "Удалить",
    second: "Секунда",
    send: "Отправить",
    create: "Создать",
    submit: "Подать",
    like: "Нравится",
    jjim: "Подписаться",
    message: "Сообщение",
    hits: "Просмотры",
    next: "Далее",
    name: "Имя",
    phone_number: "Номер телефона",
    years: "Возраст",
    support_type: "point_system-error_payment-error_other",
    max: "Максимальный",
    point_alert: "Недостаточно баллов!",
    bread_alert: "Недостаточно средств!",
    done: "Готово",
    top_title: "<b>{nickname}</b>'s Профиль",
    top_subtitle: "Вы можете использовать ViewChat с различными функциями и настройками профиля.",
  },
  msg: {
    not_implemented_yet: "Сервис находится в подготовке.",
    please_wait: "Пожалуйста, подождите...",
    input_intro: "Выбор описания",
    select_sex: "Выбор пола",
    select_age: "Выбор возраста",
    input_nickname: "Пожалуйста, введите никнейм.",
    nickname_too_short: "Никнейм не может быть короче 2 символов.",
    nickname_too_long: "Никнейм должен быть не длиннее 5 символов.",
    input_message: "Введите сообщение",
    sent_message: "Сообщение отправлено.",
    software_update: "Обновление программного обеспечения",
    select_country: "Выбор страны",
    like_success: "Лайк успешно добавлен",
    please_select_photo: "Выбор изображения",
    input_photo_content: "Пожалуйста, напишите описание истории.",
    zzalbe_registered: "Изображение успешно загружено!",
    zzalbe_registered_with_point: "{point}  Получены баллы.",
    zzalbe_success: "Изображение будет опубликовано после проверки.",
    zzalbe_comment: "Пожалуйста, введите комментарий.",
    block_user: "{nickname} Заблокировать",
    send_msg_disabled: "Отправка сообщения не удалась.",
    video_request_cancel: "{second}  Отмена автоматически через несколько секунд.",
    video_waiting_time_out: " Время на запрос видео истекло. Пожалуйста, попробуйте позже.",
    check_in: "Вы отметились. Не забудьте отметиться завтра.",
    profile_saved: "Сохранение информации профиля",
  },
  header_bar: {
    live: 'В реальном времени',
    recom: 'Рекомендации',
    photo: 'Фотографии',
    moment: 'Моменты',
    chat: 'Личное сообщение',
    profile: 'Профиль',
    follow: 'Управление подписками',
    charge: 'Пополнение',
    history: 'История использования',
    guide: ' Инструкции',
    point: 'Баллы:',
    agreement: "Условия и политика",
    jp_purchase_policy: 'Закон о специфических торговых операциях',
    usage: 'Условия использования',
    privacy: 'Политика конфиденциальности',
    ugc: 'Контракт на контент',
    youth_policy: 'Политика защиты несовершеннолетних',
    company_name: 'BC INNOVATION',
    boss: 'CEO : Hyunsuk Park',
    biz: 'Biz No : 227-81-20333',
    southwest: '305-1, 39 Cheongdae-ro 204beon-gil, Sokcho-si, Gangwon-do',
    tel: ' TEL : 1599-1401',
    email_prefix: 'E-Mail : Контакт',
    email_suffix: 'bcinnovation.co.kr',
    login: 'Вход',
    logout: 'Выход',
    logout_confirm: 'Вы уверены, что хотите выйти?',
    top_button: "Наверх",
    viewchat_point: "Баллы ViewChat",
    view_more: "Еще",
    viewchat_live: "Прямой эфир",
  },
  splash: {
    start_with_sns: ' Начать с социальных сетей',
    view_chat_login: 'Вход через электронную почту',
    view_chat_signup: 'Регистрация',
    forgot_password: 'Восстановление пароля',
  },
  reset_password: {
    title: 'Сброс пароля',
    button_title: 'Сброс пароля',
    completed: 'Пароль был сброшен.',
  },
  login: {
    title: "viewchat - видеочат с видео",
    login_01: "Добро пожаловать в viewchat.",
    login_02: "Приятного общения в видеочате, приятных видеозвонков, viewchat.",
    guide: 'Пожалуйста, введите правильный ID и пароль.',
    input_id: 'Введите ID',
    input_pwd: 'Введите пароль',
    auto_login: 'Автоматический вход',
    login: 'Вход',
  },
  phone: {
    verify: "Подтвердить",
    exit: "Выйти",
    str_adult_auth_title: "Title",
    str_adult_auth_text1_1: "Text1-1",
    str_adult_auth_text1_2: "Text1-2",
    str_adult_auth_text3_1: "Text3-1",
    str_adult_auth_text3_2: "Text3-2",
    str_adult_auth_text3_3: "Text3-3",
  },
  age_agreement: {
    title: 'Согласие по возрасту',
    text1: 'Вам больше 18 лет?',
    text2: 'Этот сервис предназначен только для взрослых!',
    reject: 'Отказаться',
    agree: 'Согласиться'
  },
  signup: {
    only_10_sec: 'Регистрация за 10 секунд.,',
    sign_up: 'Зарегистрируйтесь, пожалуйста!',
    id_placeholder: 'ID (адрес электронной почты))',
    invalid_id: 'Неверный ID (адрес электронной почты).',
    pwd: 'Пароль',
    pwd_confirm: 'Подтверждение пароля',
    pwd_confirmed: 'Пароль подтвержден!',
    agree_to_usage: 'Согласен с условиями использования и политикой конфиденциальности.',
    select_profile: 'Выбор профильного изображения',
    select_photo: 'Выбор изображения',
    nickname_placeholder: 'Введите никнейм (максимум 6 символов)',
    yrs: 'Возраст',
    pay_attention: '* Обратите внимание, что изменение после регистрации невозможно!',
    start: ' Начать',
    title: "Регистрация",
    signup: "Регистрация",
    msg_input_nickname: "Пожалуйста, введите никнейм!",
    msg_input_gender: "Пожалуйста, выберите пол!",
    msg_input_motto: "Пожалуйста, выберите тему!",
    msg_input_age: "Пожалуйста, выберите возраст!",
    gender_warning: "Пол нельзя изменить после регистрации.",
    msg_nickname_too_short: "Никнейм не может быть менее 2 символов.",
    msg_nickname_too_long: "Никнейм не может быть длиннее 5 символов.",
    send_cert_key: "Отправить код подтверждения",
    verify_cert_key: "Подтвердить код",
    msg_input_email: "Пожалуйста, введите адрес электронной почты!",
    msg_input_cert_key: "Пожалуйста, введите код подтверждения.",
    msg_sent_cert_key: "Код подтверждения отправлен на вашу электронную почту. Пожалуйста, введите его в поле ниже.",
    cert_key_placeholder: "Код подтверждения",
    email_verified: "Электронная почта подтверждена",
    msg_verify_email: "Пожалуйста, введите адрес электронной почты!",
    msg_input_password: " Пожалуйста, введите пароль!",
    msg_confirm_password: "Пожалуйста, подтвердите пароль!",
  },
  live: {
    title: "{nickname} Добро пожаловать в viewchat live!</b>",
    subtitle: "{message_count}У вас есть новые сообщения и  {notification_count} новые уведомления.",
    all_country: "Все страны",
    more_country: "Подробнее о странах",
    real_time_hot_country: "Популярные страны в реальном времени:",
    ranking: 'По рейтингу',
    recent_login: 'По подключению',
    video_chatting: " В чате",
    more: "Подробнее",
  },
  recommend: {
    title: "Рекомендуемые участники viewchat",
    subtitle: "Эти участники рекомендованы для онлайн видеочата viewchat",
    recommend_today: 'Сегодня',
    recommend_recom: 'Рекомендуемые',
    recommend_members: 'Участники',
    recommend_users: "Эти участники сейчас онлайн.",
    total_star: " Всего",
    total_star_2: "",
    total_star_3: "",
    recv_star: "Получено!",
    album: "Фотоальбом",
    jjim: "Добавить в избранное",
    message: "Отправить личное сообщение",
    video: " Запросить видеочат",
    intro: "Описание",
    average_video_time: "Среднее время видео",
    video_time_below_15s: "Менее 15 секунд",
    video_time_below_1min: "Менее 1 минуты",
    video_time_below_3min: "Менее 3 минут",
    video_time_above_3min: "Более 3 минут",
    average_review_point: "Средний балл видео",
    favourite_factor: "Симпатия",
    belief_factor: "Доверие",
    manner_factor: "Манеры",
    beauty_factor: "Внешность",
    delete: "Удалить",
    msg: 'Сообщение',
  },
  photo: {
    title: "ViewChat <b>Moment</b> Contents",
    subtitle: "Это приятный момент в viewchat.",
    category: "Полный профиль",
    category_types: "all1_zzalbe_gif_recommend_me",
    create: "Загрузка поста",
    guide: "Вот фотографии",
    hit: "Просмотры ",
    write_title: "Создать момент",
    write_guide: "Нельзя создать без изображения.",
    write_desc: "Попробуйте создать интересный контент прямо сейчас.",
    visit: " Посещения",
    recommend: "Рекомендации",
    pick_photo: "Чтобы загрузить фотографию, кликните здесь.",
    delete_success: "Удалено.",
    emoticon_delete: "Удалить эмодзи",
    emoticon_delete_confirm: "Вы уверены, что хотите удалить?",
    emoticon_delete_success: "Удалить",
    upload: "Загрузить",
    bookmark: "Закладки",
    like: "Нравится",
    gift: "Подарок",
    report: "Пожаловаться",
    prev_contents: " Предыдущий контент",
    next_contents: "Следующий контент",
    content_hashtag: 'Контент и хэштег',
    hashtag: '# Хэштег',
    hashtag_hint: '*Нажмите на выбранный хэштег еще раз, чтобы снять выбор.',
    public_options: 'Опции публичного доступа',
    free: 'Бесплатно',
    followers: 'Подписчики(Бесплатно)',
    paid: 'Подписчики(Платно)',
    paid_hint: '* Платные руководства',
    hashtag_select: 'Выбор хэштега',
    hashtag_select_hint: 'Вы можете выбрать до 3 хэштегов.',
    price_guideline: 'Платные руководства',
    price_guideline_photo_1: 'Одна фотография: ',
    price_guideline_photo_2: '20 {price} букетов',
    price_guideline_video_1: 'Одно видео: ',
    price_guideline_video_2: '60 {price} букетов',
    price_guideline_audio_1: 'Один аудиофайл: ',
    price_guideline_audio_2: '30 {price} букетов',
    price_must_be_nonzero: 'Цена не может быть равна 0.',
    price_must_below: 'Цена должна быть ниже {maxPrice}.',
    public_to_followers: 'Открыто для подписчиков',
  },
  video: {
    video_call_allow_necessary_permissions: "Пожалуйста, разрешите доступ.",
    video_01: "{nickname} Видео вызов отменён.",
    video_02: "Подтвердите завершение",
    video_03: "Вы уверены, что хотите завершить видеочат?",
    video_05: "Завершить видеочат.",
    video_07: "Недостаточно очков для чата!",
    video_08: "Поздравляем! Вы заработали {Кэш}.",
    video_09: "Видеочат с {nickname} завершен из-за недостатка очков.",
    video_10: "Запрос на видеочат",
    video_11: "Недостаточно очков.",
    video_12: "Ой! Пользователь временно отсутствует. Пожалуйста, попробуйте снова позже.",
    video_14: "Ой! Пользователь временно отсутствует. Пожалуйста, попробуйте снова позже.",
    video_22: "Отказ",
    video_23: "Принять",
    video_24: "Новый запрос на видеочат",
    video_25: "Новый глобальный запрос на видеочат",
    video_26: "Автоматический отказ через {секунд}.",
    video_27: "Одинаковый пол. Пожалуйста, запросите другой пол.",
    video_28: " Невозможно использовать веб-камеру.",
    video_profile_02: "{nickname} добавлен в избранное.",
    global_user: "Глобальный пользователь",
    global_user_warning: "Это глобальный пользователь..<br/>Будет начислено вдвое больше очков.",
    video_call_request: "Запрос на видеочат...",
    video_waiting_time_out: "Время запроса истекло. Попробуйте еще раз позже.",
    review_title: " Как был видеочат??",
    review_select_rate: "'{review}' Выберите оценку.",
    review_select_rate_1: "Выберите ниже.",
    review_select_rate_2: "<b>Оценки не накапливаются.</b><br/>Пожалуйста, дайте точную оценку.",
    review_skip: "Пропустить",
    review_success: "Оценено.",
    video_call_received: 'Запрос на видеочат',
    video_call_finished: 'Завершить видеочат {time}',
    after_8_seconds: 'Вы можете отправить подарок через 8 секунд',
  },
  message: {
    message_09: "Удалить все сообщения",
    message_10: " Прочитать все сообщения",
    message_11: "Здесь есть сообщения.",
    message_read: "Прочитано",
    message_unread: "Не прочитано",
    message_delete_title: "Удалить сообщение",
    message_delete_confirm: "Вы уверены, что хотите удалить все сообщения?",
    message_read_title: "Прочитать сообщение",
    message_read_confirm: " Вы уверены, что хотите прочитать все сообщения?",
    message_sent_photo: "Отправить фото",
    message_sent_gift: "Отправить подарок",
    message_content_delete: "Удалить контент",
    message_content_delete_confirm: "Вы уверены, что хотите удалить сообщение?",
    message_content_placeholder: "Пожалуйста, введите сообщение.",
    message_content_hint: "Введите сообщение.",
    msg_send_msg_disabled: "Невозможно отправить сообщение.",
    msg_block_user: "{nickname}이(가) Вы заблокированы.",
    msg_like_success: "Рекомендовано.",
    msg_sent_message: "Сообщение успешно отправлено.",
    photo: " Фото",
    jjim: "Избранное",
    block: "Заблокировать",
    report: "Пожаловаться",
    message_input: " Пожалуйста, введите сообщение.",
    send_message: "Отправить сообщение",
    send_desc: "С вашего счета будет списано {point}P.",
    unread_messages: 'Непрочитанные сообщения',
    viewchat_messages: ' Наслаждайтесь viewchat  <b>Личными сообщениями</b>',
    viewchat_messages_intro: 'Это сообщения диалогов, которые вы обмениваетесь с участниками. Наслаждайтесь хорошими разговорами!',
    viewchat_messages_info: 'Всего <b>{total_count}</b> сообщений и <b>{unread_count}</b> непрочитанных сообщений.',
    warning_message: ' В случае нарушения законодательства о защите несовершеннолетних и правил регулирующих органов, вы можете быть ограничены в использовании и подвергнуться юридическим последствиям.',
  },
  profile: {
    title: "Редактировать профиль",
    average_video_time: "Среднее время видео",
    average_review_point: "Средний балл отзывов",
    short_average_video_time: "Среднее время видео",
    short_average_review_point: "Средний балл отзывов",
    video_time_below_15s: "Менее 15 секунд",
    video_time_below_1min: "Менее 1 минуты",
    video_time_below_3min: "Менее 3 минут",
    video_time_above_3min: " Более 3 минут",
    favourite_factor: "Симпатия",
    belief_factor: "Доверие",
    manner_factor: "Манеры",
    beauty_factor: " Внешность",
    profile_intro: "Описание",
    profile_notify: "Уведомления",
    profile_12: "Никнейм",
    profile_13: "Пожалуйста, введите не более 8 символов.",
    profile_14: "Возраст",
    profile_15: "Пол",
    profile_16: "(не подлежит изменению)",
    profile_23: "Сохранить",
    profile_24: "Редактировать",
    profile_25: "Видео профиль",
    profile_26: "Значок по умолчанию",
    dibs: "Избранное",
    recommend: "Рекомендовать",
    album: "Альбом",
    message: "Отправить личное сообщение",
    voice_chat: "Голосовой чат",
    video_chat: "Видеочат",
    favorite: "Симпатия",
    belive: "Доверие",
    manner: "Манеры",
    beauty: "Внешность",
    photos: " Фото",
  },
  album: {
    title: "Альбом",
    confirm: " Вы уверены, что хотите удалить?"
  },
  more: {
    more_09: "Пополнить",
    more_10: "Подарочные предметы",
    more_11: "Гифтекон",
    more_13: "История использования",
    more_14: "Связаться с нами",
    more_15: " Инструкции по использованию",
    more_16: "Объявления",
    more_21: "Проверка версии",
    more_22: "Проверка последней версии.",
    more_30: "Мой альбом",
    more_31: "Проверка присутствия",
    more_32: "Объявления",
    more_33: "Поделиться",
    more_34: "Поделитесь и получите баллы.",
    more_35: " Редактировать профиль",
    more_36: " Внимание!",
    more_37: "Остерегайтесь фишинговых мошенничеств",
    more_38: "Участник",
    more_39: '50 баллов',
    more_40: 'Настройки аккаунта',
    more_41: 'Переключить',
    more_42: 'Уведомления о видео включены',
    more_43: 'Рекомендовать',
    more_44: 'Настройки уведомлений',
    more_45: 'Кто хочет стать вашим другом онлайн?',
    more_46: 'Остерегайтесь новых фишинговых мошенничеств!',
    more_47: 'Если вас направляют в другие мессенджеры или приложения, это может быть фишинговое мошенничество.',
    leave: 'Удалить аккаунт',
    leave_message: 'При удалении аккаунта все накопленные баллы и кэш будут утеряны, и повторная регистрация возможна только через 150 дней.\n\nВы уверены, что хотите удалить аккаунт?',
    refund: 'Запрос на возврат',
  },
  zzalbe: {
    message: "Сообщение",
    gift: "Подарок",
    delete: "Удалить",
    reply: "Ответ",
    msg: "Msg",
  },
  notify: {
    setting_video_receive: "Уведомления о видео",
    setting_video_receive_global: "Глобальные уведомления о видео",
    setting_sound: "Звук",
    setting_vibration: "Вибрация",
    setting_on: "ON",
    setting_off: "OFF",
  },
  log: {
    title: "История использования",
    log_list_02: "История использования баллов",
    log_list_03: "История использования кэша",
    point: "Баллы: ",
    cash: " Кэш: "
  },
  charge: {
    title: "Пополнение",
    charge_please: "Инструкция по ручному пополнению",
    direct_request: "Ручной запрос",
    desc1: "Account : NewYorkBank  304101-04-349026",
    desc2: "Выберите элемент для пополнения и оставьте имя вкладчика, после чего мы обработаем запрос.",
    by_app: "by App",
    open_app: "Открыть приложение",
    account_holder_name: "Имя вкладчика",
    input_name: "(Введите имя.)",
    input_phone_number: "(Введите номер телефона.)",
    direct_success: "Запрос успешно отправлен",
    amount: "Сумма:",
    charge_point: "Баллы:",
    charge_items: "Пожалуйста, пополните элемент",
    charge_direct: "Ручное<br/>пополнение",
    charge_purchase: "Купить",
    charge_discount: "Скидка",
    charge_item_price: "$",
    vip_item_price: "W",
    charge_item_point: "P",
    charge_vip_title: "VIP элементы",
    charge_vip_comment: " (Только для VIP-пользователей)",
    charge_vip_unavailable: "Недоступно",
    charge_vip1_price: "50,000W",
    charge_vip2_price: "100,000W",
    charge_vip1_point: "35,000P",
    charge_vip2_point: "70,000P",
    charge_11: "Бесплатная зарядная станция",
    charge_12: "Бесплатная зарядная станция 1",
    charge_14: "Бесплатная зарядная станция 2",
    charge_15: "Бесплатная зарядная станция 3",
    charge_16: "Обменять",
    charge_17: "Кэш > Баллы",
    charge_18: "Баллы > Кэш",
    purchase_success: "{point} баллов были зачислены {nickname}.",
    paypal_purchase_rewarded: "{point} баллов было начислено.",
    message_goto_app: 'Эта функция доступна только в приложении SuperCam. Хотите открыть приложение?',
    paypal_purchase_success: 'Покупка успешна!\n Пожалуйста, подождите немного, пока будут начислены платные баллы.',    
    guide_title: 'Информация о баллах',
    guide_text1: '1. Этот сервис в основном бесплатный. Однако для использования некоторых функций необходимы баллы.',
    guide_text2: '2. Для отправки ЛС, видеозвонков или подарков можно использовать баллы.<br/>Отправка ЛС - 15P за сообщение.<br/>Видеозвонок - 200P за минуту.<br/>Подарки - от 10 до 900P в зависимости от типа предмета.',
    item_select: 'Предметы',
  },
  gifticon: {
    gifticon_03: "Имя",
    gifticon_04: "Цена",
    gifticon_05: "Телефон номер",
    gifticon_06: "Купить",
    gifticon_07: "Информация",
    gifticon_10: "Покупка",
    gifticon_11: "Бренд",
    gifticon_12: "Комиссия",
    gifticon_13: "Срок действия",
    gifticon_14: "Срок действия",
    gifticon_15: "Введите номер телефона.",
    gifticon_16: "Покупка гифтекона",
    gifticon_02: "Запрос отправлен.",
    request_gifticon: "Запрос на гифтекон",
    category_select: "Выберите категорию.",
    category_1: "Товары",
    category_2: "Магазины",
    category_3: "Купоны",
    category_4: "Фастфуд",
    category_5: "Хлеб",
    category_6: "Кофе",
    category_7: "Рестораны",
    category_8: "Фотографии",
    category_9: "Красота",
    category_10: "Путешествия",
    category_11: "Цифровые товары",
    category_12: "Дети",
  },
  refund: {
    refund_agree_10: "Запрос на возврат",
    refund_agree_11: "Важная информация",
    refund_agree_12: "Информационное сообщение",
    refund_agree_13: "Информация",
    refund_agree_14: "Информационное сообщение",
    refund_03: "Введите имя владельца аккаунта",
    refund_04: "Введите номер удостоверения личности",
    refund_05: "Введите номер телефона",
    refund_06: " Введите адрес",
    refund_08: "Введите номер счета",
    refund_10: "Минимальная сумма для вывода - 30,000.",
    refund_11: "Недостаточно кэша.",
    refund_12: " Запрос на возврат успешно отправлен.",
    refund_13: "Мой кэш",
    refund_14: "Владелец аккаунта",
    refund_15: "Номер телефона",
    refund_16: "Номер удостоверения личности",
    refund_18: "Адрес",
    refund_19: " Банк",
    refund_20: "Номер счета",
    refund_21: "Введите номер счкта",
    refund_22: "Сумма для вывода - (от 30,000)",
    refund_27: "Сумма возврата",
    refund_28: "Запрос на возврат",
    refund_29: "Выбор банка",
    refund_30: "(Бонус",
    refund_31: "Добавление)",
    refund_bank: "bank1_bank2_bank3_bank4",
    owner: "Введите имя владельца счета",
    phone_desc: " Введите номер телефона только цифрами",
    resident_desc: "(-)Введите номер удостоверения личности без дефисов)",
    address_desc: " Пожалуйста, введите адрес",
    cash_desc: "Введите запрашиваемую сумму кэша вручную (кратную 10,000)",
    top_hint: '当日振込をご希望の場合は20時までに振込申請をしていただければ当日振込可能です。',
    middle_hint: '入金申請したキャッシュ-（1万キャッシュ以上から申請可能です）',
    bottom_hint: '【振込手数料】恐れ入りますが振込手数料はお客様の負担でお願いいたします。',
  },
  category: {
    category_1: "Товары",
    category_2: "Магазины",
    category_3: "Купоны",
    category_4: "Фастфуд",
    category_5: "Хлеб",
    category_6: "Кофе",
    category_7: "Рестораны",
    category_8: "Фильмы",
    category_9: "Красота",
    category_10: "Путешествия",
    category_11: "Цифровые товары",
    category_12: "Воспитание детей",
  },
  transfer: {
    title: "Обменять",
    bread: "Хлеб: ",
    point2bread: "Обменять баллы на хлеб",
    bread2point: "Обменять хлеб на баллы",
  },
  ask: {
    ask_email: "Электронная почта",
    ask: "Связаться",
    desc: "Введите точно.",
    asked: "Запрос отправлен.",
    asked_message1: "ㆍ Message1.",
    asked_message2: "ㆍ Message2.",
    asked_message3: "ㆍ Message3.",
    ok: "Подтвердить",
    ask_category: "Категория",
    ask_content: "Содержание",
    ask_01: "Введите адрес электронной почты.",
    ask_02: "Выберите категорию.",
    ask_03: "Введите текст запроса.",
    ask_04: "Выберите фото чека.",
    ask_05: "Нет ответа...",
    ask_06: "Запрос",
    ask_08: "Запрос отправлен.",
    ask_09: "Запрос отправлен.",
    ask_11: "Загрузка изображения:",
    ask_12: "* Важная информация...",
    ask_13: "Проверка ответа",
    ask_14: "Удалить аккаунт",
    ask_15: " ",
    agree: "Я согласен с условиями",
    agree_content: "Запросы и ответы будут отправлены по электронной почте, адрес электронной почты собирается только для ответа.",
    upload_desc: "Содержимое, не относящееся к запросу, изображения и ненормативная лексика будут проигнорированы."
  },
  announce: {
    title: "Уведомление",
  },
  report: {
    title: "Пожаловаться",
    warning: "Выберите причину жалобы.",
    reason: "Неприемлемый контент_Домогательства_Сексуальное содержание_Спам_Другое",
    placeholder: 'Причина жалобы',
    please_select_category: 'Выберите причину жалобы.',
    success: "Жалоба отправлена.",
  },
  notify_setting: {
    title: "Настройки уведомлений",
    video_notify: "Видео уведомления",
    video_notify_global: "Глобальные видео уведомления",
    video_notify_sound: "Звуковые уведомления",
    video_notify_vibrate: "Виброуведомления",
  },
  country: {
    title: "Все страны",
    search: "Поиск",
    real_country: "Реальные страны",
    rank: "Рейтинг",
    more_country: "Больше стран",
    max_country: "Максимум 5 стран",
    hot: "Популярные",
    alpha: "В алфавитном порядке",
    thail: "Таиланд",
    viet: "Вьетнам",
    rus: "Россия",
    real_coun: "Реальное время",
    country1: "Таиланд",
    country2: "Украина",
    country3: "Россия",
    country4: "Соединенные Штаты",
    country5: "Тайвань",
    country6: "AAA",
    warning1: "Только одна страна",
    country_code_list: "tha_uga_rus_usa_twn_alb",
    country_list: "Thailand_Uganda_Russian_United States_Taiwan_AAAA",
    all_country: "Все страны",
    apply: "Применить",
    turn_off_global: "Отключить глобальный режим",
  },
  guide: {
    title: "Информация",
    usage_agreement: "Условия использования",
    privacy_policy: "Политика конфиденциальности",
  },
  gift_items: {
    title: 'Подарки',
    buy: 'Купить',
    fingerheart: 'Сердце из пальцев',
    flowers: 'Букет цветов',
    ring: 'Бриллиантовое кольцо',
    palace: 'Дворец',
    megaheart: 'Мега-сердце',
    gift_ttem_names: ['Сердце из пальцев", "Букет цветов", "Бриллиантовое кольцо", "Дворец", "Мега-сердце'],
  },
  gift_item_purchase: {
    title: 'Покупка подарка',
    amount: "Сумма:",
    price: "Очки:",
  },
  gift_item_send: {
    title: 'Отправить подарочный предмет',
    hint: 'Нажмите кнопку отправки после выбора предмета',
    success: 'Отправлен подарочный предмет.',
  },
  gift_item_send_amount: {
    title: 'Отправить сумму',
  },
  follow: {
    follower: 'Подписчики',
    following: 'Подписки',
    block: 'Заблокировать',
  },
  album: {
    profile_edit: 'Редактировать профиль',
    write: 'Написать пост',
    posts: 'Посты',
    likes: 'Лайки',
    followers: 'Подписчики',
    followings: 'Подписки',
    motto_hint: 'Введите описание.',
    input_motto: 'Введите описание!',
    save: 'Сохранить',
    video_call: 'Запрос видео',
    send_message: 'Отправить сообщение',
    follow: 'Подписаться',
    unfollow: 'Отписаться',
    must_follow_each_other_text1: 'Нет постов от подписчиков.',
    must_follow_each_other_text2: 'Попросите подписчиков опубликовать посты!',
    no_photos: 'Нет постов.',
  },
  story_purchase: {
    title: 'Хотите купить этот контент?',
    description1: 'Купленный контент можно хранить только в приложении.',
    description2:
      'Запрещается сохранение и распространение. Пользователь несет полную ответственность за нарушение.',
    item_not_enough: 'Недостаточно элементов',
    purchase_confirm: 'Хотите совершить покупку?',
    purchase_success: 'Вы успешно купили этот контент.',
  },
};
