<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_review" @click="$event.stopPropagation()">
        <div class="top">
          <div class="profile">
            <UserImage :gender="userInfo.user_sex" :image="userInfo.user_profile_image"/>
          </div>
          <div class="info">
            <div class="text">
              <h2>
                {{ userInfo.user_nickname + ', ' + (userInfo.user_sex == '0' ? $t('common.male_short') : $t('common.female_short')) + userInfo.user_age}}
              </h2>
              <span>{{ userInfo.user_motto }}</span>
            </div>
            <div class="btn">
              <img src="../../assets/img/user_profile_gift_icon.png">
              <span>{{ currency(0) }}</span>
              <img src="../../assets/img/user_profile_like_icon.png">
              <span>{{ currency(0) }}</span>
              <img src="../../assets/img/user_profile_moments_icon.png">
              <span>{{ currency(0) }}</span>
            </div>
          </div>
        </div>
        <div class="star-wrapper">
          <label class="guide1">
            {{ $t('video.review_select_rate', { review: reviewParams[reviewParamIdx] }) }}
          </label>
          <vue3-star-ratings
            v-model="rating"
            :showControl="false"
            inactiveColor="#b1b1b1"
            starColor="#ffbb00"
            starSize="38"
          />
          <a href="javascript:" @click="onOk">{{ $t('common.done') }}</a>
        </div>
        <div class="close">
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/popup_close_white.png">
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./VideoReview.scss"></style>

<script>
import { randomNumberInRange, requestPost, showToast } from "../../lib/util";
import UserImage from "../../components/UserImage";
import Vue3StarRatings from 'vue3-star-ratings-ksh';

export default {
  name: 'VideoReview',
  components: {
    UserImage,
    Vue3StarRatings,
  },
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'action'],
  data() {
    return {
      myInfo: this.$store.getters.me,
      reviewParams: [
        this.$t('profile.favourite_factor'),
        this.$t('profile.belief_factor'),
        this.$t('profile.manner_factor'),
        this.$t('profile.beauty_factor')
      ],
      reviewParamIdx: randomNumberInRange(0, 3),
      rating: 0,
      backButtonRouteGuard: null,
    }
  },
  computed: {
    userInfo() {
      return this.user;
    },
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        this.rating = 0;
        
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_review').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onOk: function () {
      let _app = this;
      requestPost(
        'video/giveVideoReview',
        {
          uid: _app.myInfo.uid,
          to_uid: _app.userInfo.uid,
          review_param: _app.reviewParamIdx,
          review_value: _app.rating
        },
        () => {
          showToast(_app.$t('video.review_success'));
          _app.$emit('close');
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
  }
}
</script>
