<template>
  <p class="text">{{ message.message }}</p>
</template>

<script>
export default {
  name: "TextItem",
  emits: [],
  props: { message: { type: Object } },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.text {
  background-color: #e5e5e5;
  display: inline-block;
  padding: 12px 20px 14px 20px;
  border-radius: 10px;
  font-size: 17px;
  color: black;
  text-align: center;
  align-content: center;
  font-size: 16px;
}

@media only screen and (max-width:766px) {
  .text {
    padding: 10px 15px 12px 15px;
    border-radius: 5px;
    font-size: 15px;
  }
}
</style>