<template>
  <div class="button_div"><a href="javascript:" @click="$emit('click')">{{ title }}</a></div>
</template>

<script>
export default {
  name: "PrimaryButton",
  emits: ['click'],
  props: ['title'],
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.button_div {
  height: 40px;
}

.button_div a {
  width: 100%;
  height: 100%;
  background-color: #f25c62;
  color: white;
  text-align: center;
  align-content: center;
  font-size: 16px;
  border-radius: 10px;
}

.button_div a:hover {
  background-color: #333;
}
</style>