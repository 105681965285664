export const OFFLINE_MODE = false;
export const APP_CLASS = 48;
export const BASE_URL = OFFLINE_MODE ? process.env.VUE_APP_API_URL : 'https://api.chatsee.me';
export const UPLOAD_API_URL = OFFLINE_MODE ? process.env.VUE_APP_API_URL : 'https://upload.chatsee.me';
// export const BASE_URL = OFFLINE_MODE ? process.env.VUE_APP_API_URL : 'https://in.chatsee.me:38752';
// export const UPLOAD_API_URL = OFFLINE_MODE ? process.env.VUE_APP_UPLOAD_URL : 'https://in.chatsee.me:42750';
export const API_URL = `${BASE_URL}/app/`;
export const XMPP_SERVER = 'https://chat.camtalkaws.com/http-bind/';
export const XMPP_SERVER_DOMAIN = 'chat.camtalkaws.com';
// export const XMPP_SERVER = 'https://in.chatsee.me:8080/http-bind/';
// export const XMPP_SERVER_DOMAIN = 'in.chatsee.me';
export const APP_MARKET_CODE = 'web';
export const PAGE_SIZE = 50;
export const RESIGNUP_DELAY_DAYS = 3;
export const FCM_VAPID_KEY = 'BD3-TtObaa_Qh2rgaw0YiuGlIt7VzYjf0JtD5DEAp128iPm1qzMlp5g0tx7p2PuktCtvcMxPyi0dWaYg8j8fJpw';
export const GOOGLE_CLIENT_ID = '277906408897-ql5d7h0de3k8bkksal8997fkql0036j2.apps.googleusercontent.com';

export const GIFT_ITEM_POINTS = [10, 30, 100, 300, 900];
export const GIFT_ITEM_IMAGES = [
  'gift_item1',
  'gift_item2',
  'gift_item3',
  'gift_item4',
  'gift_item5'
];
export const GIFT_ITEM_NAMES = ['손하트', '꽃다발', '링', '궁전', '메가하트'];
export const VIDEO_CHAT_GIFT_ITEM_POINTS = [10, 30, 100, 300, 900];

export const UPLOAD_MOMENT_IMAGE = 0;
export const UPLOAD_PROFILE_IMAGE = 1;
export const UPLOAD_ASK_IMAGE = 2;

export const EVENT_NAME = {
  PHOTO_DELETE: 'photo_delete',
  PROFILE_BACK: 'profile_back_press',
  MESSAGE_DETAIL_BACK: 'message_detail_back_press',
  GIFTICON_BACK: 'gifticon_back_press',
  HOME_BACK: 'home_back_press',
  PURCHASE_SUCCESS: 'purchase_success',
  PAYPAL_PURCHASE_SUCCESS: 'paypal_purchase_success',
  VIDEO_REQUEST_CANCEL: 'video_request_cancel',
  VIDEO_REQUEST_REFUSE: 'video_request_refuse',
  NEW_MESSAGE_TEXT: 'new_msg_text',
  NEW_MESSAGE_IMAGE: 'new_msg_image',
  NEW_MESSAGE_VIDEO: 'new_msg_video',
  NEW_MESSAGE_GIFT: 'new_msg_gift',
  VIDEO_CHAT: 'video_chat',
  VIDEO_RECEIVE: 'video_receive',
  VIDEO_REVIEW: 'video_review',
  REPORT: 'report',
  REPORT_SUCCESS: 'report_success',
  CHECK_DB: 'check_db',
  CHARGE_PAGE_RESUME: 'charge_page_resume',
  CLOSE_DIALOGS: 'close_dialogs',
  VIDEO_GIFT_ITEM_RECEIVED: 'video_gift_item_received',
};

export const MEDIA_TYPE = {
  CAMERA: 'camera',
  GALLERY: 'gallery',
  VIDEO: 'video'
};

export const ROUTES = [
  '/live',
  '/recommend',
  '/moment',
  '/chat',
  '/profile',
  '/charge',
  '/history',
  '/guide',
  '/announce',
  '/ham',
];

export const CHARGE_ITEM = [
  {
    POINT: '400',
    PRICE: '6,000',
    PRICE_USD: 7.99,
    PRICE_JP: 790,
  },
  {
    POINT: '1720',
    PRICE: '30,000',
    PRICE_USD: 29.99,
    PRICE_JP: 3300,
  },
  {
    POINT: '3100',
    PRICE: '90,000',
    PRICE_USD: 49.99,
    PRICE_JP: 5500,
  },
  {
    POINT: '6000',
    PRICE: '220,000',
    PRICE_USD: 99.99,
    PRICE_JP: 9900,
  },
  {
    POINT: '12800',
    PRICE: '220,000',
    PRICE_USD: 99.99,
    PRICE_JP: 19500,
  },
];

export const VIP_ITEM = [
  {
    POINT: '35,000',
    PRICE: '50,000',
    PRICE_USD: 79.99,
  },
  {
    POINT: '70,000',
    PRICE: '100,000',
    PRICE_USD: 159.99,
  },
]
