import { createApp } from 'vue';
import VueLazyLoad from 'vue3-lazyload';
import moment from 'moment';
import mitt from 'mitt';
import { getData } from 'country-list';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import dao from './lib/dao';
import fcm from './lib/fcm';
import iap from './lib/iap';
import vchat from './lib/vchat';
import xmpp from './lib/xmpp';
import { currency, isGlobalUser, isMobile } from "./lib/util";
import { EVENT_NAME } from './lib/constant';

const emitter = mitt();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueLazyLoad);

// 글로벌메서드 추가
Object.assign(app.config.globalProperties, {
  isGlobalUser: isGlobalUser,
  currency: currency,
  isMobile: isMobile,
  moment: moment,
  emitter: emitter,
  countryList: getData().map(it => {
    it.country_code = it.code;
    it.country_name = it.name;
    return it;
  }),
});

iap.init();
dao.init(emitter);

if (window.location.host.indexOf('chatsee.me') > -1) {
  fcm.init(emitter);
}

vchat.init(emitter);
xmpp.init(emitter);

app.mount('#app');

//
// [2024/03/04 10:32 KSH]충전화면에서 PG사 결제 페이지로 갔다가 돌아올 때 포인트 갱신을 위한 처리.
//
document.addEventListener('visibilitychange', function(){
  if (document.hidden == false) {
    if (router.currentRoute.value.name == 'Charge') {
      emitter.emit(EVENT_NAME.CHARGE_PAGE_RESUME, {});
    }
  }
})
