export default {
  app_name: 'ViewChat',
  app_error: {
    title: 'An unexpected error has occurred.',
    msg: 'An error has been sent to the team in charge. Please close the app and start again!',
    network: 'A network communication error has occured.',
    other_device_login: 'In another device, a user joined the same phone number.',
    image_not_supported: 'Unsupported image format....',
    upload_file_not_exist: "Upload file not exist",
  },
  common: {
    male_short: 'M',
    female_short: 'F',
    male: "Male",
    female: "Female",
    age: "",
    preparing: "Preparing...",
    yes: "Yes",
    no: "No",
    ok: "OK",
    cancel: "Cancel",
    point: "Point",
    cash: "Cash",
    bread: "Bread",
    close: "Close",
    delete: "Delete",
    second: "s",
    send: "SEND",
    create: "CREATE",
    submit: "Submit",
    like: "Like",
    jjim: "Follow",
    message: "Message",
    hits: "hits",
    next: "Next",
    name: "Name",
    phone_number: "Phone Number",
    years: "years",
    support_type: "point_system-error_payment-error_other",
    max: "MAX",
    point_alert: "Not enough point!",
    bread_alert: "Not enough bread!",
    done: "Done",
    top_title: "This is <b>{nickname}</b>'s profile",
    top_subtitle: "You can use ViewChat to set up various functions and profiles.",
  },
  msg: {
    not_implemented_yet: "Not implemented yet.",
    please_wait: "Please wait...",
    input_intro: "Select your intro",
    select_sex: "Select your gender",
    select_age: "Select your age",
    input_nickname: "Please input your nickname",
    nickname_too_short: "Nickname can not be less than 2 letters.",
    nickname_too_long: "Nickname can not be more than 5 letters.",
    input_message: "Please input your message",
    sent_message: "Message successfully sent",
    software_update: "Software Update",
    select_country: "Please select country",
    like_success: "like success",
    please_select_photo: "Please select photo",
    input_photo_content: "Moment Comment",
    zzalbe_registered: "Photo registered!",
    zzalbe_registered_with_point: "{point} point received.",
    zzalbe_success: "Photo is published after review.",
    zzalbe_comment: "Please input comment.",
    block_user: "{nickname} block",
    send_msg_disabled: "Don't send message",
    video_request_cancel: "Automatically canceled after {second} seconds",
    video_waiting_time_out: "Video request time out! Please try again later.",
    check_in: "Checked in. Don't forget checking in tomorrow",
    profile_saved: "Save profile info",
  },
  header_bar: {
    live: 'Live',
    recom: 'Recommend',
    photo: 'Photo',
    moment: 'Moment',
    chat: 'Chat',
    profile: 'Profile',
    follow: 'Follow',
    charge: 'Charge',
    history: 'History',
    guide: 'Guide',
    point: 'Point:',
    agreement: "Agreement & Policy",
    jp_purchase_policy: 'Purchase Policy',
    usage: 'Usage agreement',
    privacy: 'Privacy policy',
    ugc: 'UGC agreement',
    youth_policy: 'Youth policy',
    company_name: 'BC INNOVATION',
    boss: 'CEO : Hyunsuk Park',
    biz: 'Biz No : 227-81-20333',
    southwest: '305-1, 39 Cheongdae-ro 204beon-gil, Sokcho-si, Gangwon-do',
    tel: ' TEL : 1599-1401',
    email_prefix: 'E-Mail : contact',
    email_suffix: 'bcinnovation.co.kr',
    login: 'Login',
    logout: 'Logout',
    logout_confirm: 'Are you sure you want to logout?',
    top_button: "Top",
    viewchat_point: "ViewChat Point",
    view_more: "View More",
    viewchat_live: "View Live <b>Ranking</b>",
  },
  splash: {
    start_with_sns: 'Start with SNS',
    view_chat_login: 'Email Login',
    view_chat_signup: 'Signup',
    forgot_password: 'Forgot password?',
  },
  reset_password: {
    title: 'Password Reset',
    button_title: 'Reset Password',
    completed: 'Password reset completed!',
  },
  login: {
    title: "ViewChat - Face Video chat",
    login_01: "Welcome to the ViewChat",
    login_02: "Fun face chat, fun video call, ViewChat",
    guide: 'Input your correct id & pwd.',
    input_id: 'Input Your ID',
    input_pwd: 'Input Your Password',
    auto_login: 'Auto login',
    login: 'LOGIN',
  },
  phone: {
    verify: "Verify",
    exit: "Exit",
    str_adult_auth_title: "Title",
    str_adult_auth_text1_1: "Text1-1",
    str_adult_auth_text1_2: "Text1-2",
    str_adult_auth_text3_1: "Text3-1",
    str_adult_auth_text3_2: "Text3-2",
    str_adult_auth_text3_3: "Text3-3",
  },
  age_agreement: {
    title: 'Age agreement',
    text1: 'Are you older than 18?',
    text2: 'Only adults can use this service!<br/>Thank you.',
    reject: 'Reject',
    agree: 'Agree'
  },
  signup: {
    only_10_sec: 'Only 10 seconds,',
    sign_up: 'Please sign up!',
    id_placeholder: 'ID (Email)',
    invalid_id: 'Invalid ID (Email)',
    pwd: 'Password',
    pwd_confirm: 'Confirm Password',
    pwd_confirmed: 'Password confirmed!',
    agree_to_usage: 'Agree to usage agreement and privacy policy',
    select_profile: 'Select Your Profile Image',
    select_photo: 'Select Photo',
    nickname_placeholder: 'Input your nickname (Max 6 Charactor)',
    yrs: 'yrs',
    pay_attention: '* Not-editable after signup, so please pay attention!',
    start: 'START',
    title: "Signup",
    signup: "Join",
    msg_input_nickname: "Please input your nickname!",
    msg_input_gender: "Please select your gender!",
    msg_input_motto: "Please select motto!",
    msg_input_age: "Please select age!",
    gender_warning: "Gender can't be changed later",
    msg_nickname_too_short: "Nickname can not be less than 2 letters.",
    msg_nickname_too_long: "Nickname can not be more than 5 letters.",
    send_cert_key: "Send Cert Key",
    verify_cert_key: "Verify Cert Key",
    msg_input_email: "Please input your email address!",
    msg_input_cert_key: "Please input cert key.",
    msg_sent_cert_key: "Sent cert key to your email. Please input the code below input field.",
    cert_key_placeholder: "Cert Key",
    email_verified: "Email verified",
    msg_verify_email: "Please verify your email address!",
    msg_input_password: "Please input password!",
    msg_confirm_password: "Please confirm password!",
  },
  live: {
    title: "Welcome ViewChat Live, <b>{nickname}</b>",
    subtitle: "You have {message_count} new messages and {notification_count} new notifications.",
    all_country: "All Country",
    more_country: "More Country",
    real_time_hot_country: "Real time Hot Country:",
    ranking: 'Ranking',
    recent_login: 'Recent Login',
    video_chatting: "Chatting",
    more: "More",
  },
  recommend: {
    title: "ViewChat <b>Recommend</b> User",
    subtitle: "I'm a real-time video chat member recommended by ViewChat",
    recommend_today: 'Today',
    recommend_recom: 'Recommend',
    recommend_members: 'Members',
    recommend_users: "I'm a <b>recommended</b> member who's online right now.",
    total_star: "Total",
    total_star_2: "",
    total_star_3: "",
    recv_star: "received!",
    album: "Album",
    jjim: "Jjim",
    message: "Message",
    video: "Video",
    intro: "Intro message",
    average_video_time: "Avg Video Time",
    video_time_below_15s: "< 15s",
    video_time_below_1min: "< 1min",
    video_time_below_3min: "< 3min",
    video_time_above_3min: "> 3min",
    average_review_point: "Avg Review Rate",
    favourite_factor: "Fav",
    belief_factor: "Beli",
    manner_factor: "Man",
    beauty_factor: "Bea",
    delete: "Delete",
    msg: 'Msg',
  },
  photo: {
    title: "ViewChat <b>Moment</b> Contents",
    subtitle: "This is Viewchat's fun and enjoyable Moment.",
    category: "All_Profile",
    category_types: "all1_zzalbe_gif_recommend_me",
    create: "Create",
    guide: "Here are photos",
    hit: "Hit ",
    write_title: "Create Moment",
    write_guide: "Can't create without image.",
    write_desc: "Create interesting content as your moment.",
    visit: "Visits",
    recommend: "Recom",
    pick_photo: "Click to register the picture",
    delete_success: "Deleted!",
    emoticon_delete: "Delete emoticon",
    emoticon_delete_confirm: "Are you sure to delete?",
    emoticon_delete_success: "Deleted!",
    upload: "Upload",
    bookmark: "Bookmark",
    like: "Like",
    gift: "Gift",
    report: "Report",
    prev_contents: "Prev Contents",
    next_contents: "Next Contents",
    content_hashtag: 'Content and Hashtag',
    hashtag: '# Hashtag',
    hashtag_hint: '* Selected hashtags are cleared when reselect.',
    public_options: 'Public Options',
    free: 'Free',
    followers: 'Followers',
    paid: 'Paid',
    paid_hint: '* Paid Guideline',
    hashtag_select: 'Hashtag Select',
    hashtag_select_hint: 'Up to 3 hashtags can be selected.',
    price_guideline: 'Price Guideline',
    price_guideline_photo_1: 'A photo: ',
    price_guideline_photo_2: '20 {price} flowers',
    price_guideline_video_1: 'A video: ',
    price_guideline_video_2: '60 {price} flowers',
    price_guideline_audio_1: 'An audio: ',
    price_guideline_audio_2: '30 {price} flowers',
    price_must_be_nonzero: 'Price must be non-zero.',
    price_must_below: 'Price must below {maxPrice}.',
    public_to_followers: 'Public to followers',
  },
  video: {
    video_call_allow_necessary_permissions: "please permission",
    video_01: "{nickname} cancelled video call",
    video_02: "End confirm",
    video_03: "Are you sure to finish video chatting?",
    video_05: "Finish video chatting",
    video_07: "Insufficient point for chatting!",
    video_08: "Congratulations!, you earned {cash}.",
    video_09: "Video chatting was finished for insufficent {nickname}'s point",
    video_10: "Request video chatting",
    video_11: "Insufficient point",
    video_12: "Peer user is unavailable right now. Please send message first",
    video_14: "Peer user is busy.",
    video_22: "Refuse",
    video_23: "Accept",
    video_24: "New Video Request",
    video_25: "New Global Video Request",
    video_26: "(Automatically refused after {second} seconds))",
    video_27: "Same gender. Please request against other gender.",
    video_28: "Web camera is not available.",
    video_profile_02: "{nickname}님을 찜목록에 추가하였습니다",
    global_user: "Global User",
    global_user_warning: "This is global user.<br/>2 times more points will be charged.",
    video_call_request: "Request Video Chatting...",
    video_waiting_time_out: "Request timeout. Try again later",
    review_title: "How was video chatting?",
    review_select_rate: "Please select '{review}' rate",
    review_select_rate_1: "Select below start",
    review_select_rate_2: "<b>Rates not accumulated.</b><br/>Please rate correctly.",
    review_skip: "Skip",
    review_success: "Review success.",
    video_call_received: 'Video Chatting Request',
    video_call_finished: 'Video Chatting Finished {time}',
    after_8_seconds: 'You can send a gift after 8 seconds.',
  },
  message: {
    message_09: "Delete All Message",
    message_10: "Read All Message",
    message_11: "Here are messages.",
    message_read: "Read",
    message_unread: "Unread",
    message_delete_title: "Delete message",
    message_delete_confirm: "Are you sure to delete all message?",
    message_read_title: "Read message",
    message_read_confirm: "Are you sure to read all message?",
    message_sent_photo: "Send photo",
    message_sent_gift: "Send gift",
    message_content_delete: "Delete content",
    message_content_delete_confirm: "Are you sure to delete messages?",
    message_content_placeholder: "Please input message",
    message_content_hint: "Please enter a message",
    msg_send_msg_disabled: "Can't send message.",
    msg_block_user: "{nickname} is blocked.",
    msg_like_success: "Recommended.",
    msg_sent_message: "Message successfully sent",
    photo: "Photo",
    jjim: "Dibs",
    block: "Block",
    report: "Report",
    message_input: "Please input message.",
    send_message: "Send message",
    send_desc: "{point}P will be charged.<br/>Warning message here.",
    unread_messages: 'Unread messages',
    viewchat_messages: 'Enjoy ViewChat <b>Message</b>',
    viewchat_messages_intro: 'ViewChat message shared with members. Have a nice conversation!',
    viewchat_messages_info: 'There are a total of <b>{total_count}</b> messages and <b>{unread_count}</b> unread messages.',
    warning_message: 'In case of violation of the Youth Protection Act and regulations according to each reviewing institution, it may be subject to restrictions on use and legal punishment.',
  },
  profile: {
    title: "Profile Edit",
    average_video_time: "Avg Video Time",
    average_review_point: "Avg Review Rate",
    short_average_video_time: "AvgVidTime",
    short_average_review_point: "AvgReviewRate",
    video_time_below_15s: "Less 15s",
    video_time_below_1min: "Less 1m",
    video_time_below_3min: "Less 3m",
    video_time_above_3min: "More 3m",
    favourite_factor: "Favorite",
    belief_factor: "Truth",
    manner_factor: "Manner",
    beauty_factor: "Beauty",
    profile_intro: "Motto",
    profile_notify: "Notify",
    profile_12: "Nickname",
    profile_13: "Please input 8",
    profile_14: "Age",
    profile_15: "Gender",
    profile_16: "(Immutable)",
    profile_23: "SAVE",
    profile_24: "Edit",
    profile_25: "Video Profile",
    profile_26: "Default Icon",
    dibs: "Dibs",
    recommend: "Recommend",
    album: "Album",
    message: "Message",
    voice_chat: "Voice Chat",
    video_chat: "Video Chat",
    favorite: "Favorite",
    belive: "Belive",
    manner: "Manner",
    beauty: "Beauty",
    photos: "Photos",
  },
  album: {
    title: "Album",
    confirm: "Are you sure to delete ?"
  },
  more: {
    more_09: "Charge",
    more_10: "Gift Items",
    more_11: "Gifticon",
    more_13: "History",
    more_14: "Ask",
    more_15: "Guide",
    more_16: "Notice",
    more_21: "Update check",
    more_22: "Check latest version.",
    more_30: "My Album",
    more_31: "Checkin",
    more_32: "Announces",
    more_33: "Share",
    more_34: "Receive point after share.",
    more_35: "Profile Edit",
    more_36: "Attention to cheet!",
    more_37: "Please pay attention to cheet.",
    more_38: "회원",
    more_39: '50 Point',
    more_40: 'Account Setting',
    more_41: 'Transfer',
    more_42: 'Verify Notify ON',
    more_43: 'Recommend',
    more_44: 'Notify Setting',
    more_45: 'Who wants to be online friends?',
    more_46: 'Watch Out for New Phishing Fraud!',
    more_47: 'You should suspect phishing scams when inducing to other messengers or apps.',
    leave: 'Leave',
    leave_message: 'When you leave us, you will lose all of your point and cash. and 150 days later, you will be able to sign up again.\n\nAre you sure to leave now?',
    refund: 'Refund',
  },
  zzalbe: {
    message: "Message",
    gift: "Gift",
    delete: "Delete",
    reply: "Reply",
    msg: "Msg",
  },
  notify: {
    setting_video_receive: "Video",
    setting_video_receive_global: "Global Video",
    setting_sound: "Sound",
    setting_vibration: "Vibrate",
    setting_on: "ON",
    setting_off: "OFF",
  },
  log: {
    title: "History",
    log_list_02: "Point History",
    log_list_03: "Cash History",
    point: "Point: ",
    cash: "Cash: "
  },
  charge: {
    title: "Charge",
    charge_please: "Direct Charge Guide",
    direct_request: "Direct Request",
    desc1: "Account : NewYorkBank  304101-04-349026",
    desc2: "After selecting the item to deposit and depositing, please leave the name of the depositor to contact us, will check and process it.\n",
    by_app: "by App",
    open_app: "Open App",
    account_holder_name: "Name",
    input_name: "(Please input Name.)",
    input_phone_number: "(Please input phone number.)",
    direct_success: "success",
    amount: "Amount:",
    charge_point: "Point:",
    charge_items: "Please<br/>charge<br/>items!",
    charge_direct: "Direct<br/>Charge",
    charge_purchase: "Buy",
    charge_discount: "Discount",
    charge_item_price: "$",
    vip_item_price: "W",
    charge_item_point: "P",
    charge_vip_title: "VIP Items",
    charge_vip_comment: "(only for VIP users)",
    charge_vip_unavailable: "unavailable",
    charge_vip1_price: "50,000W",
    charge_vip2_price: "100,000W",
    charge_vip1_point: "35,000P",
    charge_vip2_point: "70,000P",
    charge_11: "Free Charge",
    charge_12: "Free Charge 1",
    charge_14: "Free Charge 2",
    charge_15: "Free Charge 3",
    charge_16: "Transfer",
    charge_17: "Cash > Point",
    charge_18: "Point > Cash",
    purchase_success: "{point} point rewarded to {nickname}.",
    paypal_purchase_rewarded: "{point} points purchased.",
    message_goto_app: 'This feature is only available at the Supercam app. Will you open the app?',
    paypal_purchase_success: 'Purchase success!\n Please wait for a minute before paid point is rewarded.',
    select_item: 'Charge item',
    select_pg: 'Payment method',
    card: 'Card',
    tele_d: 'd Pay',
    tele_au: 'au mobile',
    tele_softbank: 'SoftBank',
    guide_title: 'Point guide',
    guide_text1: '1. This service is basically free. However, you can use points to use some functions.',
    guide_text2: '2. Send DM, video call, or gift items can be purchased or used using points.<br/>Send DM - costs 15P each.<br/>Video call - 200P per minute required.<br/>Gift item - 10 ~ 900P is required depending on the item type.',
    card_info: 'Card Information',
    card_no: 'Card Number',
    card_expire: 'Card Expire Date',
    card_holder_name: 'Card Holder Name',
    card_security_code: 'Card Security Code',
    input_card_no: 'Please input card number.',
    input_card_expire: 'Please input card expiry date.',
    input_card_holder_name: 'Please input card holder name.',
    input_card_security_code: 'Please input card security code.',
    item_select: 'Item',
    item4_hint: '3,000P おトク‼',
    item3_hint: '1,000P おトク‼',
    item2_hint: '¥ 1,000 おトク‼',
    item1_hint: '人気No.1',
    item0_hint: 'お試し価格',
  },
  gifticon: {
    gifticon_03: "Name",
    gifticon_04: "Price",
    gifticon_05: "Phone",
    gifticon_06: "Purchase",
    gifticon_07: "Guide",
    gifticon_10: "Buy",
    gifticon_11: "Brand",
    gifticon_12: "Fee",
    gifticon_13: "Expire",
    gifticon_14: "Expire period is ",
    gifticon_15: "Please input phone number",
    gifticon_16: "sell gifticon",
    gifticon_02: "Requested.",
    request_gifticon: "Request gifticon",
    category_select: "Select category",
    category_1: "Good",
    category_2: "Shop",
    category_3: "Coupon",
    category_4: "Fast Food",
    category_5: "Bread",
    category_6: "Coffee",
    category_7: "Restaurant",
    category_8: "Film",
    category_9: "Beauty",
    category_10: "Travel",
    category_11: "Digital",
    category_12: "Baby",
  },
  refund: {
    refund_agree_10: "Refund",
    refund_agree_11: "Attention",
    refund_agree_12: "guide text",
    refund_agree_13: "Guide",
    refund_agree_14: "guide text",
    refund_03: "Input account owner name.",
    refund_04: "Input resident number.",
    refund_05: "Input phone number.",
    refund_06: "Input address.",
    refund_08: "Input account number.",
    refund_10: "30,000 cash minimum.",
    refund_11: "Cash not enough.",
    refund_12: "Refund success.",
    refund_13: "My Cash",
    refund_14: "Account owner",
    refund_15: "Phone number",
    refund_16: "Resident number",
    refund_18: "Address",
    refund_19: "Bank",
    refund_20: "Account number",
    refund_21: "Input account number",
    refund_22: "Refund cash - (30,000 cash minimum)",
    refund_27: "Refund money",
    refund_28: "Refund Request",
    refund_29: "Select bank",
    refund_30: "(Bonus",
    refund_31: "added)",
    refund_bank: "bank1_bank2_bank3_bank4",
    owner: "예금주 입력",
    phone_desc: "휴대폰번호를 숫자로만 입력",
    resident_desc: "(-)하이픈을 제외한 숫자로만 입력)",
    address_desc: "주소를 입력해주세요",
    cash_desc: "신청캐시 직접 입력(만원 단위로 작성)",
    top_hint: 'Top hint',
    middle_hint: 'Middle hint',
    bottom_hint: 'Bottom hint',
  },
  category: {
    category_1: "Good",
    category_2: "Shop",
    category_3: "Coupon",
    category_4: "Fast Food",
    category_5: "Bread",
    category_6: "Coffee",
    category_7: "Restaurant",
    category_8: "Film",
    category_9: "Beauty",
    category_10: "Travel",
    category_11: "Digital",
    category_12: "Baby",
  },
  transfer: {
    title: "Transfer",
    bread: "Bread: ",
    point2bread: "Point to Bread",
    bread2point: "Bread to Point",
  },
  ask: {
    ask_email: "Email",
    ask: "Ask",
    desc: "Please input correctly",
    asked: "Asked",
    asked_message1: "ㆍ Message1.",
    asked_message2: "ㆍ Message2.",
    asked_message3: "ㆍ Message3.",
    ok: "OK",
    ask_category: "Category",
    ask_content: "Content",
    ask_01: "Input your email address.",
    ask_02: "Select category",
    ask_03: "Input Ask Comment",
    ask_04: "Please select receipt photo.",
    ask_05: "No answer...",
    ask_06: "ASK",
    ask_08: "Successfully asked",
    ask_09: "Input Your Email Address",
    ask_11: "Image Upload: ",
    ask_12: "* Attention text...",
    ask_13: "Answer Check",
    ask_14: "Leave",
    ask_15: " ",
    agree: "Agree to the following.",
    agree_content: "Inquiries and answers are sent by e-mail, and e-mails are colleInquiries and answers are sent by e-mail, and e-mails are collected only for reply.",
    upload_desc: "Content, images, and profanity that are not related to the inquiry will be ignored."
  },
  announce: {
    title: "Notice",
  },
  report: {
    title: "REPORT",
    warning: "Select Report Reason",
    reason: "Objectional Contents_Sexual Harassment_Sexual Advance_Spam_Etc",
    placeholder: 'Report category',
    please_select_category: 'Please select report category.',
    success: "Report success",
  },
  notify_setting: {
    title: "Notify Setting",
    video_notify: "Video Notify",
    video_notify_global: "Video Notify Global",
    video_notify_sound: "Video Notify Sound",
    video_notify_vibrate: "Video Notify Vibrate",
  },
  country: {
    title: "All Country",
    search: "Search",
    real_country: "Real time country",
    rank: "RANK",
    more_country: "More Country",
    max_country: "Maximum 5 countries.",
    hot: "Hot",
    alpha: "alphabet",
    thail: "Thail",
    viet: "Viet",
    rus: "Rus",
    real_coun: "Realtime Coun",
    country1: "Thailand",
    country2: "Uqurain",
    country3: "Russian",
    country4: "United States",
    country5: "Tailwan",
    country6: "AAA",
    warning1: "Only one countries",
    country_code_list: "tha_uga_rus_usa_twn_alb",
    country_list: "Thailand_Uganda_Russian_United States_Taiwan_AAAA",
    all_country: "All Country",
    apply: "Apply",
    turn_off_global: "Turn off global",
  },
  guide: {
    title: "Guide",
    usage_agreement: "Usage Agreement",
    privacy_policy: "Privacy Policy",
  },
  gift_items: {
    title: 'Gift Items',
    buy: 'Buy',
    fingerheart: 'FingerHeart',
    flowers: 'Flowers',
    ring: 'Ring',
    palace: 'Palace',
    megaheart: 'MegaHeart',
    gift_item_names: ['FingerHeart', 'Flowers', 'Ring', 'Palace', 'MegaHeart'],
  },
  gift_item_purchase: {
    title: 'Gift Item Purchase',
    amount: "Amount:",
    price: "Point:",
  },
  gift_item_send: {
    title: 'Send Gift Item',
    hint: 'Click send button after select an item.',
    success: 'Sent gift item.',
  },
  gift_item_send_amount: {
    title: 'Send Amount',
  },
  follow: {
    follower: 'Follower',
    following: 'Following',
    block: 'Block',
  },
  album: {
    profile_edit: 'Profile Edit',
    write: 'Write',
    posts: 'Posts',
    likes: 'Likes',
    followers: 'Followers',
    followings: 'Followings',
    motto_hint: 'Input motto.',
    input_motto: 'Please input motto!',
    save: 'Save',
    video_call: 'Video Call',
    send_message: 'Send Message',
    follow: 'Follow',
    unfollow: 'Unfollow',
    must_follow_each_other_text1: 'You are not followed each other!',
    must_follow_each_other_text2: 'Send message in order to follow each other.',
    no_photos: 'There are no photos.',
  },
  story_purchase: {
    title: 'Will you purchase\nthis item?',
    description1: 'Purchased story item can only be used within app.',
    description2:
      'Storage and leakage are prohibited, and in case of violation, all responsibility lies with the user.',
    item_not_enough: 'Gift items are not enough',
    purchase_confirm: 'Would you like to purchase?',
    purchase_success: 'Purchased story item.',
  },
};
