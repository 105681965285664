<template>
  <transition name="fade">
    <div class="pop_back" @click="$emit('close')">
      <div class="pop_gift_item_send" @click="$event.stopPropagation()">
        <div class="head">
          <h1>{{ $t('gift_item_send.title') }}</h1>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/popup_close_white.png">
          </a>
        </div>
        <div class="content">
          <div v-for="i in 5" @click="onItemClick(i - 1)" class="gift_item"
            :class="{ gift_item_selected: i - 1 == selectedItemIndex }">
            <img class="gift_item_radio"
              :src="require(`../../assets/img/video_chat_gift_item_radio_${selectedItemIndex == i - 1 ? 'on' : 'off'}.png`)">
            <img class="gift_item_icon" :src="require(`../../assets/img/gift_item${i}.png`)">
            <span class="gift_item_amount">{{ `x ${giftItemAmounts[i - 1]}` }}</span>
            <span class="gift_item_name">{{ giftItemNames[i - 1] }}</span>
          </div>
          <div class="hint">{{ $t('gift_item_send.hint') }}</div>
          <div class="bottom_btns">
            <GrayButton style="flex: 1;" :title="$t('common.close')" @click="$emit('close')" />
            <PrimaryButton style="flex: 1;margin-left: 10px;" :title="$t('common.send')" @click="onSend" />
          </div>
        </div>
      </div>
    </div>
  </transition>

  <ConfirmPopup v-if="showGiftItemLackPopup" :title="$t('story_purchase.item_not_enough')"
    :content="$t('story_purchase.purchase_confirm')" :ok="$t('common.yes')" :cancel="$t('common.no')"
    @close="showGiftItemLackPopup = false" @ok="showGiftItemLackPopup = false; showGiftItemPurchasePopup = true;" />

  <GiftItemSendAmountPopup v-if="showGiftItemSendAmountPopup" :giftItemId="selectedItemIndex"
    @close="showGiftItemSendAmountPopup = false" @send="onSendMultiple" />

  <GiftItemPurchasePopup v-if="showGiftItemPurchasePopup" :giftItemId="selectedItemIndex" :isInVideo="isInVideo"
    :videoInfo="{ target_uid: peerUser.uid, chattingTime: ellapsedVideoChattingTime }"
    @close="showGiftItemPurchasePopup = false" @purchased="onGiftItemPurchased" />
</template>

<script>
import GrayButton from '../../components/buttons/GrayButton.vue';
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
import BasePopup from '../../components/popups/BasePopup.vue';
import ConfirmPopup from '../../components/popups/ConfirmPopup.vue';
import { GIFT_ITEM_POINTS } from '../../lib/constant';
import dao from '../../lib/dao';
import { requestPost, showToast } from '../../lib/util';
import xmpp from '../../lib/xmpp';
import store from '../../store';
import GiftItemPurchasePopup from './GiftItemPurchasePopup.vue';
import GiftItemSendAmountPopup from './GiftItemSendAmountPopup.vue';


export default {
  name: "GiftItemSendPopup",
  components: {
    BasePopup,
    PrimaryButton,
    GrayButton,
    ConfirmPopup,
    GiftItemPurchasePopup,
    GiftItemSendAmountPopup,
  },
  props: {
    peerUser: { type: Object },
    isInVideo: { type: Boolean, default: false },
    videoInfo: { type: Object, default: null },  // {time, from_uid, to_uid, chattingTime}
    isMessageSendMultiGiftItemsSupported: { type: Boolean },
  },
  emits: ['close', 'sent', 'purchased'],
  data() {
    return {
      backButtonRouteGuard: null,
      myInfo: this.$store.getters.me,
      selectedItemIndex: null,
      giftItemPoints: GIFT_ITEM_POINTS,
      showGiftItemLackPopup: false,
      showGiftItemPurchasePopup: false,
      showGiftItemSendAmountPopup: false,
      popupOpenedTime: 0,
    }
  },
  computed: {
    ellapsedVideoChattingTime() {
      return this.videoInfo.chattingTime + (Date.now() - this.popupOpenedTime);
    }
  },
  mounted() {
    this.popupOpenedTime = Date.now();
    document.addEventListener('keyup', this.onKeyPress);
    this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
      this.$emit('close');
      next(false);
    });
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyPress);
    if (this.backButtonRouteGuard) {
      this.backButtonRouteGuard();
    }
  },
  computed: {
    giftItemAmounts() {
      return [
        this.myInfo.gift_item1_count,
        this.myInfo.gift_item2_count,
        this.myInfo.gift_item3_count,
        this.myInfo.gift_item4_count,
        this.myInfo.gift_item5_count,
      ];
    },
    giftItemNames() {
      return [
        this.$t('gift_items.fingerheart'),
        this.$t('gift_items.flowers'),
        this.$t('gift_items.ring'),
        this.$t('gift_items.palace'),
        this.$t('gift_items.megaheart'),
      ];
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_gift_item_send').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onItemClick: function (i) {
      this.selectedItemIndex = i;
    },
    onSend: function () {
      if (this.selectedItemIndex == null) {
        showToast(this.$t('gift_item_send.hint'));
        return;
      }

      if (this.isMessageSendMultiGiftItemsSupported) {
        this.showGiftItemSendAmountPopup = true;
      } else {
        this.sendGiftItem(1);
      }
    },

    onSendMultiple: function (amount) {
      this.showGiftItemSendAmountPopup = false;
      this.sendGiftItem(amount);
    },

    sendGiftItem: async function (amount) {
      var giftItemCountLackString = '';
      if (
        this.selectedItemIndex == 0 &&
        this.myInfo.gift_item1_count < amount
      ) {
        giftItemCountLackString =
          this.$t('gift_items.fingerheart') + '(10P)';
      }
      if (
        this.selectedItemIndex == 1 &&
        this.myInfo.gift_item2_count < amount
      ) {
        giftItemCountLackString =
          this.$t('gift_items.flowers') + '(30P)';
      }
      if (
        this.selectedItemIndex == 2 &&
        this.myInfo.gift_item3_count < amount
      ) {
        giftItemCountLackString =
          this.$t('gift_items.ring') + '(100P)';
      }
      if (
        this.selectedItemIndex == 3 &&
        this.myInfo.gift_item4_count < amount
      ) {
        giftItemCountLackString =
          this.$t('gift_items.palace') + '(300P)';
      }
      if (
        this.selectedItemIndex == 4 &&
        this.myInfo.gift_item5_count < amount
      ) {
        giftItemCountLackString =
          this.$t('gift_items.megaheart') + '(900P)';
      }

      if (giftItemCountLackString != '') {
        this.showGiftItemLackPopup = true;
        return;
      }

      const myInfo = this.myInfo;
      const peerUser = this.peerUser;
      const itemId = this.selectedItemIndex;
      requestPost(
        this.isInVideo ? 'gift/sendGiftItem' : 'gift/sendGiftInMessageV2',
        this.isInVideo ? {
          uid: myInfo.uid,
          target_uid: this.peerUser.uid,
          time: this.videoInfo.time,
          from_user: this.videoInfo.from_uid,
          to_user: this.videoInfo.to_uid,
          item_id: itemId,
        } : {
          uid: myInfo.uid,
          target_uid: this.peerUser.uid,
          item_id: itemId,
          amount: amount,
        },
        async response => {
          myInfo.gift_item1_count = response.gift_item1_count;
          myInfo.gift_item2_count = response.gift_item2_count;
          myInfo.gift_item3_count = response.gift_item3_count;
          myInfo.gift_item4_count = response.gift_item4_count;
          myInfo.gift_item5_count = response.gift_item5_count;
          await store.dispatch('setMyInfo', myInfo);

          if (this.isInVideo) {
            xmpp.sendGiftItemInVideo(peerUser, itemId);
          } else {
            if (amount == 1) {
              xmpp.sendGiftItem(peerUser, itemId);
            } else {
              xmpp.sendGiftItemMulti(peerUser, itemId, amount);
            }
          }

          await dao.saveUser(peerUser);

          if (this.isInVideo) {
            dao.insertMessage(peerUser.uid, 1, `${itemId}`, 'gift_item_sent_in_video', Date.now(), 0);
          } else {
            dao.insertMessage(peerUser.uid, 1, `${itemId}_${amount}`, 'gift_item_sent_multi', Date.now(), 0);
          }

          this.$emit('close');
          this.$emit('sent', itemId);
        },
        (code, msg) => {
          showToast(msg);
        }
      )
    },
    onGiftItemPurchased: function () {
      this.showGiftItemPurchasePopup = false;
      this.$emit('purchased');
    },
  }
}
</script>


<style scoped>
.pop_gift_item_send {
  position: fixed;
  width: 500px;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: -250px;
  padding: 40px;
  background-color: #000000A0;
  border-radius: 15px;
  z-index: 110;
}

.pop_gift_item_send .head {
  position: relative;
  padding-bottom: 20px;
  border-bottom: solid 1px #e5e5e5;
}

.pop_gift_item_send .head h1 {
  font-size: 18px;
  line-height: 20px;
  color: white;
}

.pop_gift_item_send .head a {
  position: absolute;
  right: 0;
  top: 0;
}

.pop_gift_item_send .head a img {
  width: 20px;
}

.pop_gift_item_send .content {
  width: 100%;
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift_item {
  align-self: stretch;
  margin-bottom: 10px;
  padding: 10px;
}

.gift_item_selected {
  background-color: #00000099;
  border-color: #1CFFE0FF;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
}

.gift_item_radio {
  width: 20px;
}

.gift_item_icon {
  width: 50px;
  margin: 0px 20px;
}

.gift_item_amount {
  color: white;
  width: 100px;
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
}

.gift_item_name {
  color: white;
  font-size: 18px;
}

.hint {
  padding: 5px;
  background-color: #E23C3CA0;
  border-radius: 13px;
  font-size: 16px;
  color: white;
  margin: 20px 0px;
}

.bottom_btns {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}

@media only screen and (max-width:766px) {
  .pop_gift_item_send {
    width: calc(100% - 20px);
    height: auto;
    left: 0;
    bottom: 0;
    top: auto;
    transform: translateY(0%);
    margin-left: 0;
    margin-top: 0;
    padding: 10px;
    border-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .pop_gift_item_send .title {
    font-size: 15px;
    font-weight: 600;
    padding: 10px 10px 0px 10px;
  }

  .pop_gift_item_send .content {
    width: calc(100% - 40px);
    padding: 20px 20px;
  }
}
</style>