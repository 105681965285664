<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_report" @click="$event.stopPropagation()">
        <div class="head">
          <h1>{{ $t('report.warning') }}</h1>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="report_reasion">
          <select @change="onCategory">
            <option disabled selected value="">{{ $t('report.placeholder') }}</option>
            <option v-for="(it, idx) in categoryList" :key="idx" :value="it">{{ it }}</option>
          </select>
          <a href="javascript:" @click="onReport">{{ $t('report.title') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./Report.scss"></style>

<script>

import { requestPost, showToast } from "../../lib/util";

export default {
  name: 'Report',
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'report'],
  data() {
    return {
      myInfo: this.$store.getters.me,
      categoryList: this.$t('report.reason').split('_'),
      category: '',
      backButtonRouteGuard: null,
    }
  },
  computed: {},
  watch: {
    show(show) {
      if (show) {
        // dialog show
        this.category = '';
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_report').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onCategory: function (e) {
      this.category = e.target.value;
    },
    onReport: function () {
      if (this.category == '') {
        showToast(this.$t('report.please_select_category'));
        return;
      }

      let _app = this;
      requestPost(
        'setting/report',
        {
          uid: this.myInfo.uid,
          peer_userid: this.user.uid,
          content: this.category,
          type: 1,
          message_text: ''
        },
        () => {
          showToast(_app.$t('report.success'));
          _app.$emit('report');
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    }
  }
}
</script>
