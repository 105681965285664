<template>
  <!-- PC GNB -->
  <div class="gnb">
    <div v-if="myInfo != null" class="profile">
      <div class="image">
        <UserImage :gender="myInfo.user_sex" :image="myInfo.user_profile_image" />
      </div>
      <div class="text">
        <p :class="[{ female: myInfo.user_sex == 1 }, { male: myInfo.user_sex == 0 }]">{{ myInfo.user_nickname }}</p>
        <span>
          ({{
            myInfo.user_age + " " + $t('common.years') + ", " +
            (myInfo.user_sex == 0 ? $t('common.male') : $t('common.female'))
          }})
        </span>
      </div>
    </div>
    <div v-if="myInfo == null" class="profile">
      <div class="login_button">
        <a href="javascript:" @click="onLogin()">
          <img src="../assets/img/gnb_login.png">{{ $t('splash.view_chat_login') }}
        </a>
      </div>
      <div class="signup_button">
        <a href="javascript:" @click="onLogin()">
          <img src="../assets/img/gnb_signup.png">{{ $t('splash.view_chat_signup') }}
        </a>
      </div>
    </div>

    <div class="menu">
      <ul v-for="(tab, idx) in tabs" :key="idx">
        <li>
          <a :class="{ now: activeTab == idx }" href="javascript:" @click="onTab(idx)">
            <img :src="tab.activeIcon">{{ tab.title }}<p><img src="../assets/img/arrow_left.png"></p>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="myInfo != null" class="point">
      <p>{{ myInfo.user_sex == 0 ? $t('common.point') : $t('common.bread') }}</p>{{ myInfo.user_sex == 0 ? point : cash }}<img :src="require(myInfo.user_sex == 0 ? '../assets/img/ic_point.png' : '../assets/img/bread.png')">
    </div>
    <div v-if="myInfo != null" class="gift_items">
      <a href="javascript:" @click="onGiftItms()">
        <img src="../assets/img/gift_item1.png"><span>x {{ myInfo.gift_item1_count }}</span>
        <img src="../assets/img/gift_item2.png"><span>x {{ myInfo.gift_item2_count }}</span>
        <img src="../assets/img/gift_item3.png"><span>x {{ myInfo.gift_item3_count }}</span>
        <img src="../assets/img/gift_item4.png"><span>x {{ myInfo.gift_item4_count }}</span>
        <img src="../assets/img/gift_item5.png"><span>x {{ myInfo.gift_item5_count }}</span>
      </a>
    </div>
    <div v-if="myInfo != null" class="ranking">
      <div class="scroll">
        <h1 v-html="$t('header_bar.viewchat_live')"></h1>
        <div class="list">
          <ul>
            <li v-for="(user, idx) in liveUsers" :key="idx">
              <a href="javascript:" @click="onProfile(user)">
                <UserImage :gender="user.user_sex" :image="user.user_profile_image" />
                <img style="width: 18px; height: 15px; margin-top: -5px;"
                  :src="require('../assets/flag/' + user.user_country_code + '.png')">
                {{ getMaxLengthLimitedString(user.user_nickname) }}
                <span v-if="user.user_line_busy == '0'">ON</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="more" v-if="liveUsers.length == 10">
          <a href="javascript:" @click="onRankingMore">
            {{ $t('header_bar.view_more') }}<img src="../assets/img/ico_down.png">
          </a>
        </div>
      </div>
    </div>
    <div class="copy">
      <div class="menu">
        <a href="javascript:" @click="viewAgreeMenu()" style="margin-bottom:0px;">
          {{ $t('header_bar.agreement') }}<img src="../assets/img/arrow_left.png">
        </a>
        <a href="javascript:" @click="onJpPurchasePolicy()">
          {{ $t('header_bar.jp_purchase_policy') }}
        </a>
      </div>
      <div class="menu_on_back" @click="viewAgreeMenu()" />
      <div class="menu_on">
        <a href="javascript:" @click="onUsageAgreement()">{{ $t('header_bar.usage') }}</a>
        <a href="javascript:" @click="onPrivacyPolicy()">{{ $t('header_bar.privacy') }}</a>
        <a href="javascript:" @click="onUgcPolicy()">{{ $t('header_bar.ugc') }}</a>
        <a href="javascript:" @click="onYouthPolicy()">{{ $t('header_bar.youth_policy') }}</a>
      </div>
      <div class="text">
        <p>{{ $t('header_bar.company_name') }}</p>
        <span>{{ $t('header_bar.boss') }}</span>
        <span>{{ $t('header_bar.biz') }}</span>
        <span>{{ $t('header_bar.southwest') }}</span>
        <span>{{ $t('header_bar.tel') }}</span>
        <span>{{ $t('header_bar.email_prefix') }}@{{ $t('header_bar.email_suffix') }}</span>
        <h5>COPYRIGHT 2023 @ {{ $t('header_bar.company_name') }}. All Rights Reserved.</h5>
      </div>
    </div>
  </div>

  <!-- Mobile GNB -->
  <div v-if="showMoMenu" class="pop_back" @click="$emit('closeMoMenu')">
    <div class="momenu" @click="$event.stopPropagation()">
      <div class="close">
        <a href="javascript:" @click="$emit('closeMoMenu')">
          <img src="../assets/img/btn_close.png">
        </a>
      </div>
      <div v-if="myInfo != null" class="profile">
        <div class="image">
          <UserImage :gender="myInfo.user_sex" :image="myInfo.user_profile_image" />
        </div>
        <div class="text">
          <p>{{ myInfo.user_nickname }}</p>
          <span>
            ({{
              myInfo.user_age + $t('common.years') + ", " +
              (myInfo.user_sex == 0 ? $t('common.male') : $t('common.female'))
            }})
          </span>
        </div>
      </div>
      <div v-if="myInfo == null" class="profile">
        <div class="login_button">
          <a href="javascript:" @click="onLogin()">
            <img src="../assets/img/gnb_login.png">{{ $t('splash.view_chat_login') }}
          </a>
        </div>
        <div class="signup_button">
          <a href="javascript:" @click="onLogin()">
            <img src="../assets/img/gnb_signup.png">{{ $t('splash.view_chat_signup') }}
          </a>
        </div>
      </div>
      <div class="menu">
        <ul v-for="(tab, idx) in tabs" :key="idx">
          <li>
            <a :class="{ now: activeTab == idx }" href="javascript:" @click="onTab(idx)">
              <img :src="tab.activeIcon">{{ tab.title }}<p><img src="../assets/img/arrow_left.png"></p>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="myInfo != null" class="point">
        <p>{{ $t('header_bar.viewchat_point') }}</p>{{ point }}<img src="../assets/img/ico_point.png">
      </div>
      <div v-if="myInfo != null" class="gift_items">
        <a href="javascript:" @click="onGiftItms()">
          <img src="../assets/img/gift_item1.png"><span>x {{ myInfo.gift_item1_count }}</span>
          <img src="../assets/img/gift_item2.png"><span>x {{ myInfo.gift_item2_count }}</span>
          <img src="../assets/img/gift_item3.png"><span>x {{ myInfo.gift_item3_count }}</span>
          <img src="../assets/img/gift_item4.png"><span>x {{ myInfo.gift_item4_count }}</span>
          <img src="../assets/img/gift_item5.png"><span>x {{ myInfo.gift_item5_count }}</span>
        </a>
      </div>
      <div v-if="myInfo != null" class="ranking">
        <div class="scroll">
          <h1 v-html="$t('header_bar.viewchat_live')"></h1>
          <div class="list">
            <ul>
              <li v-for="(user, idx) in liveUsers" :key="idx">
                <a href="javascript:" @click="onProfile(user)">
                  <UserImage :gender="user.user_sex" :image="user.user_profile_image" />
                  <img style="width: 18px; height: 15px; margin-top: -5px;"
                    :src="require('../assets/flag/' + user.user_country_code + '.png')">
                  {{ getMaxLengthLimitedString(user.user_nickname) }}
                  <span v-if="user.user_line_busy == '0'">ON</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="more" v-if="liveUsers.length == 10">
            <a href="javascript:" @click="onRankingMore">
              {{ $t('header_bar.view_more') }}<img src="../assets/img/ico_down.png">
            </a>
          </div>
        </div>
      </div>
      <div class="copy">
        <div class="menu">
          <a href="javascript:" @click="onJpPurchasePolicy()">
            {{ $t('header_bar.jp_purchase_policy') }}
          </a>
        </div>
        <div class="text">
          <p>{{ $t('header_bar.company_name') }}</p>
          <span>{{ $t('header_bar.boss') }}</span>
          <span>{{ $t('header_bar.biz') }}</span>
          <span>{{ $t('header_bar.southwest') }}</span>
          <span>{{ $t('header_bar.tel') }}</span>
          <span>{{ $t('header_bar.email_prefix') }}@{{ $t('header_bar.email_suffix') }}</span>
          <h5>COPYRIGHT 2023 @ {{ $t('header_bar.company_name') }}. All Rights Reserved.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./Gnb.scss"></style>

<script>
import $ from 'jquery'
import UserImage from "./UserImage";
import { requestPost, showToast, getTermsUrl, getMaxLengthLimitedString } from "@/lib/util";

export default {
  name: "Gnb",
  props: ['showMoMenu', 'activeTab'],
  emits: ['closeMoMenu', 'tabClick'],
  components: {
    UserImage,
  },
  data() {
    return {
      tabs: [
        {
          title: this.$t('header_bar.live'),
          activeIcon: require('../assets/img/ico_gnb_01.png'),
          inactiveIcon: require('../assets/img/ico_gnb_01.png'),
        },
        {
          title: this.$t('header_bar.recom'),
          activeIcon: require('../assets/img/ico_gnb_02.png'),
          inactiveIcon: require('../assets/img/ico_gnb_02.png'),
        },
        {
          title: this.$t('header_bar.moment'),
          activeIcon: require('../assets/img/ico_gnb_03.png'),
          inactiveIcon: require('../assets/img/ico_gnb_03.png'),
        },
        {
          title: this.$t('header_bar.chat'),
          activeIcon: require('../assets/img/ico_gnb_04.png'),
          inactiveIcon: require('../assets/img/ico_gnb_04.png'),
        },
        {
          title: this.$t('header_bar.profile'),
          activeIcon: require('../assets/img/ico_gnb_05.png'),
          inactiveIcon: require('../assets/img/ico_gnb_05.png'),
        },
      ],
      pageIndex: 0,
      liveUsers: [],
    }
  },
  computed: {
    myInfo() {
      return this.$store.getters.me;
    },
    point() {
      if (this.$store.getters.isLogin) {
        return this.currency(this.$store.getters.me.points);
      } else {
        return '0';
      }
    },
    cash() {
      if (this.$store.getters.isLogin) {
        return this.currency(this.$store.getters.me.cash);
      } else {
        return '0';
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    var gnb = $(".gnb");
    var pageHeight = $(document).height();
    gnb.css("height", pageHeight);

    this.initLiveList();
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    getMaxLengthLimitedString,
    onTab: function (tabIdx) {
      if (this.myInfo == null) {
        if (tabIdx >= 3) {
          this.$router.replace({
            name: 'Logout'
          });
          return;
        }
      }
      this.$emit('tabClick', tabIdx);
    },
    onLogin: function () {
      this.$router.replace({
        name: 'Logout'
      });
    },
    onGiftItms() {
      this.$router.push('/gift-items');
    },
    handleScroll(event) {
      var gnb = $(".gnb");
      let scroll = $(window).scrollTop();
      if (scroll > 195) {
        gnb.addClass("gnb_fixed");
      } else {
        gnb.removeClass("gnb_fixed");
      }
    },
    onJpPurchasePolicy() {
      window.open(getTermsUrl('jp_purchase_policy'));
    },
    onUsageAgreement() {
      this.viewAgreeMenu();
      window.open(getTermsUrl('usage_agreement'));
    },
    onPrivacyPolicy() {
      this.viewAgreeMenu();
      window.open(getTermsUrl('privacy_policy'));
    },
    onUgcPolicy() {
      this.viewAgreeMenu();
      window.open(getTermsUrl('ugc'));
    },
    onYouthPolicy() {
      this.viewAgreeMenu();
      window.open(getTermsUrl('youth_policy'));
    },
    viewAgreeMenu() {
      $(".copy .menu_on_back").toggle();
      $(".copy .menu_on").toggle();
    },
    onRankingMore() {
      this.getLiveList();
    },
    initLiveList: function () {
      this.pageIndex = 0;
      this.liveUsers = [];
      this.getLiveList();
    },
    getLiveList: async function () {
      if (this.myInfo == null) {
        await this.$store.dispatch('loadMyInfo');

        if (this.myInfo == null) {
          return;
        }
      }

      let _app = this;
      requestPost(
        'live/getLiveList_LiveRecommend',
        {
          uid: this.myInfo.uid,
          sex: this.myInfo.user_sex == 0 ? 1 : 0,
          sort: 'updateDate',
          country_code: 'jp',
          offset: this.pageIndex * 10,
          limit: 10
        },
        response => {
          _app.liveUsers = _app.liveUsers.concat(response.live_list);
          _app.pageIndex++;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onProfile: function (user) {
      this.$root.showUserDlg(user);
    },
  },
}
</script>
