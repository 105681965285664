<template>
  <transition name="fade">
    <BasePopup :title="$t('gift_item_send_amount.title')" @close="$emit('close')">
      <div class="content">
        <img :src="require(`../../assets/img/gift_item${giftItemId + 1}.png`)">
        <span class="gift_item_name">{{ giftItemName }}</span>
        <div class="amount">
          <span>{{ $t('gift_item_purchase.amount') }}</span>
          <a href="javascript:" @click="onMinus()">-</a>
          <span>{{ amount }}</span>
          <a href="javascript:" @click="onPlus()">+</a>
        </div>
        <PrimaryButton style="width: 200px;margin-top: 20px;" :title="$t('common.send')" @click="onSend" />
      </div>
    </BasePopup>
  </transition>
</template>
<script>

import PrimaryButton from "../../components/buttons/PrimaryButton.vue";
import BasePopup from "../../components/popups/BasePopup.vue";

export default {
  name: 'GiftItemSendAmountPopup',
  components: {
    PrimaryButton,
    BasePopup
  },
  props: {
    giftItemId: {
      type: Number,
    },
  },
  emits: ['close', 'send'],
  data() {
    return {
      myInfo: this.$store.getters.me,
      amount: 1,
    }
  },
  computed: {
    giftItemName() {
      return [
        this.$t('gift_items.fingerheart'),
        this.$t('gift_items.flowers'),
        this.$t('gift_items.ring'),
        this.$t('gift_items.palace'),
        this.$t('gift_items.megaheart'),
      ][this.giftItemId];
    }
  },
  methods: {
    onMinus: function () {
      if (this.amount > 1) {
        this.amount = this.amount - 1;
      }
    },
    onPlus: function () {
      this.amount = this.amount + 1;
    },
    onSend: function () {
      this.$emit('send', this.amount);
    },
  }
}
</script>

<style scoped>
.content {
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content .gift_item_name {
  color: #333;
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.content select {
  color: #333;
  background-color: #f5f5f5;
  padding: 15px 20px;
  border-radius: 10px;
  margin-right: 10px;
  width: 100%;
}

.content a {
  font-size: 15px;
  font-weight: 700;
  background-color: #e24c4d;
  color: white;
  border-radius: 15px;
  padding: 15px 0;
  display: block;
  text-align: center;
  margin-top: 10px;
}

.content a:hover {
  background-color: #333;
}

.content .amount {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.content .amount a {
  background-color: #e24c4d;
  color: white;
  border-radius: 20px;
  width: 40px;
  font-size: 15px;
  padding: 12px 0px;
  text-align: center;
}

.content .amount span {
  color: #333;
  font-size: 19px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
</style>