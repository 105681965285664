<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="closeDialog">
      <div class="pop_receive" @click="$event.stopPropagation()">
        <label class="title">{{ $t('video.video_call_received') }}</label>
        <div class="close">
          <a href="javascript:" @click="closeDialog">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="user-info">
          <div class="profile">
            <UserImage
              :gender="userInfo.sex"
              :image="userInfo.profileimage"/>
          </div>
          <div class="info">
            <label class="nickname">
              {{ userInfo.nickname }},
              {{ " " }}
              {{ userInfo.sex == '0' ? $t('common.male_short') : $t('common.female_short') }}
              {{ userInfo.age }}
            </label>
            <div class="cnt-info">
              <div>
                <img srcset="../../assets/img/user_profile_gift_icon.png 1x">
                <label>
                  {{ currency(userStats.star_gift_cnt) }}
                </label>
              </div>
              <div>
                <img srcset="../../assets/img/user_profile_like_icon.png 1x">
                <label>
                  {{ currency(userInfo.like_cnt) }}
                </label>
              </div>
              <div>
                <img srcset="../../assets/img/user_profile_moments_icon.png 1x">
                <label>
                  {{ currency(userInfo.like_cnt) }}
                </label>
              </div>
            </div>
            <label class="intro">{{ userInfo.motto }}</label>
          </div>
        </div>
        <div class="time-group">
          <div class="video-time">
            <img src="../../assets/img/timer.png"/>
            <div class="time">
              <label class="value">
                {{videoTime}}
              </label>
              <label class="caption">{{ $t('recommend.average_video_time') }}</label>
            </div>
          </div>
          <div class="video-time star">
            <img src="../../assets/img/star.png"/>
            <div class="time">
              <label class="value yellow">
                {{ Number(userStats.review_value).toFixed(1) }}
              </label>
              <label class="caption">{{ $t('recommend.average_review_point') }}</label>
            </div>
          </div>
          <button class="button btn-report" @click="onReport">
            <img src="../../assets/img/report.png"/>
          </button>
        </div>
        <div class="message-video">
          <button class="btn-refuse" @click="closeDialog">
            {{ $t('video.video_22') }}
          </button>
          <button class="btn-accept" @click="onAccept">
            {{ $t('video.video_23') }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./VideoReceive.scss"></style>

<script>
import { detectWebcam, pad, requestPost, showToast } from "../../lib/util";
import xmpp, { fromXmppUserInfo } from "../../lib/xmpp";
import { EVENT_NAME } from "../../lib/constant";
import UserImage from "../../components/UserImage";
import ProgressView from "../../components/ProgressView";
import StarView from "../../components/StarView";
import fcm from "../../lib/fcm";
import moment from "moment";

const VIDEO_RECEIVE_STATUS = {
  WAITING: 'waiting',
  ACCEPTING: 'accepting',
  CANCELED: 'canceled'
};

export default {
  name: 'VideoReceive',
  components: {
    UserImage,
    ProgressView,
    StarView,
  },
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    userStats: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    roomId: String,
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'action'],
  data() {
    let settingInfo = this.$store.getters.setting;
    return {
      myInfo: this.$store.getters.me,
      settingInfo: settingInfo,
      rejectTime: Math.abs(settingInfo.chatting_wait_time),
      curTime: Math.abs(settingInfo.chatting_wait_time),
      deviceTime: '',
      maxVideoTime: 0,
      timer: null,
      videoReceiveStatus: VIDEO_RECEIVE_STATUS.WAITING,
      backButtonRouteGuard: null,
    }
  },
  computed: {
    userInfo() {
      return this.user;
    },
    videoTime() {
      return moment(this.userStats.video_chat_time * 1000 + moment('02.05.2018 00:00:00').valueOf()).format('HH:mm:ss')
    },
  },
  watch: {
    show(show) {
      let _app = this;
      if (show) {
        // dialog show
        this.emitter.on(EVENT_NAME.VIDEO_REQUEST_CANCEL, this.onVideoRequestCancel);
        this.emitter.on(EVENT_NAME.VIDEO_REQUEST_REFUSE, this.closeDialog);
        this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.closeDialog);
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.closeDialog();
          next(false);
        });

        this.curTime = this.rejectTime;
        this.setLineBusy(1, function () {
          xmpp.directVideo = true;
          _app.startTimer();
        });
      } else {
        // dialog hide
        this.emitter.off(EVENT_NAME.VIDEO_REQUEST_CANCEL, this.onVideoRequestCancel);
        this.emitter.off(EVENT_NAME.VIDEO_REQUEST_REFUSE, this.closeDialog);
        this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.closeDialog);
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }

        this.stopTimer();
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        this.closeDialog();
      }
    },
    startTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }

      let _app = this;
      this.timer = setInterval(() => {
        if (_app.curTime > 0) {
          _app.curTime--;
          return;
        }

        _app.closeDialog();
      }, 1000);
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    acceptVideoChat() {
      this.stopTimer();

      let videoLog = this.roomId.split('_').filter(it => it != '');
      let maxTimeInSeconds = videoLog.length == 6 ? Number(videoLog[5]) : -1;

      let _app = this;
      this.videoReceiveStatus = VIDEO_RECEIVE_STATUS.ACCEPTING;
      requestPost(
        'video/acceptVideoChat',
        {
          out_class: this.userInfo.class,
          uid: this.myInfo.uid,
          time: videoLog[1],
          to_user: videoLog[2],
          from_user: videoLog[3]
        },
        async response => {
          if (_app.videoReceiveStatus == VIDEO_RECEIVE_STATUS.CANCELED) {
            showToast(_app.$t('video.video_01'), { nickname: _app.userInfo.nickname });
            _app.endVideoChat(videoLog[1], videoLog[2], videoLog[3], 0);
            return;
          }

          _app.myInfo.cash = response.my_cash;
          await _app.$store.dispatch('setMyInfo', _app.myInfo);

          xmpp.sendDirectVideo(_app.userInfo, 'accept', true);

          _app.maxVideoTime = maxTimeInSeconds;
          _app.deviceTime = videoLog[1];

          _app.$emit('action', {
            roomId: _app.roomId,
            xmppUserInfo: _app.userInfo,
            maxVideoTime: _app.maxVideoTime,
            freeChatTime: _app.settingInfo.free_chatting_time,
          });
        },
        (code, msg) => {
          if (code == -4 && _app.myInfo.user_sex == 0) {
            showToast(_app.$t('video.video_07'));
          } else {
            showToast(msg);
            _app.$emit('close');
          }
        }
      );
    },
    endVideoChat(time, toUser, fromUser, videoChatTime) {
      let _app = this;
      requestPost(
        'video/endVideoChat',
        {
          out_class: _app.userInfo.class,
          uid: _app.myInfo.uid,
          time: time,
          to_user: toUser,
          from_user: fromUser,
          video_sec: videoChatTime
        },
        async response => {
          if (_app.myInfo.user_sex == 1) {
            let receivedCash = Number(response.requester_cash) - Number(_app.myInfo.cash);
            if (receivedCash > 0) {
              showToast(_app.$t('video.video_08', { cash: receivedCash }));
            }
          }

          _app.myInfo.points = response.requester_point;
          _app.myInfo.cash = response.requester_cash;
          await _app.$store.dispatch('setMyInfo', _app.myInfo);

          _app.setLineBusy(0, () => {
            xmpp.directVideo = false;
            _app.$emit('close');
          });
        },
        (code, msg) => {
          showToast(msg);

          _app.setLineBusy(0, () => {
            xmpp.directVideo = false;
          });
          
          _app.$emit('close');
        }
      );
    },
    setLineBusy(busy, cb) {
      requestPost(
        'user/setLineBusy',
        {
          uid: this.myInfo.uid,
          busy: busy
        },
        () => {
          if (cb) {
            cb();
          }
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onVideoRequestCancel(user_id) {
      if (user_id == this.userInfo.userid) {
        if (this.videoReceiveStatus == VIDEO_RECEIVE_STATUS.WAITING) {
          showToast(this.$t('video.video_01', { nickname: this.userInfo.nickname }));
          this.setLineBusy(0, () => {
            xmpp.directVideo = false;
            this.$emit('close');
          });
        } else if (this.videoReceiveStatus == VIDEO_RECEIVE_STATUS.ACCEPTING) {
          this.videoReceiveStatus = VIDEO_RECEIVE_STATUS.CANCELED;
        }
      }
    },
    closeDialog() {
      let _app = this;
      xmpp.sendDirectVideo(this.userInfo, 'refuse', true);
      this.setLineBusy(0, function () {
        xmpp.directVideo = false;
        _app.$emit('close');
      });
    },
    onAccept() {
      detectWebcam((hasWebcam) => {
        if (hasWebcam) {
          this.acceptVideoChat();
        } else {
          showToast('웹캠이 연결되어 있지 않습니다.');
        }
      });
    },
    onReport: function () {
      this.$root.showReportDlg(fromXmppUserInfo(this.userInfo));
    },
  }
}
</script>
