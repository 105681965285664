<template>
  <img :src="this.imageSrc">
</template>
<script>
export default {
  name: "CountryFlag",
  props: {
    src: String
  },
  computed: {
    imageSrc() {
      if (this.src) {
        try {
          return require('../assets/flag/' + this.src.toLowerCase() + '.png')
        } catch (e) {
          if (e.name !== "ModuleNotFoundError" &&
            !e.message.startsWith('Cannot find module')) throw e; // handle false-positives
          return require('../assets/flag/world.png');
        }
      }
      return require('../assets/flag/world.png');
    },
  },
}
</script>
