<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_message" @click="$event.stopPropagation()">
        <div class="info">
          <UserImage :gender="user.user_sex" :image="user.user_profile_image"/>
          Message to <b>{{ user.user_nickname }}</b>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="message_box">
          <textarea v-model="message" :placeholder="$t('message.message_content_hint')"></textarea>
          <a href="javascript:" @click="onSend">{{ $t('common.send') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./SendMessage.scss"></style>

<script>
import UserImage from "../../components/UserImage";
import { loadData, requestPost, saveData, showToast } from "../../lib/util";
import dao from "../../lib/dao";
import xmpp from "../../lib/xmpp";

export default {
  name: 'SendMessage',
  components: {
    UserImage
  },
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    return {
      myInfo: this.$store.getters.me,
      settingInfo: this.$store.getters.setting,
      message: '',
      backButtonRouteGuard: null,
    }
  },
  computed: {},
  watch: {
    show(show) {
      if (show) {
        // dialog show
        this.message = loadData('LastMessage', '');
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_message').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    async onSend() {
      if (this.message == '') {
        showToast(this.$t('message.message_content_hint'));
        return;
      }

      let _app = this;

      // 처음으로 쪽지를 보내는 경우 포인트차감,스코어 적립.
      if (await dao.getMessagesCountWithUser(_app.user.uid) == 0) {
        requestPost(
          'friend/sentMessageWithPoints',
          {
            requester_user_id: _app.myInfo.uid,
            receiver_user_id: _app.user.uid
          },
          response => {
            _app.myInfo.points = response.sender_point;
            _app.$store.dispatch('setMyInfo', _app.myInfo);

            xmpp.sendMessageText(_app.user, _app.message);

            dao.saveUser(_app.user);
            dao.insertMessage(_app.user.uid, 1, _app.message, 'text', Date.now(), 0);

            showToast(_app.$t('message.msg_sent_message'));
            saveData('LastMessage', _app.message);

            _app.$emit('close');
          },
          (code, msg) => {
            showToast(msg);
            _app.$emit('close');
            _app.$root.onCharge();
          }
        );
      } else {
        xmpp.sendMessageText(_app.user, _app.message);

        dao.saveUser(_app.user);
        dao.insertMessage(_app.user.uid, 1, _app.message, 'text', Date.now(), 0);

        showToast(_app.$t('message.msg_sent_message'));
        saveData('LastMessage', _app.message);

        _app.$emit('close');
      }
    }
  }
}
</script>
