import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import xmpp from "../lib/xmpp";

const Login = () => import("@/views/startup/Login");
const Logout = () => import("@/views/startup/Logout");
const PhoneVerify = () => import("@/views/startup/PhoneVerify");
const Live = () => import("@/views/live/Live");
const Chat = () => import("@/views/chat/Chat");
const VideoChat = () => import("@/views/video/VideoChat");
const Recommend = () => import("@/views/recommend/Recommend");
const Moment = () => import("@/views/moment/Moment");
const Album = () => import("@/views/moment/Album");
const More = () => import("@/views/profile/More");
const Profile = () => import("@/views/profile/Profile");
const History = () => import("@/views/profile/History");
const Announce = () => import("@/views/profile/Announce");
const Charge = () => import("@/views/profile/charge/Charge");
const Guide = () => import("@/views/profile/Guide");
const Transfer = () => import("@/views/profile/Transfer");
const NotifySetting = () => import("@/views/profile/NotifySetting");
const GiftItems = () => import("@/views/profile/GiftItems");
const Refund = () => import("@/views/profile/Refund");

const routes = [
  {
    path: '/',
    component: Moment,
    meta: {
      auth: false
    },
  },
  {
    path: '/phone-verify',
    name: 'PhoneVerify',
    component: PhoneVerify
  },
  {
    path: '/login/:partnerType?/:partnerId?',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  {
    path: '/live',
    name: 'Live',
    component: Live,
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    meta: {
      auth: true
    },
  },
  {
    path: '/video-chat',
    name: 'VideoChat',
    component: VideoChat,
    meta: {
      auth: true
    },
  },
  {
    path: '/recommend',
    name: 'Recommend',
    component: Recommend,
  },
  {
    path: '/moment',
    name: 'Moment',
    component: Moment,
    meta: {
      auth: false
    },
  },
  {
    path: '/album/:uid',
    name: 'Album',
    component: Album,
  },
  {
    path: '/profile',
    name: 'More',
    component: More,
    meta: {
      auth: true
    },
  },
  {
    path: '/profile-edit',
    name: 'Profile',
    component: Profile,
    meta: {
      auth: true
    },
  },
  {
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      auth: true
    },
  },
  {
    path: '/announce',
    name: 'Announce',
    component: Announce,
    meta: {
      auth: true
    },
  },
  {
    path: '/charge',
    name: 'Charge',
    component: Charge,
    meta: {
      auth: true
    },
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide,
    meta: {
      auth: true
    },
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer,
    meta: {
      auth: true
    },
  },
  {
    path: '/notify-setting',
    name: 'NotifySetting',
    component: NotifySetting,
    meta: {
      auth: true
    },
  },
  {
    path: '/gift-items',
    name: 'GiftItems',
    component: GiftItems,
    meta: {
      auth: true
    },
  },
  {
    path: '/refund',
    name: 'Refund',
    component: Refund,
    meta: {
      auth: true
    },
  },
];

const authenticate = async () => {
  let myInfo = store.getters.me;
  if (!myInfo) {
    await store.dispatch('loadMyInfo');
  }
  if (xmpp.isConnected === false) {
    xmpp.start();
  }

  return store.getters.isLogin;
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  let auth = await authenticate();
  if (auth) {
    next();
  } else {
    if (to.meta.auth) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router;
