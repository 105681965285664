export default {
  app_name: 'ViewChat',
  app_error: {
    title: '予期しないエラーが発生しました。',
    msg: 'エラー情報が担当チームに配信されました。アプリを閉じてもう一度実行してください。',
    network: 'ネットワーク通信エラーが発生しました。',
    other_device_login: '他のデバイスで同じ番号でサインアップしました。',
    image_not_supported: 'サポートされていない画像形式です。',
    upload_file_not_exist: "アップロードファイルが存在しません。",
  },
  common: {
    male_short: '男',
    female_short: '女',
    male: "男性",
    female: "女性",
    age: "",
    preparing: "準備中...",
    yes: "はい",
    no: "いいえ",
    ok: "OK",
    cancel: "キャンセル",
    point: "ポイント",
    cash: "キャッシュ",
    bread: "パン",
    close: "閉じる",
    delete: "削除",
    second: "秒",
    send: "送信",
    create: "作る",
    submit: "提出",
    like: "いいね",
    jjim: "フォロー",
    message: "メッセージ",
    hits: "クリック数",
    next: "次",
    name: "名前",
    phone_number: "電話番号",
    years: "才",
    support_type: "point_system-error_payment-error_other",
    max: "最大",
    point_alert: "ポイント不足",
    bread_alert: "パンが足りません",
    done: "完了",
    top_title: "<b>{nickname}</b>さんのプロフィール",
    top_subtitle: "様々な機能とプロフィールを設定して、ビューチャットを利用できます。",
  },
  msg: {
    not_implemented_yet: "サービス準備中",
    please_wait: "お待ち下さい...",
    input_intro: "紹介コメントを選択してください",
    select_sex: "性別選択",
    select_age: "年齢選択",
    input_nickname: "ニックネームを入力して下さい。",
    nickname_too_short: "2文字以上入力して下さい。",
    nickname_too_long: "5文字以下で入力して下さい。",
    input_message: "メッセージ入力",
    sent_message: "メッセージを送信しました。",
    software_update: "ソフトウェアアップデート",
    select_country: "国選択",
    like_success: "いいね成功",
    please_select_photo: "イメージ選択",
    input_photo_content: "ストーリーを書いて下さい。",
    zzalbe_registered: "イメージが登録完了",
    zzalbe_registered_with_point: "{point} ポイントを貰いました。",
    zzalbe_success: "画像は検収後に掲載されます。",
    zzalbe_comment: "コメントを入力してください",
    block_user: "{nickname} ブロック",
    send_msg_disabled: "メッセージの送信に失敗しました",
    video_request_cancel: "{second} 秒後自動でキャンセルされます",
    video_waiting_time_out: "ビデオ通話申請時間が何秒です。しばらくしてからもう一度申請してください。",
    check_in: "チェックインしました。明日のチェックインをお忘れなく",
    profile_saved: "プロフィールを保存",
  },
  header_bar: {
    live: 'ビデオ通話',
    recom: 'おすすめ',
    photo: '写真',
    moment: 'タイムライン',
    chat: 'メッセージ',
    profile: 'プロフィール',
    follow: 'フォロー管理',
    charge: 'チャージ',
    history: '使用履歴',
    guide: '規約',
    point: 'ポイント:',
    agreement: "契約と方針",
    jp_purchase_policy: '特定商取引法に基づく表記',
    usage: '利用約款',
    privacy: '個人情報保護方針',
    ugc: 'コンテンツ契約',
    youth_policy: '青少年保護政策',
    company_name: 'Matenetworksjapan',
    boss: 'CEO : 鄭湘遇',
    biz: 'Biz No : 072-439-3135',
    southwest: '〒550-0015 大阪府大阪市西区西区南堀江１丁目１６−１１RE008',
    tel: ' TEL : 080-4391-3863',
    email_prefix: 'E-Mail : 828rokmcjsw',
    email_suffix: 'gmail.com',
    login: 'ログイン',
    logout: 'ログアウト',
    logout_confirm: 'ログアウトしてもよろしいですか？',
    top_button: "1番上へ",
    viewchat_point: "ViewChat ポイント",
    view_more: "もっとみる",
    viewchat_live: "View ライブ <b>Ranking</b>",
  },
  splash: {
    start_with_sns: 'SNSではじめる',
    view_chat_login: 'メールでログイン',
    view_chat_signup: '会員登録',
    forgot_password: 'パスワードを忘れましたか？',
  },
  reset_password: {
    title: 'パスワードのリセット',
    button_title: 'パスワードのリセット',
    completed: 'パスワードのリセットが完了しました。',
  },
  login: {
    title: "ビューチャット - ビデオチャット",
    login_01: "ビューチャット入会ありがとうございます",
    login_02: "楽しいフェイスチャット、楽しいビデオ通話",
    guide: '正解なIDとパスワードを入力してください',
    input_id: 'ID入力',
    input_pwd: 'パスワード入力',
    auto_login: '自動ログイン',
    login: 'ログイン',
  },
  phone: {
    verify: "確認",
    exit: "出る",
    str_adult_auth_title: "Title",
    str_adult_auth_text1_1: "Text1-1",
    str_adult_auth_text1_2: "Text1-2",
    str_adult_auth_text3_1: "Text3-1",
    str_adult_auth_text3_2: "Text3-2",
    str_adult_auth_text3_3: "Text3-3",
  },
  age_agreement: {
    title: '年齢認証',
    text1: 'あなたは18歳以上ですか?',
    text2: 'ここから先は、アダルト商品を扱うアダルトサイトとなります。<br/>18歳未満の方のアクセスは固くお断りします。',
    reject: 'Reject',
    agree: 'I Agree'
  },
  signup: {
    only_10_sec: '簡単な',
    sign_up: 'アカウントを作成する!',
    id_placeholder: 'ID (メールアドレス)',
    invalid_id: 'IDが存在しません',
    pwd: 'パスワード',
    pwd_confirm: 'パスワード入力',
    pwd_confirmed: 'パスワードが認証されました',
    agree_to_usage: '利用規約 または',
    select_profile: 'ログインイメージ選択',
    select_photo: 'イメージ選択',
    nickname_placeholder: 'ニックネームを入力してください。',
    yrs: 'yrs',
    pay_attention: '入会後 変更できません。ご注文願います。',
    start: 'スタート',
    title: "入会",
    signup: "入会",
    msg_input_nickname: "ニックネーム入力",
    msg_input_gender: "性別選択",
    msg_input_motto: "ストーリーを選択",
    msg_input_age: "年齢を入力してください",
    gender_warning: "性別は入会後変更不可です。",
    msg_nickname_too_short: "ニックネームは2文字以下では出来ません",
    msg_nickname_too_long: "ニックネームは5文以内",
    send_cert_key: "認証キーの送信",
    verify_cert_key: "認証キーの確認",
    msg_input_email: "メールアドレスを記入してください！",
    msg_input_cert_key: "認証キーを入力してください。",
    msg_sent_cert_key: "電子メールで認証キーを送信しました。以下のフィールドにコードを入力してください。",
    cert_key_placeholder: "認証キー",
    email_verified: "Eメール認証",
    msg_verify_email: "Eメールを入力",
    msg_input_password: "パスワード入力",
    msg_confirm_password: "パスワードを確認して下さい。",
  },
  live: {
    title: "{nickname}さん、ビューチャットにようこそ",
    subtitle: "{message_count}個の新しいメッセージと{notification_count}個の新しい通知があります。",
    all_country: "全ての国",
    more_country: "他の国",
    real_time_hot_country: "ライブ中の人気な国:",
    ranking: 'ランキング順',
    recent_login: '接続順',
    video_chatting: "チャット中",
    more: "詳しく",
  },
  recommend: {
    title: "ビューチャットおすすめ会員",
    subtitle: "ビューチャットがおすすめするリアルタイムビデオチャット会員です",
    recommend_today: '今日',
    recommend_recom: '推薦',
    recommend_members: '会員',
    recommend_users: "今アクセスしているおすすめ会員です。",
    total_star: "合計",
    total_star_2: "",
    total_star_3: "",
    recv_star: "受け取り!",
    album: "アルバム",
    jjim: "保存",
    message: "メッセージ送信",
    video: "ビデオ通話要請",
    intro: "紹介文",
    average_video_time: "平均ビデオ通話",
    video_time_below_15s: "15秒未満",
    video_time_below_1min: "1分未満",
    video_time_below_3min: "3分未満",
    video_time_above_3min: "3分以上",
    average_review_point: "平均点数",
    favourite_factor: "好み",
    belief_factor: "信頼",
    manner_factor: "マナ",
    beauty_factor: "魅力",
    delete: "削除",
    msg: 'メッセージ',
  },
  photo: {
    title: "ViewChat <b>タイムライン</b>",
    subtitle: "ビューチャットのタイムラインを楽しんでください。",
    category: "全体_Profile",
    category_types: "all1_zzalbe_gif_recommend_me",
    create: "投稿",
    guide: "ここの写真です。",
    hit: "ヒット ",
    write_title: "新規投稿",
    write_guide: "写真がなければ作ることができません.",
    write_desc: "その気持ち、シェアしよう",
    visit: "訪問者数",
    recommend: "推奨数",
    pick_photo: "写真を登録するにはクリックしてください",
    delete_success: "削除！",
    emoticon_delete: "絵文字を削除",
    emoticon_delete_confirm: "削除しますか？",
    emoticon_delete_success: "削除!",
    upload: "アップロード",
    bookmark: "ブックマーク",
    like: "いいね",
    gift: "プレゼント",
    report: "レポート",
    prev_contents: "以前のコンテンツ",
    next_contents: "次のコンテンツ",
    content_hashtag: '内容とハッシュタグ',
    hashtag: '# ハッシュタグ',
    hashtag_hint: '* 選択したハッシュタグは再選択するとクリアされます.',
    public_options: '公開オプション',
    free: '無料',
    followers: 'フォロワー',
    paid: '有料',
    paid_hint: '* 有料ガイドライン',
    hashtag_select: 'ハッシュタグ選択',
    hashtag_select_hint: 'ハッシュタグは3つまで選択可能です。',
    price_guideline: '価格ガイドライン',
    price_guideline_photo_1: '写真: ',
    price_guideline_photo_2: '20 {price} フラワーズ',
    price_guideline_video_1: 'ビデオ: ',
    price_guideline_video_2: '60 {price} フラワーズ',
    price_guideline_audio_1: 'オーディオ: ',
    price_guideline_audio_2: '30 {price} フラワーズ',
    price_must_be_nonzero: '価格はゼロ以外でなければなりません。',
    price_must_below: '価格は {maxPrice} 未満である必要があります.',
    public_to_followers: 'フォロワー公開',
  },
  video: {
    video_call_allow_necessary_permissions: "許可してください",
    video_01: "{nickname} ビデオ通話がキャンセルされました",
    video_02: "終了確認",
    video_03: "ビデオチャットを本当に終了しますか？",
    video_05: "ビデオチャットを終了してください",
    video_07: "チャットに不足しているポイント!",
    video_08: "おめでとうございます！ {cash}を得ました。",
    video_09: "{nickname}のポイントが足りず、ビデオチャットが終了しました",
    video_10: "ビデオチャットリクエスト",
    video_11: "ポイントが不足しています！",
    video_12: "不在中です。またお掛け直してください。",
    video_14: "不在中です。またお掛け直してください。",
    video_22: "拒否する",
    video_23: "通話する",
    video_24: "新しいビデオをリクエストする",
    video_25: "新しいグローバルビデオリクエスト",
    video_26: "({second}秒後に自動的に拒否されます)",
    video_27: "性別が同じです。他の性別についてもお願いします.",
    video_28: "Webカメラは使用できません。",
    video_profile_02: "{nickname}様をフォローしました。",
    global_user: "グローバルユーザー",
    global_user_warning: "グローバルユーザーです。<br/>2倍のポイントが課されます。",
    video_call_request: "接続中...",
    video_waiting_time_out: "リクエストタイムアウト。後でもう一度やり直す",
    review_title: "ビデオチャットはどうでしたか？",
    review_select_rate: "「{review}」どうでしたか？",
    review_select_rate_1: "開始の下で選択",
    review_select_rate_2: "<b>料金は累積していません。</b> <br/>正確に評価してください。",
    review_skip: "スキップする",
    review_success: "評価しました。",
    video_call_received: 'ビデオ通話',
    video_call_finished: 'ビデオチャット終了 {time}',
    after_8_seconds: '8秒後にギフトを送信できます。',
  },
  message: {
    message_09: "全てのメッセージ削除",
    message_10: "全てのメッセージを既読にする",
    message_11: "ここにメッセージがあります。",
    message_read: "既読",
    message_unread: "既読なし",
    message_delete_title: "削除",
    message_delete_confirm: "全てのメッセージ削除しますか？",
    message_read_title: "メッセージを見る",
    message_read_confirm: "全てのメッセージを既読にしますか？",
    message_sent_photo: "写真送信",
    message_sent_gift: "プレゼント送信",
    message_content_delete: "コンテンツ削除",
    message_content_delete_confirm: "メッセージを削除しますか？",
    message_content_placeholder: "メッセージを入力して下さい。",
    message_content_hint: "メッセージ入力",
    msg_send_msg_disabled: "メッセージ送信不可",
    msg_block_user: "{nickname} ブロック.",
    msg_like_success: "いいねしました。",
    msg_sent_message: "メッセージ送信成功",
    photo: "写真",
    jjim: "フォロー",
    block: "ブロック",
    report: "報告する",
    message_input: "メッセージ入力",
    send_message: "メッセージ送信",
    send_desc: "{point} Pが請求されます。<br/>警告メッセージ。",
    unread_messages: '未読メッセージ',
    viewchat_messages: 'ビューチャットを楽しんでね。',
    viewchat_messages_intro: 'メンバーとのメッセージを表示します。楽しい会話をしてください。',
    viewchat_messages_info: '合計<b>{total_count}</b>メッセージと<b>{unread_count}</b>未読メッセージがあります。',
    warning_message: '青少年保護法及び各審査機関による規定に違反した場合、使用制限及び法的処罰を受けることができます。',
  },
  profile: {
    title: "プロフィール修正",
    average_video_time: "平均映像時間",
    average_review_point: "平均レビューポイント",
    short_average_video_time: "平均映像時間",
    short_average_review_point: "平均レビューポイント",
    video_time_below_15s: "１５秒未満",
    video_time_below_1min: "１分未満",
    video_time_below_3min: "３分未満",
    video_time_above_3min: "３分以上",
    favourite_factor: "好み",
    belief_factor: "信頼",
    manner_factor: "マナー",
    beauty_factor: "美しい",
    profile_intro: "モットー",
    profile_notify: "通知",
    profile_12: "ニックネーム",
    profile_13: "8字以内で入力してください",
    profile_14: "年齢",
    profile_15: "性別",
    profile_16: "(変更不可)",
    profile_23: "保存",
    profile_24: "修正",
    profile_25: "ビデオプロフィール",
    profile_26: "基本アイコン",
    dibs: "フォロー",
    recommend: "いいね",
    album: "アルバム",
    message: "メッセージ",
    voice_chat: "音声通話",
    video_chat: "ビデオ通話",
    favorite: "好み",
    belive: "信頼",
    manner: "マナー",
    beauty: "美しい",
    photos: "写真",
  },
  album: {
    title: "アルバム",
    confirm: "削除しますか？"
  },
  more: {
    more_09: "チャージ",
    more_10: "プレゼントボックス",
    more_11: "ギフト",
    more_13: "使用履歴",
    more_14: "お問合せ",
    more_15: "規約",
    more_16: "お知らせ",
    more_21: "アップデートをチェック",
    more_22: "最新のバージョンを確認しました",
    more_30: "自分のアルバム",
    more_31: "デイリーボーナス",
    more_32: "お知らせ",
    more_33: "共有する",
    more_34: "毎回ポイントを支払います",
    more_35: "プロフィールの編集",
    more_36: "チートに注目!",
    more_37: "チートに気をつけてください",
    more_38: "회원",
    more_39: '50 ポイント',
    more_40: 'アカウント設定',
    more_41: 'キャッシュ交換',
    more_42: '通知ONの確認',
    more_43: '勧告する',
    more_44: '通知設定',
    more_45: 'オンライン友達になりたい人？',
    more_46: '新しいフィッシング詐欺に注意してください！',
    more_47: '他のMessengerやアプリを利用する際は、フィッシング詐欺にご注意ください。',
    leave: '退会する',
    leave_message: '退会時ポイントとキャッシュは全て消去されます。そして再登録は150日後になりますが、退会で宜しいでしょうか?',
    refund: '入金申請',
  },
  zzalbe: {
    message: "メッセージ",
    gift: "プレゼント",
    delete: "削除",
    reply: "返事",
    msg: "Msg",
  },
  notify: {
    setting_video_receive: "ビデオ",
    setting_video_receive_global: "グローバルビデオ",
    setting_sound: "音",
    setting_vibration: "マナーモード",
    setting_on: "ON",
    setting_off: "OFF",
  },
  log: {
    title: "使用履歴",
    log_list_02: "ポイント履歴",
    log_list_03: "キャッシュ履歴",
    point: "ポイント: ",
    cash: "キャッシュ: "
  },
  charge: {
    title: "チャージ",
    charge_please: "直接充電案内",
    direct_request: "直接リクエスト",
    desc1: "Account : NewYorkBank  304101-04-349026",
    desc2: "ご入金いただくアイテムを選択後、入金者名を残していただければ確認後処理させていただきます。",
    by_app: "by App",
    open_app: "アプリを開く",
    account_holder_name: "名前",
    input_name: "(名前入力.)",
    input_phone_number: "(電話番号入力.)",
    direct_success: "成功",
    amount: "金額:",
    charge_point: "ポイント:",
    charge_items: "アイテムをチャージしてください!",
    charge_direct: "すぐにチャージ",
    charge_purchase: "ご購入",
    charge_discount: "割引",
    charge_item_price: "$",
    vip_item_price: "W",
    charge_item_point: "P",
    charge_vip_title: "VIPアイテム",
    charge_vip_comment: "(VIP)",
    charge_vip_unavailable: "使用不可",
    charge_vip1_price: "50,000W",
    charge_vip2_price: "100,000W",
    charge_vip1_point: "35,000P",
    charge_vip2_point: "70,000P",
    charge_11: "無料チャージ",
    charge_12: "無料チャージ 1",
    charge_14: "無料チャージ 2",
    charge_15: "無料チャージ 3",
    charge_16: "乗り換え",
    charge_17: "現金＞ポイント",
    charge_18: "ポイント＞現金",
    purchase_success: "{point}ポイント購入完了",
    paypal_purchase_rewarded: "{point} ポイントが購入されました。",
    message_goto_app: 'この機能はスーパーカムアプリでのみ利用可能です。アプリを開いてみませんか？',
    paypal_purchase_success: '購入成功！\n有料ポイントが支払われるまでしばらくお待ちください。',
    select_item: 'アイテム',
    select_pg: 'お支払い方法を選択してください。',
    card: 'カード',
    tele_d: 'd払い',
    tele_au: 'auモバイル',
    tele_softbank: 'ソフトバンク',
    guide_title: 'ポイント案内',
    guide_text1: '1.本サービスは基本的に無料です。ただし、一部の機能を利用するためにはポイントを利用しなければいけません。',
    guide_text2: '2. DM送信とビデオ通話、ギフトアイテムなどはポイントを使用して購入できます。<br/>DM送信 - 1つに15P使用されます。<br/>ビデオ通話 - 1分に200P必要です。<br/> ギフトアイテム - アイテムの種類に応じて10〜900P必要です。',
    card_info: 'カード情報',
    card_no: 'カード番号',
    card_expire: 'カードの有効期限',
    card_holder_name: 'カード所有者名',
    card_security_code: 'カードセキュリティコード',
    input_card_no: 'カード番号を入力してください。',
    input_card_expire: 'カードの有効期限を入力してください。',
    input_card_holder_name: 'カード所有者名を入力してください。',
    input_card_security_code: 'カードのセキュリティ コードを入力してください。',
    item_select: 'アイテム',
    item4_hint: '3,000P おトク‼',
    item3_hint: '1,000P おトク‼',
    item2_hint: '¥ 1,000 おトク‼',
    item1_hint: '人気No.1',
    item0_hint: 'お試し価格',
  },
  gifticon: {
    gifticon_03: "名前",
    gifticon_04: "価格",
    gifticon_05: "電話",
    gifticon_06: "購入",
    gifticon_07: "案内",
    gifticon_10: "買う",
    gifticon_11: "ブランド",
    gifticon_12: "手数料",
    gifticon_13: "有効期限",
    gifticon_14: "有効期限は ",
    gifticon_15: "電話番号を入力してください",
    gifticon_16: "ギフトアイコンを売る",
    gifticon_02: "リクエストしました.",
    request_gifticon: "ギフトアイコンをリクエストしました",
    category_select: "カテゴリーを選択",
    category_1: "商品",
    category_2: "店",
    category_3: "クーポン",
    category_4: "ファストフード",
    category_5: "パン",
    category_6: "コーヒー",
    category_7: "食堂",
    category_8: "写真",
    category_9: "美容",
    category_10: "旅行",
    category_11: "デジタル",
    category_12: "赤ちゃん",
  },
  refund: {
    refund_agree_10: "払い戻し",
    refund_agree_11: "Attention",
    refund_agree_12: "案内文",
    refund_agree_13: "案内",
    refund_agree_14: "案内文",
    refund_03: "アカウントの所有者名を入力してください",
    refund_04: "住民番号を入力してください",
    refund_05: "電話番号入力",
    refund_06: "住所入力",
    refund_08: "口座番号入力",
    refund_10: "最小30000現金",
    refund_11: "現金が不足しています",
    refund_12: "振り込申請完了",
    refund_13: "保有パン",
    refund_14: "口座名義",
    refund_15: "電話番号",
    refund_16: "住民番号",
    refund_18: "住所",
    refund_19: "銀行",
    refund_20: "口座番号",
    refund_21: "口座番号入力",
    refund_22: "払い戻し現金 -（最小30000）",
    refund_27: "入金申請予定金額",
    refund_28: "入金申請",
    refund_29: "銀行選択",
    refund_30: "(Bonus",
    refund_31: "added)",
    refund_bank: "bank1_bank2_bank3_bank4",
    owner: "名義人の入力",
    phone_desc: "携帯電話番号を数字でのみ入力",
    resident_desc: "(-)ハイフンを除く数字でのみ入力)",
    address_desc: "住所を入力してください",
    cash_desc: "申請キャッシュ直接入力（千円単位で作成）",
    top_hint: '当日振込をご希望の場合は20時までに振込申請をしていただければ当日振込可能です。',
    middle_hint: '入金申請したキャッシュ-（1万キャッシュ以上から申請可能です）',
    bottom_hint: '【振込手数料】恐れ入りますが振込手数料はお客様の負担でお願いいたします。',
  },
  category: {
    category_1: "商品",
    category_2: "ショップ",
    category_3: "クーポン",
    category_4: "ファストフード",
    category_5: "パン",
    category_6: "コーヒー",
    category_7: "食堂",
    category_8: "Film",
    category_9: "美容",
    category_10: "旅行",
    category_11: "デジタル",
    category_12: "子ども",
  },
  transfer: {
    title: "変更",
    bread: "パン: ",
    point2bread: "ポイントをパンに変える",
    bread2point: "パンをポイントに変える",
  },
  ask: {
    ask_email: "Eメール",
    ask: "お問合せ",
    desc: "正しく入力してください",
    asked: "質問した",
    asked_message1: "ㆍ Message1.",
    asked_message2: "ㆍ Message2.",
    asked_message3: "ㆍ Message3.",
    ok: "いいね",
    ask_category: "カテゴリー",
    ask_content: "内容",
    ask_01: "Eメールアドレスを入力します",
    ask_02: "カテゴリーを選択",
    ask_03: "メッセージ入力",
    ask_04: "レシートの写真を選んでください",
    ask_05: "返事なし...",
    ask_06: "送信",
    ask_08: "正常にリクエストしました",
    ask_09: "Eメールアドレスを入力",
    ask_11: "画像のアップロード: ",
    ask_12: "* Attention text...",
    ask_13: "回答チェック",
    ask_14: "去る",
    ask_15: " ",
    agree: "次の事項に同意します",
    agree_content: "お問い合わせと回答は電子メールで送信され、電子メールは返信のためにのみ収集されます。",
    upload_desc: "お掛け間違いのないようご注意ください"
  },
  announce: {
    title: "お知らせ",
  },
  report: {
    title: "報告する",
    warning: "報告理由",
    reason: "スパム_気分が悪い_セクハラ_性的発言_その他",
    placeholder: 'カテゴリー',
    please_select_category: 'お問い合わせを選択してください',
    success: "報告しました。",
  },
  notify_setting: {
    title: "通知設定",
    video_notify: "ビデオ通知",
    video_notify_global: "グローバルビデオ通知",
    video_notify_sound: "音声ビデオ通知",
    video_notify_vibrate: "ビデオマナーモード",
  },
  country: {
    title: "全ての国",
    search: "探す",
    real_country: "リアルタイムの国",
    rank: "順位",
    more_country: "他の国々",
    max_country: "最大５つの国々",
    hot: "熱い",
    alpha: "アルファベット",
    thail: "タイ",
    viet: "ベトナム",
    rus: "ロシア",
    real_coun: "リアルタイムの国",
    country1: "Thailand",
    country2: "Uqurain",
    country3: "Russian",
    country4: "United States",
    country5: "Tailwan",
    country6: "AAA",
    warning1: "一つの国",
    country_code_list: "tha_uga_rus_usa_twn_alb",
    country_list: "Thailand_Uganda_Russian_United States_Taiwan_AAAA",
    all_country: "全ての国",
    apply: "適用しました",
    turn_off_global: "グローバルモードを解除",
  },
  guide: {
    title: "規約",
    usage_agreement: "利用規約",
    privacy_policy: "個人情報保護方針",
  },
  gift_items: {
    title: 'プレゼントボックス',
    buy: 'Buy',
    fingerheart: 'プチハート',
    flowers: '花束',
    ring: 'ダイアのリング',
    palace: '宮殿',
    megaheart: 'メガハート',
    gift_item_names: [
      'プチハート',
      '花束',
      'ダイアのリング',
      '宮殿',
      'メガハート',
    ],
  },
  gift_item_purchase: {
    title: 'プレゼントボックス',
    amount: "金額:",
    price: "ポイント:",
  },
  gift_item_send: {
    title: 'ギフトアイテムを送る',
    hint: '項目を選択したら送信ボタンをクリックしてください。',
    success: 'プレゼントを送信しました.',
  },
  gift_item_send_amount: {
    title: 'ギフトアイテムを転送する',
  },
  follow: {
    follower: 'フォロワー',
    following: 'フォロー',
    block: 'ブロック',
  },
  album: {
    profile_edit: 'プロフィール編集',
    write: '投稿',
    posts: '投稿',
    likes: 'いいね',
    followers: 'フォロワー',
    followings: 'フォロー中',
    motto_hint: 'メッセージを入力してください',
    input_motto: 'メッセージを入力してください！',
    save: '保存',
    video_call: 'ビデオ通話',
    send_message: 'メッセージを送る',
    follow: 'フォローする',
    unfollow: 'フォローを解除する',
    must_follow_each_other_text1: 'フォロワー限定の特別コンテンツです。',
    must_follow_each_other_text2: 'フォローして、今すぐアクセスしてください。',
    no_photos: 'コンテンツはまだありません。',
  },
  story_purchase: {
    title: 'この商品を購入しますか？',
    description1: '購入したストーリーアイテムはアプリ内でのみ使用できます。',
    description2:
      '保管・漏洩は禁止であり、違反した場合の責任はすべて使用者にあります。',
    item_not_enough: 'アイテムがありません。',
    purchase_confirm: '購入しますか？',
    purchase_success: 'ストーリーアイテムを購入しました。',
  },
};
