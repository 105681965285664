<template>
  <div class="top">
    <div class="logo">
      <a href="javascript:" @click="onTab(0)">
        <img src="../assets/img/logo.png">{{ $t('app_name') }}
      </a>
    </div>
    <div class="menu">
      <a href="javascript:" @click="onTab(10)"><img src="../assets/img/follow.png"
          style="width:16px;height:16px;margin-right: 5px;filter:invert(0);">{{ $t('header_bar.follow') }}</a>
      <a href="javascript:" @click="onTab(5)">{{ $t('header_bar.charge') }}</a>
      <a href="javascript:" @click="onTab(6)">{{ $t('header_bar.history') }}</a>
      <a href="javascript:" @click="onTab(7)">{{ $t('header_bar.guide') }}</a>
      <a v-if="myInfo != null" href="javascript:" @click="onLogout">{{ $t('header_bar.logout') }}</a>
      <a v-if="myInfo == null" href="javascript:" @click="onLogin">{{ $t('header_bar.login') }}</a>
      <a href="javascript:" @click="onTab(8)">
        <img src="../assets/img/ico_alarm.png">
      </a>
    </div>

    <div class="ham">
      <a href="javascript:" @click="onTab(10)" style="color:white;font-size: 14px;margin-right: 20px;"><img
          src="../assets/img/follow.png" style="width:16px;height:16px;margin-right: 5px;filter:invert(0);">{{
            $t('header_bar.follow') }}</a>
      <a href="javascript:" @click="onLogout">
        <img src="../assets/img/logout.png" style="padding-left: 2px;padding-right: 2px;margin-right: 16px;">
      </a>
      <a href="javascript:" @click="$emit('hamClick')">
        <img src="../assets/img/ham.png">
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped src="./HeaderBar.scss"></style>

<script>
import $ from "jquery";

export default {
  name: "HeaderBar",
  components: {},
  props: ['activeTab'],
  emits: ['hamClick', 'tabClick'],
  computed: {
    myInfo() {
      return this.$store.getters.me;
    },
    point() {
      if (this.$store.getters.isLogin) {
        return this.currency(this.$store.getters.me.points);
      } else {
        return '0';
      }
    }
  },
  methods: {
    onTab: function (tabIdx) {
      if (this.myInfo == null) {
        this.$router.replace({
          name: 'Logout'
        });
        return;
      }
      this.$emit('tabClick', tabIdx);
    },
    onLogout: function () {
      this.$router.replace({
        name: 'Logout'
      });
    },
    onLogin: function () {
      this.$router.replace({
        name: 'Logout'
      });
    },
    handleScroll(event) {
      var top = $(".top");
      var pageHeight = $(document).height();
      var pageWidth = $(document).width();
      let scroll = $(window).scrollTop();
      if (pageWidth < 760) {
        if (scroll > 50) {
          top.addClass("top_fixed");
        } else {
          top.removeClass("top_fixed");
        }
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>
