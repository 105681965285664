<template>
  <div class="container">
    <img :src="require(`../../../assets/img/gift_item${giftItemId + 1}.png`)">
    <span class="gift_item_name">{{ giftItemName }} x {{ amount }}</span>
  </div>
</template>

<script>
export default {
  name: "GiftSendMultiItem",
  emits: [],
  props: { message: { type: Object } },
  data() {
    let tokens = this.message.message.split('_');
    let itemId = parseInt(tokens[0]);
    let amount = parseInt(tokens[1]);
    return {
      giftItemId: itemId,
      amount: amount
    }
  },
  computed: {
    giftItemName() {
      return [
        this.$t('gift_items.fingerheart'),
        this.$t('gift_items.flowers'),
        this.$t('gift_items.ring'),
        this.$t('gift_items.palace'),
        this.$t('gift_items.megaheart'),
      ][this.giftItemId];
    }
  },
}
</script>

<style scoped>
.container {
  width: fit-content;
  background-color: #e5e5e5;
  padding: 12px 20px 14px 20px;
  border-radius: 10px;
  font-size: 17px;
  color: black;
  text-align: center;
  align-content: center;
  font-size: 16px;
}

@media only screen and (max-width:766px) {
  .container {
    padding: 10px 15px 12px 15px;
    border-radius: 5px;
    font-size: 15px;
  }
}
</style>