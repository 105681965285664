import { API_URL, APP_CLASS, APP_MARKET_CODE, OFFLINE_MODE, UPLOAD_API_URL } from "./constant";
import store from "../store";
import axios from 'axios';
import moment from "moment";

const qs = require('qs');

const PROGRESS_COLOR = '#257fff';

const headers = () => {
  let myInfo = store.getters.me;
  let appToken = store.getters.token;

  return {
    'App-Package': 'com.viewr.nchat',
    'App-Version-Code': '1000000000',
    'App-Version-Name': '',
    'App-Class': APP_CLASS,
    'App-Market-Code': APP_MARKET_CODE,
    'App-Uid': myInfo == null ? '' : myInfo.uid,
    'App-Token': appToken,
    'App-Lang': 'japanese',
  };
};

const requestApi = async (method, url, param, cbSuccess, cbError, showLoading) => {
  if (!method || !url) {
    return;
  }
  if (!param) {
    param = {};
  }
  if (showLoading === undefined) {
    showLoading = true;
  }

  try {
    let response = await axios.request({
      url: API_URL + url,
      method: method,
      headers: headers(),
      data: qs.stringify(param),
      responseType: 'json',
      transformRequest: (data, headers) => {
        if (showLoading) {
          store.dispatch('setLoading', true);
        }
        return data;
      }
    });

    let data = response.data;
    console.log('api request:', url)
    console.log('response data:', data)
    if (data.app_token) {
      await store.dispatch('setAppToken', data.app_token);
    }

    if (Number(data.result_code) == 0) {
      if (cbSuccess) {
        cbSuccess(data.result_data);
      }
    } else {
      if (cbError) {
        cbError(Number(data.result_code), data.result_msg);
      }
    }
  } catch (error) {
    console.log('api request:', url)
    console.log('failed response:', error)
    if (error.response) {
      let response = error.response;
      if (response.status == 300) {
        if (response.data) {
          try {
            let data = response.data;
            if (cbError) {
              cbError(Number(data.result_code), data.result_msg);
            }
          } catch (e) {
            if (OFFLINE_MODE) {
              console.error('>>> api error: ', e);
            }
          }
        }
      } else {
        if (cbError) {
          cbError(response.status, error.message);
        }
      }
    } else {
      if (cbError) {
        cbError(999, error.message);
      }
    }
  } finally {
    await store.dispatch('setLoading', false);
  }
};

export const requestGet = async (url, param, cbSuccess, cbError, showLoading) => {
  await requestApi('GET', url, param, cbSuccess, cbError, showLoading);
};

export const requestPost = async (url, param, cbSuccess, cbError, showLoading) => {
  await requestApi('POST', url, param, cbSuccess, cbError, showLoading);
};

export const requestUpload = async (filePath, param) => {
  if (!filePath) {
    return '';
  }
  if (!param) {
    param = {};
  }

  let fd = new FormData();
  fd.append('file', filePath);
  Object.keys(param).forEach(key => {
    fd.append(key, param[key]);
  });

  return new Promise(resolve => {
    store.dispatch('setLoading', true);
    axios.post(
      UPLOAD_API_URL + "/upload/uploadImage",
      fd,
      {
        headers: headers()
      }
    ).then(response => {
      if (response.status !== 200) {
        showToast('인터넷연결이 불안정합니다. 잠시 후 다시 시도해주세요.');
        return resolve('');
      }

      let res = response.data;
      if (res.result_code == 0) {
        return resolve(res.result_data.file_url);
      } else {
        showToast(res.result_msg);
        return resolve('');
      }
    }).catch(error => {
      showToast(error);
    }).then(() => {
      store.dispatch('setLoading', false);
    });
  });

};

export const requestUploads = async (files, param) => {
  if (files.length == 0) {
    return '';
  }
  if (!param) {
    param = {};
  }

  let fd = new FormData();
  files.forEach(file => {
    fd.append('file[]', file);
  });
  Object.keys(param).forEach(key => {
    fd.append(key, param[key]);
  });

  return new Promise(resolve => {
    store.dispatch('setLoading', true);
    axios.post(
      UPLOAD_API_URL + "/upload/uploadImages",
      fd,
      {
        headers: headers()
      }
    ).then(response => {
      if (response.status !== 200) {
        showToast('인터넷연결이 불안정합니다. 잠시 후 다시 시도해주세요.');
        return resolve('');
      }

      let res = response.data;
      if (res.result_code == 0) {
        return resolve(res.result_data.file_urls);
      } else {
        showToast(res.result_msg);
        return resolve('');
      }
    }).catch(error => {
      showToast(error);
    }).then(() => {
      store.dispatch('setLoading', false);
    });
  });

};

export const loadData = (key, defaultValue = null) => {
  let result = window.localStorage.getItem(key);
  if (result == null) {
    result = defaultValue;
  }

  return result;
};

export const saveData = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const showToast = (msg) => {
  window.alert(msg);
};

export const go2Url = (link) => {
  if (!link.startsWith('https://') && !link.startsWith('http://')) {
    link = 'http://' + link;
  }

  location.href = link;
};

export const pad = (val, len) => {
  let string = val.toString();
  return '0'.repeat(Math.max(0, len - string.length)) + string;
};

export const replaceAll = (str, search, replacement) => {
  return str.replace(new RegExp(search, 'g'), replacement);
};

export const currency = (value) => {
  return typeof value == "undefined" || value == '' ? '0' : value.toString()
    .replace(/,/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const randomNumberInRange = (from, to) => {
  return Math.floor(Math.random() * (to - from + 1) + from);
};

export const getImageThumbnailUri = (imageUrl) => {
  if (imageUrl.startsWith('file://')) {
    return imageUrl;
  }

  return imageUrl ? imageUrl.replace('/image_', '/s_image_') : '';
};

export const getUserDistance = (lng1, lat1, lng2, lat2) => {
  let distance = gps2km(lat1, lng1, lat2, lng2);
  return distance < 5000 && distance >= 0 ? Number(distance).toFixed(1) + 'km' : '???km';
};

export const gps2km = (lat_a, lng_a, lat_b, lng_b) => {
  if (lat_a == 0 && lng_a == 0 || lat_b == 0 && lng_b == 0)
    return -1.0;

  let pk = 180 / Math.PI;
  let a1 = lat_a / pk;
  let a2 = lng_a / pk;
  let b1 = lat_b / pk;
  let b2 = lng_b / pk;

  let t1 = Math.cos(a1) * Math.cos(a2) * Math.cos(b1) * Math.cos(b2);
  let t2 = Math.cos(a1) * Math.sin(a2) * Math.cos(b1) * Math.sin(b2);
  let t3 = Math.sin(a1) * Math.sin(b1);
  let tt = Math.acos(t1 + t2 + t3);

  return 6366000 * tt;
};

export const printDuration = seconds => {
  let hour = pad(Math.floor(seconds / 3600), 2);
  let min = pad(Math.floor((seconds - hour * 3600) / 60), 2);
  let sec = pad(seconds - hour * 3600 - min * 60, 2);

  return `${hour}:${min}:${sec}`;
};

export const isGlobalUser = user => {
  return user.user_class == 48 || user.user_class == 15 || user.user_class == 51;
}

export const isMobile = () => {
  return window.innerWidth <= 768;
}

export const detectWebcam = (callback) => {
  if (window.location.host.indexOf('chatsee.me') == -1) {
    return callback(true);
  }

  let md = navigator.mediaDevices;
  if (!md || !md.enumerateDevices) return callback(false);
  md.enumerateDevices().then(devices => {
    callback(devices.some(device => 'videoinput' === device.kind));
  })
}

export const getTermsUrl = (terms) => {
  const host = window.location.host;
  const parts = host.split('.');

  switch (parts[0]) {
    case 'jp':
      if (terms === 'usage_agreement') {
        terms = 'index';
      } else if (terms === 'youth_policy') {
        terms = 'guideline';
      } else if (terms === 'ugc') {
        terms = 'UGC';
      } else if (terms === 'jp_purchase_policy') {
        terms = 'shop';
      }
      return `https://viewchat.applipolicy.com/${terms}_jpn.html`;
    default:
      return `https://${parts[0]}.chatsee.me/terms/${terms}.html`;
  }
}

export const getMaxLengthLimitedString = (string) => {
  if (string.length <= 6) {
    return string;
  } else {
    return string.substring(0, 5) + '...';
  }
}

export const setMomentLocale = () => {
  const host = window.location.host;
  const parts = host.split('.');

  switch (parts[0]) {
    case 'jp':
      moment.locale('ja');
    default:
      moment.locale(parts[0]);
  }
}