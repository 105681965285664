<template>
  <transition name="fade">
    <div v-if="show">
      <div class="calling_wrapper" @click="onCancel" />
      <div class="calling_back" @click="$event.stopPropagation()" />
      <div class="calling">
        <div class="count">
          <p>{{ leftTime }}</p>
        </div>
        <h3>{{ $t('video.video_call_request') }}</h3>
        <a @click="onCancel"
          style="margin-top: 20px; width:60px;height:60px;border-radius: 30px;background-color: #f25c62;display: flex;align-items: center;justify-content: center;">
          <img srcset="../../assets/img/video_hangout@3x.png 3x" />
      </a>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./VideoRequest.scss"></style>

<script>
import { pad, requestPost, saveData, showToast } from "../../lib/util";
import xmpp from "../../lib/xmpp";
import { EVENT_NAME } from "../../lib/constant";
import dao from "../../lib/dao";

export default {
  name: 'VideoRequest',
  components: {},
  props: {
    user: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'action'],
  data() {
    let settingInfo = this.$store.getters.setting;
    return {
      myInfo: this.$store.getters.me,
      settingInfo: settingInfo,
      rejectTime: settingInfo == null ? 15 : Math.abs(settingInfo.chatting_wait_time),
      curTime: 0,
      deviceTime: '',
      maxVideoTime: 0,
      roomUrl: '',
      roomId: '',
      timer: null,
      backButtonRouteGuard: null,
    }
  },
  computed: {
    userInfo() {
      return this.user;
    },
    leftTime() {
      return pad(this.curTime, 2);
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        this.initEvent();
        this.initData();
        this.doVideoRequest();
        document.addEventListener('keyup', this.onKeyPress);
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);

        saveData('last_video_call_finish_time', Date.now());
        this.emitter.off(EVENT_NAME.NEW_MESSAGE_VIDEO, this.onNewVideo);
        this.stopTimer();
        xmpp.directVideo = false;
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        this.onCancel();
      }
    },
    startTimer() {
      if (this.timer) {
        clearInterval(this.timer);
      }

      let _app = this;
      this.timer = setInterval(() => {
        if (_app.curTime > 0) {
          _app.curTime--;
          return;
        }

        _app.stopTimer();
        showToast(_app.$t('video.video_waiting_time_out'));
        saveData('last_video_call_finish_time', Date.now());
        _app.$emit('close');
      }, 1000);
    },
    stopTimer() {
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    onNewVideo(packet) {
      if (packet.fromUser.userid != this.userInfo.uid) {
        return;
      }
      switch (packet.content) {
        case 'refuse':
          showToast(this.$t('video.video_12'));
          saveData('last_video_call_finish_time', Date.now());
          this.$emit('close');
          break;
        case 'busy':
          showToast(this.$t('video.video_14'));
          saveData('last_video_call_finish_time', Date.now());
          this.$emit('close');
          break;
        case 'accept':
          this.stopTimer();
          this.$emit('action', {
            name: 'accept',
            data: {
              roomUrl: this.roomId,
              roomId: this.roomUrl,
              maxTime: parseInt(this.maxVideoTime == 0 ? this.settingInfo.free_chatting_time : this.maxVideoTime),
              deviceTime: this.deviceTime,
              freeChatTime: parseInt(this.settingInfo.free_chatting_time),
            }
          });
          break;
      }
    },
    onCancel() {
      saveData('last_video_call_finish_time', Date.now());
      xmpp.sendDirectVideoCancel(this.userInfo, this.roomId);
      this.$emit('close');
    },
    initEvent() {
      this.emitter.on(EVENT_NAME.NEW_MESSAGE_VIDEO, this.onNewVideo);
    },
    initData() {
      xmpp.directVideo = true;
      this.curTime = this.rejectTime;
    },
    doVideoRequest() {
      this.deviceTime = Date.now().toString();
      let _app = this;
      requestPost(
        'video/requestVideoChat',
        {
          uid: this.myInfo.uid,
          time: this.deviceTime,
          to_user: this.userInfo.uid,
          from_user: this.myInfo.uid
        },
        async response => {
          _app.settingInfo.video_call_delay = response.video_call_delay;
          _app.settingInfo.video_call_delay_message = response.video_call_delay_message;
          await _app.$store.dispatch('setSettingInfo', _app.settingInfo);

          _app.myInfo.points = response.point;
          await _app.$store.dispatch('setMyInfo', _app.myInfo);

          _app.maxVideoTime = response.max_video_time * 60;

          if (response.is_ai == 1) {
            let tokens = response.roomid.split('/?r=');
            _app.roomUrl = tokens[0];
            _app.roomId = tokens[1];

            xmpp.directVideo = true;

            _app.stopTimer();

            // go to video chatting
            _app.$emit('action', {
              name: 'accept',
              data: {
                roomUrl: _app.roomUrl,
                roomId: _app.roomId,
                maxTime: parseInt(_app.maxVideoTime == 0 ? _app.settingInfo.free_chatting_time : _app.maxVideoTime),
                deviceTime: _app.deviceTime,
                freeChatTime: parseInt(_app.settingInfo.free_chatting_time)
              }
            });
          } else {
            _app.roomUrl = response.roomid;
            _app.roomId = `camtalk_${_app.deviceTime}_${_app.userInfo.uid}_${_app.myInfo.uid}_0_${_app.maxVideoTime}`;

            xmpp.sendDirectVideo(_app.userInfo, `${_app.roomUrl}/?r=${_app.roomId}`);

            _app.curTime = _app.rejectTime;
            _app.startTimer();
          }

          dao.saveUser(_app.userInfo);
          dao.insertMessage(_app.userInfo.uid, 1, _app.$t('video.video_10'), 'video_request', Date.now(), 0);
        },
        (code, msg) => {
          if (code == -4 && _app.myInfo.user_sex == 0) {
            showToast(_app.$t('video.video_11'));
            _app.$emit('action', {
              name: 'charge'
            });
          } else if (code == -4 && _app.myInfo.user_sex == 1) {
            xmpp.sendDirectVideo(_app.userInfo, `/?r=camtalk_${_app.deviceTime}_${_app.userInfo.uid}_${_app.myInfo.uid}_0_${_app.maxVideoTime}`);
            showToast(msg);
            _app.$emit('action', {
              name: 'fail'
            });
          } else {
            showToast(msg);
            _app.$emit('action', {
              name: 'fail'
            });
          }
        }
      );
    },
  }
}
</script>
