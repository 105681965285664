<template>
  <transition name="fade">
    <div class="pop_back" @click="$emit('close')">
      <div class="pop_alert" @click="$event.stopPropagation()">
        <a href="javascript:" @click="$emit('close')" class="close">
          <img src="../../assets/img/btn_close.png">
        </a>
        <div class="title" v-if="title != ''"><p>{{ title }}</p></div>
        <div class="content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "BasePopup",
  props: {
    title: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
  },
  emits: ['close'],

  data() {
    return {
      backButtonRouteGuard: null,
    }
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyPress);
    this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
      this.$emit('close');
      next(false);
    });
  },
  beforeUnmount() {
    document.removeEventListener('keyup', this.onKeyPress);
    if (this.backButtonRouteGuard) {
      this.backButtonRouteGuard();
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_alert').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
  }
}
</script>

<style lang="css" scoped>
.pop_back {position:fixed;top:0;left:0;width:100%;height:100%;background-color:rgba(0, 0, 0, .5);z-index:1000;display: flex;overflow-y: scroll;}

.pop_alert {position:fixed;width:300px;height:auto;left:50%;top:50%;margin-left:-190px;margin-top:-130px;padding:20px;background-color:white;border-radius:15px;text-align:center;}
.pop_alert .close {position:absolute;right:10px;top:10px;z-index:10;}
.pop_alert .close img {width:20px;height:20px;border-radius:0;opacity:0.5;}
.pop_alert .title {font-size:17px;font-weight:600;padding:10px 10px 0px 10px;}
.pop_alert .content {position:relative;padding:20px 20px;}

@media only screen and (max-width:1000px) {

}

@media only screen and (max-width:766px) {
  .pop_alert {width:calc(100% - 20px);height:auto;left:0;bottom:0;top:auto;margin-left:0;margin-top:0;padding:10px;border-radius:15px;border-bottom-left-radius:0;border-bottom-right-radius:0;}
  .pop_alert .title {font-size:15px;font-weight:600;padding:10px 10px 0px 10px;}
  .pop_alert .content {padding:20px 20px;}
}
</style>