<template>
  <img v-if="image" v-lazy="image" class="photo" style="object-fit: cover;"/>
  <img v-else :src="(gender == '1' ? female_profile : male_profile)" class="photo" style="object-fit: contain;"/>
</template>

<script>
export default {
  name: "UserImage",
  emits: ['click'],
  props: ['gender', 'image'],
  data() {
    return {
      male_profile: require('../assets/img/member_male.png'),
      female_profile: require('../assets/img/member_female.png'),
    }
  },
}
</script>
