<template>
  <p :style="'width:' + (value / maxValue * 100) + '%'"></p>
</template>
<script>
export default {
  name: "ProgressView",
  props: {
    value: Number,
    maxValue: Number,
  },
  data() {
    return {}
  },
}
</script>
