<template>
  <transition name="fade">
    <BasePopup :title="title" @close="$emit('close')">
      <div>
        <span>{{ content }}</span>
      </div>
      <div class="bottom_btns">
        <GrayButton style="flex: 1;" :title="cancel" @click="$emit('close')" />
        <PrimaryButton style="flex: 1;margin-left: 10px;" :title="ok" @click="$emit('ok')" />
      </div>
    </BasePopup>
  </transition>
</template>

<script>
import GrayButton from '../buttons/GrayButton.vue';
import PrimaryButton from '../buttons/PrimaryButton.vue';
import BasePopup from './BasePopup.vue';

export default {
  name: "ConfirmPopup",
  components: {
    BasePopup,
    PrimaryButton,
    GrayButton,
  },
  props: {
    title: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    content: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    ok: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    cancel: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
  },
  emits: ['close', 'ok'],
}
</script>

<style lang="css" scoped>
.bottom_btns {display:flex; flex-direction: row;width:100%;margin-top: 20px;}
</style>