import { createI18n } from "vue-i18n";
import cn from './locales/cn';
import en from './locales/en';
import ko from './locales/ko';
import in1 from './locales/in';
import jp from './locales/jp';
import ru from './locales/ru';

const host = window.location.host;
const parts = host.split('.');

export default new createI18n({
  locale: parts[0],
  fallbackLocale: 'jp',
  messages: {
    'cn': cn,
    'en': en,
    'ko': ko,
    'in': in1,
    'jp': jp,
    'ru': ru,
  }
});
