import { createStore } from 'vuex';
import { loadData, saveData } from "../lib/util";

export default createStore({
  state: {
    loading: false,
    me: null,
    setting: null,
    updateInfo: null,
    appToken: '',
    mottos: []
  },
  getters: {
    isLoading(state) {
      return state.loading;
    },
    isLogin(state) {
      return state.me != null;
    },
    me(state) {
      return state.me;
    },
    setting(state) {
      return state.setting;
    },
    updateInfo(state) {
      return state.updateInfo;
    },
    mottos(state) {
      return state.mottos;
    },
    token(state) {
      return state.appToken;
    }
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },
    setMyInfo(state, info) {
      state.me = info;
      if (info == null) {
        saveData('my_info', null);
      } else {
        saveData('my_info', JSON.stringify(state.me));
        saveData('NotifySound', state.me.NotifySound ? 'on' : 'off');
        saveData('NotifyVibrate', state.me.NotifyVibrate ? 'on' : 'off');
      }
    },
    loadMyInfo(state) {
      let myInfo = loadData('my_info');
      if (myInfo != null) {
        state.me = JSON.parse(myInfo);
      } else {
        state.me = null;
      }
      state.setting = JSON.parse(loadData('setting_info'));
      state.appToken = loadData('app_token', '');
      state.updateInfo = JSON.parse(loadData('update_info'));
      state.mottos = JSON.parse(loadData('mottos'));
    },
    setSettingInfo(state, setting) {
      state.setting = setting;
      saveData('setting_info', JSON.stringify(setting));
    },
    setUpdateInfo(state, info) {
      state.updateInfo = info;
      saveData('update_info', JSON.stringify(info));
    },
    setMottos(state, mottos) {
      state.mottos = mottos;
      saveData('mottos', JSON.stringify(mottos));
    },
    setAppToken(state, token) {
      state.appToken = token;
      saveData('app_token', token);
    }
  },
  actions: {
    setLoading(state, loading) {
      state.commit('setLoading', loading);
    },
    setMyInfo(state, info) {
      state.commit('setMyInfo', info);
    },
    loadMyInfo(state) {
      state.commit('loadMyInfo');
    },
    setSettingInfo(state, setting) {
      state.commit('setSettingInfo', setting);
    },
    setUpdateInfo(state, info) {
      state.commit('setUpdateInfo', info);
    },
    setMottos(state, mottos) {
      state.commit('setMottos', mottos);
    },
    setAppToken(state, token) {
      state.commit('setAppToken', token);
    }
  }
});
