import store from "../store";
import { requestPost, showToast } from "./util";


const OneStore_AppID = 'OA00749586';
const SKU_LIST = [
  "product.3700p",
  "product.6200p",
  "product.18600p",
  "product.35800p",
  "product.60000p",
  "product.150000p",
  "item_vip_35000p",
  "item_vip_70000p"
];

const iap = {

  buyPoint: point => {
    window.store.order(SKU_LIST[point]);
  },

  finishPurchase: p => {
    let myInfo = store.getters.me;

    p.finish();

    requestPost(
      'iap/boughtPoint',
      {
        uid: myInfo.uid,
        PurchaseData: p.receipt,
        DataSignature: p.signature
      },
      async response => {
        myInfo.points = response.point;
        await store.dispatch('setMyInfo', myInfo);

        showToast(this.currency(response.purchased_point) + "포인트를 구매하였습니다.");
      },
      (code, msg) => {
        showToast(msg);
      }
    );
  },

  onError: error => {
    console.log('ERROR ' + error.code + ': ' + error.message);
  },

  init: function () {

    document.addEventListener('deviceready', () => {
      if (!window.store) {
        return;
      }

      SKU_LIST.forEach(item => {
        window.store.register({
          id: item,
          alias: item,
          type: window.store.CONSUMABLE
        });

        window.store.when(`${item} approved`, p => {
          p.verify();
        });

        window.store.when(`${item} verified`, this.finishPurchase);
      });

      window.store.error(this.onError);
    });
  }
};

export default iap;
