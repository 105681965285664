import { requestPost, saveData, showToast } from "./util";
import store from "../store";
import { EVENT_NAME, FCM_VAPID_KEY, OFFLINE_MODE } from "./constant";
import dao from "./dao";
import xmpp from "./xmpp";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";


const PUSH_NOTE = 1;
const PUSH_FREE_CHARGE_POINT_RECEIVED = 2;
const PUSH_ZZALBE_GIFT_POINT_RECEIVED = 3;
const PUSH_ZZALBE_EVENT_POINT_RECEIVED = 4;
const PUSH_QNA_REPLY = 5;
const PUSH_PROFILE_REVIEWED = 6;
const PUSH_ZZALBE_REVIEWED = 7;
const PUSH_TALK_REVIEWED = 8;
const PUSH_RANK_BEST_EVENT_POINT_RECEIVED = 9;
const PUSH_ZZALBE_NEW_COMMENT = 10;
const PUSH_NICKNAME_DELETED = 11;
const PUSH_REFUND_STATUS_CHANGED = 12;
const PUSH_BONUS_POINT_RECEIVED = 13;
const PUSH_JJIM_USER_LOGIN = 14;
const PUSH_USER_BLACKED = 15;
const PUSH_USER_REPORTED = 16;
const PUSH_XMPP_MESSAGE = 17;
const PUSH_PURCHASE_HINTB = 19;
const PUSH_NICKNAME_APPROVED = 20;
const PUSH_NICKNAME_REJECTED = 21;
const PUSH_MOTTO_APPROVED = 22;
const PUSH_MOTTO_REJECTED = 23;
const PUSH_ADMIN_MESSAGE = 24;
const PUSH_RANK_BEST_EVENT_CASH_RECEIVED = 25;
const PUSH_PURCHASE_PAYPAL = 26;
const PUSH_VIDEO_CALL_REQUEST = 100;
const PUSH_ACCEPT_VIDEO_CALL = 101;
const PUSH_REJECT_VIDEO_CALL = 102;
const PUSH_CANCEL_VIDEO_CALL = 103;

const fcm = {
  emitter: null,

  subscribe: topic => {
    if (typeof FCMPluginNG == "undefined" || !topic) {
      return;
    }

    FCMPluginNG.subscribeToTopic(topic);
  },

  unsubscribe: topic => {
    if (typeof FCMPluginNG == "undefined" || !topic) {
      return;
    }

    FCMPluginNG.unsubscribeFromTopic(topic);
  },

  saveToken: token => {
    if (!token) {
      return;
    }

    saveData('FCMToken', token);

    const myInfo = store.getters.me;
    if (store.getters.isLogin) {
      requestPost(
        'user/setUserDeviceToken',
        {
          uid: myInfo.uid,
          device_token: token
        },
        () => {
        },
        () => {
        },
        false
      );
    }
  },

  getAdminInfo: () => {
    return {
      uid: 0,
      user_nickname: '管理者',
      user_sex: 1,
      user_age: 30,
      user_profile_image: '',
      user_profile_video: '',
      user_latitude: 0,
      user_longitude: 0,
      user_like_cnt: 0,
      user_country_code: '',
      user_class: ''
    };
  },

  processData: async data => {
    const settingInfo = store.getters.setting;
    const myInfo = store.getters.me;

    let type = Number(data['type']);
    let message = data['message'];
    var customData = data['custom_data'];
    if (customData != null) {
      customData = JSON.parse(customData);
    }

    if (type >= PUSH_VIDEO_CALL_REQUEST && (settingInfo && settingInfo.external_integration_enable == 1)) {
      fcm.handlePushCall(data);
      return;
    }

    let jjimNotify = myInfo && myInfo.uid > 0;

    if (
      type == PUSH_NOTE ||
      type == PUSH_FREE_CHARGE_POINT_RECEIVED ||
      type == PUSH_ZZALBE_GIFT_POINT_RECEIVED ||
      type == PUSH_ZZALBE_EVENT_POINT_RECEIVED ||
      type == PUSH_QNA_REPLY ||
      type == PUSH_PROFILE_REVIEWED ||
      type == PUSH_ZZALBE_REVIEWED ||
      type == PUSH_TALK_REVIEWED ||
      type == PUSH_RANK_BEST_EVENT_POINT_RECEIVED ||
      type == PUSH_ZZALBE_NEW_COMMENT ||
      type == PUSH_NICKNAME_DELETED ||
      type == PUSH_REFUND_STATUS_CHANGED ||
      type == PUSH_BONUS_POINT_RECEIVED ||
      (type == PUSH_JJIM_USER_LOGIN && jjimNotify) ||
      type == PUSH_USER_BLACKED ||
      type == PUSH_USER_REPORTED ||
      type == PUSH_PURCHASE_HINTB ||
      type == PUSH_NICKNAME_APPROVED ||
      type == PUSH_NICKNAME_REJECTED ||
      type == PUSH_MOTTO_APPROVED ||
      type == PUSH_MOTTO_REJECTED ||
      type == PUSH_ADMIN_MESSAGE ||
      type == PUSH_RANK_BEST_EVENT_CASH_RECEIVED
    ) {
    }

    if (type == PUSH_FREE_CHARGE_POINT_RECEIVED) {
      let paidPoint = customData['paid_point'];
      let newPoints = customData['new_points'];

      if (myInfo) {
        myInfo.points = newPoints;
        await store.dispatch('setMyInfo', myInfo);
      }
      fcm.emitter.emit(EVENT_NAME.PURCHASE_SUCCESS, {newPoints: newPoints, paidPoint: paidPoint});
    } else if (type == PUSH_PURCHASE_PAYPAL) {
      let paidPoint = customData['paid_point'];
      let newPoints = customData['new_points'];

      if (myInfo) {
        myInfo.points = newPoints;
        await store.dispatch('setMyInfo', myInfo);
      }
      fcm.emitter.emit(EVENT_NAME.PAYPAL_PURCHASE_SUCCESS, {newPoints: newPoints, paidPoint: paidPoint});
    } else if (
      type == PUSH_QNA_REPLY ||
      type == PUSH_PROFILE_REVIEWED ||
      type == PUSH_ZZALBE_REVIEWED ||
      type == PUSH_TALK_REVIEWED ||
      type == PUSH_ZZALBE_REVIEWED ||
      type == PUSH_RANK_BEST_EVENT_POINT_RECEIVED ||
      type == PUSH_NICKNAME_DELETED ||
      type == PUSH_REFUND_STATUS_CHANGED ||
      type == PUSH_BONUS_POINT_RECEIVED ||
      type == PUSH_USER_BLACKED ||
      type == PUSH_NICKNAME_APPROVED ||
      type == PUSH_NICKNAME_REJECTED ||
      type == PUSH_MOTTO_APPROVED ||
      type == PUSH_MOTTO_REJECTED ||
      type == PUSH_ADMIN_MESSAGE ||
      type == PUSH_RANK_BEST_EVENT_CASH_RECEIVED
    ) {
      dao.saveUser(fcm.getAdminInfo());

      if (message != '' && type != PUSH_ADMIN_MESSAGE) {
        dao.insertMessage(0, 0, message, 'text', Date.now(), 1);
      }
      if (type == PUSH_ADMIN_MESSAGE) {
        let imageUrl = customData['image_url'];
        if (imageUrl != '') {
          dao.insertMessage(0, 0, message == '' ? imageUrl : `${imageUrl}###${message}`, 'image', Date.now(), 1);
        } else {
          dao.insertMessage(0, 0, message, 'text', Date.now(), 1);
        }
      }
      if (type == PUSH_PROFILE_REVIEWED) {
        myInfo.user_profile_image = customData['profile_image'];
        myInfo.user_profile_video = customData['profile_video'];
        await store.dispatch('setMyInfo', myInfo);
      } else if (type == PUSH_NICKNAME_DELETED) {
        myInfo.user_nickname = '채팅하자~';
        await store.dispatch('setMyInfo', myInfo);
      } else if (type == PUSH_NICKNAME_APPROVED || type == PUSH_NICKNAME_REJECTED) {
        myInfo.user_nickname = customData['nickname'];
        await store.dispatch('setMyInfo', myInfo);
      } else if (type == PUSH_MOTTO_APPROVED || type == PUSH_MOTTO_REJECTED) {
        myInfo.user_motto = customData['motto'];
        await store.dispatch('setMyInfo', myInfo);
      }
    } else if (type == PUSH_ZZALBE_EVENT_POINT_RECEIVED) {
      myInfo.points = customData['new_points'];
      await store.dispatch('setMyInfo', myInfo);

      dao.saveUser(fcm.getAdminInfo());
      dao.insertMessage(0, 0, message, 'text', Date.now(), 1);
    } else if (type == PUSH_ZZALBE_GIFT_POINT_RECEIVED) {
      let newPoints = customData['receiver_new_points'];
      let sentPoints = customData['sent_points'];
      let senderUserInfo = customData['sender_user_info'];

      myInfo.points = newPoints;
      await store.dispatch('setMyInfo', myInfo);

      dao.saveUser({
        uid: senderUserInfo['fd_id'],
        user_nickname: senderUserInfo['fd_nickname'],
        user_sex: senderUserInfo['fd_sex'],
        user_age: senderUserInfo['fd_age'],
        user_profile_image: senderUserInfo['profile_image'],
        user_profile_video: senderUserInfo['profile_video'],
        user_latitude: senderUserInfo['fd_latitude'],
        user_longitude: senderUserInfo['fd_longitude'],
        user_like_cnt: senderUserInfo['like_cnt'],
        user_country_code: '',
        user_class: ''
      });
      dao.insertMessage(senderUserInfo['fd_id'], 0, `${sentPoints}`, 'gift_point_sent', Date.now(), 1);
    } else if (type == PUSH_JJIM_USER_LOGIN) {

    } else if (type == PUSH_USER_REPORTED) {
      let senderUserInfo = customData['sender_user_info'];
      dao.saveUser({
        uid: senderUserInfo['fd_id'],
        user_nickname: senderUserInfo['fd_nickname'],
        user_sex: senderUserInfo['fd_sex'],
        user_age: senderUserInfo['fd_age'],
        user_profile_image: senderUserInfo['profile_image'],
        user_profile_video: senderUserInfo['profile_video'],
        user_latitude: senderUserInfo['fd_latitude'],
        user_longitude: senderUserInfo['fd_longitude'],
        user_like_cnt: senderUserInfo['like_cnt'],
        user_country_code: '',
        user_class: ''
      });
      dao.insertMessage(senderUserInfo['fd_id'], 0, message, 'text', Date.now(), 1);
    }

    if (type == PUSH_XMPP_MESSAGE) {
      let pathname = '';
      if (window && window.location) {
        pathname = window.location.pathname;
      }
      if (pathname.endsWith('phone_verify')) {
        return;
      }

      if (myInfo && xmpp.connection == null) {
        xmpp.start();
      }
    }

    if (type == PUSH_USER_BLACKED) {
      showToast(message);
      // navigator.app.exitApp();
    }
  },

  handlePushCall: data => {
    const myInfo = store.getters.me;
    const settingInfo = store.getters.setting;

    let type = Number(data['type']);
    let customData = data['custom_data'];

    if (type == PUSH_VIDEO_CALL_REQUEST) {
      let roomId = customData['room_id'];
      let targetClassNum = customData['target_class'];
      let targetUserId = customData['target_user_id'];
      let targetUserNickname = customData['target_user_nickname'];
      let targetUserGender = customData['target_user_gender'];

      if (xmpp.directVideo) {

      } else {
        if (myInfo.user_video_alarm != 0) {

        } else {
          let videoLog = roomId.split('_').filter(it => it != '');
          let nowTime = Date.now();
          let roomTime = Number(videoLog[1]);

          nowTime -= roomTime;
          if (nowTime < 60000) {
            requestPost(
              'user/getAverageVideoChatTimesAndReviewValues',
              {
                uid: myInfo.uid,
                peer_uid: targetUserId
              },
              response => {
                fcm.emitter.emit(EVENT_NAME.VIDEO_RECEIVE, {
                  userInfo: {
                    userid: targetUserId,
                    nickname: targetUserNickname,
                    sex: targetUserGender,
                    class: targetClassNum
                  },
                  userStats: response,
                  roomId: roomId
                });
              },
              (code, msg) => {
                console.log('>>> video receive load stats fail due to ', msg);
              },
              false
            );
          }
        }
      }
    } else if (type == PUSH_CANCEL_VIDEO_CALL) {
      fcm.emitter.emit(EVENT_NAME.VIDEO_REQUEST_CANCEL, customData['target_user_id']);
    }
  },

  init: function (emitter) {
    fcm.emitter = emitter;

    if (OFFLINE_MODE) {
      return;
    }

    const firebaseApp = initializeApp({
      apiKey: "AIzaSyBx4q0VBAyPAEarnIs3A7OWu4J3ZpWC_1g",
      authDomain: "viewchat-rn.firebaseapp.com",
      databaseURL: "https://viewchat-rn.firebaseio.com",
      projectId: "viewchat-rn",
      storageBucket: "viewchat-rn.appspot.com",
      messagingSenderId: "277906408897",
      appId: "1:277906408897:web:a2cdf388206ca441d89b40",
      measurementId: "G-GLKGS3XRZE"
    });

    const messaging = getMessaging(firebaseApp);
    getToken(messaging, {vapidKey: FCM_VAPID_KEY})
      .then(async token => {
        if (token) {
          await fcm.saveToken(token);
        }
      })
      .catch(e => {
        console.log('>>> Firebase retrieving token error: ', e);
      });

    onMessage(messaging, payload => {
      fcm.processData(payload.data);
    });
  }
};

export default fcm;
