<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_follow" @click="$event.stopPropagation()">
        <div class="follow_top">
          <div class="tab_bar">
            <div class="tab_button" :class="{ selected: category === 0 }">
              <a href="javascript:" @click="onCategory(0)">{{ $t('follow.follower') }}</a>
            </div>
            <div class="tab_button" :class="{ selected: category === 1 }">
              <a href="javascript:" @click="onCategory(1)">{{ $t('follow.following') }}</a>
            </div>
            <div class="tab_button" :class="{ selected: category === 2 }">
              <a href="javascript:" @click="onCategory(2)">{{ $t('follow.block') }}</a>
            </div>
          </div>
          <div class="close_button">
            <a href="javascript:" @click="$emit('close')">
              <img class="close" src="../../assets/img/btn_close.png">
            </a>
          </div>
        </div>
        <div class="follow_box">
          <ul class="scroll">
            <li v-for="(user, idx) in userList" :key="idx">
              <div style="display:flex;flex-direction: row;align-items: center;flex-grow: 1;" @click="onUserClick(user)">
                <UserImage :gender="user.user_sex" :image="user.user_profile_image" />
                <div class="nickname_gender_age">
                  <div class="nickname">{{ user.user_nickname }}</div>
                  <div class="gender_age" :class="[{ male: user.user_sex == 0, female: user.user_sex == 1 }]">({{
                    (user.user_sex == 1
                      ? $t('common.female_short') : $t('common.male_short')) + user.user_age }})</div>
                </div>
              </div>
              <div v-if="category == 0 && user.is_following == 0" class="btn_red">
                <a href="javascript:" @click="onFollow(user)">
                  {{ $t('common.jjim') }}
                </a>
              </div>
              <div class="btn_gray">
                <a href="javascript:" @click="onDelete(user)">
                  {{ $t('common.delete') }}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { requestPost, showToast } from "../../lib/util";
import { PAGE_SIZE } from "../../lib/constant";
import InfiniteLoading from 'v3-infinite-loading';
import UserImage from "../../components/UserImage";

export default {
  name: 'Follow',
  components: {
    InfiniteLoading,
    UserImage,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close'],
  data() {
    let myInfo = this.$store.getters.me;
    return {
      myInfo: myInfo,
      category: 0,
      userList: [],
      loadingEnd: false,
      backButtonRouteGuard: null,
    }
  },
  mounted() {
    this.userList = [];
    this.loadingEnd = false;
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        this.email = '';
        this.category = 0;
        this.userList = [];
        this.loadingEnd = false;
        this.initList();
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        this.$emit('close');
      }
    },
    onCategory: function (_category) {
      this.category = _category;
      this.initList();
    },
    onUserClick: function (user) {
      this.$root.showUserDlg(user);
    },
    onFollow: function (user) {
      let _app = this;
      requestPost(
        'jjim/createJjim',
        {
          uid: this.myInfo.uid,
          peer_uid: user.uid
        },
        () => {
          _app.initList();
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onDelete: function (user) {
      let _app = this;

      if (this.category == 0) {
        requestPost(
          'jjim/deleteJjim',
          {
            uid: user.uid,
            peer_uid: this.myInfo.uid
          },
          () => {
            _app.initList();
          },
          (code, msg) => {
            showToast(msg);
          }
        );
      } else if (this.category == 1) {
        requestPost(
          'jjim/deleteJjim',
          {
            uid: this.myInfo.uid,
            peer_uid: user.uid
          },
          () => {
            _app.initList();
          },
          (code, msg) => {
            showToast(msg);
          }
        );
      } else {
        requestPost(
          'block/unblockUser',
          {
            uid: this.myInfo.uid,
            peer_uid: user.uid
          },
          () => {
            _app.initList();
          },
          (code, msg) => {
            showToast(msg);
          }
        );
      }
    },
    initList: function () {
      this.userList = [];
      this.loadingEnd = false;

      this.getFollowUsers();
    },
    getFollowUsers: function ($state) {
      let _app = this;
      if (this.loadingEnd) {
        return;
      }
      if (this.category === 0 || this.category === 1) {
        requestPost(
          'jjim/readJjim',
          {
            uid: this.myInfo.uid,
            by_me: this.category,
            offset: this.userList.length,
            limit: PAGE_SIZE
          },
          response => {
            _app.loadingEnd = response.jjim_list.length < PAGE_SIZE;
            _app.userList = _app.userList.concat(response.jjim_list);
            if ($state) {
              if (_app.loadingEnd) {
                $state.complete();
              }
              $state.loaded();
            }
          },
          (code, msg) => {
            showToast(msg);
            if ($state) {
              $state.loaded();
            }
          }
        );
      } else {
        requestPost(
          'block/getBlockedUsers',
          {
            uid: this.myInfo.uid,
            offset: this.userList.length,
            limit: PAGE_SIZE
          },
          response => {
            _app.loadingEnd = response.blocked_users.length < PAGE_SIZE;
            _app.userList = _app.userList.concat(response.blocked_users);
            if ($state) {
              if (_app.loadingEnd) {
                $state.complete();
              }
              $state.loaded();
            }
          },
          (code, msg) => {
            showToast(msg);
            if ($state) {
              $state.loaded();
            }
          }
        );
      }
    },
  },
}
</script>

<style scoped>
.pop_follow {
  position: fixed;
  width: 400px;
  height: auto;
  left: 50%;
  top: 50%;
  margin-left: -240px;
  margin-top: -240px;
  padding: 40px;
  background-color: white;
  border-radius: 15px;
  z-index: 110;
}

.pop_follow .follow_top {
  position: relative;
}

.pop_follow .follow_top .tab_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pop_follow .follow_top .tab_bar .tab_button {
  font-size: 14px;
  font-weight: bold;
  padding: 8px 10px;
  margin: 0 20px;
}

.pop_follow .follow_top .tab_bar .tab_button.selected {
  border-bottom: solid 3px #333;
}

.pop_follow .follow_top .close_button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.pop_follow .follow_top .close_button img {
  width: 20px;
}

.pop_follow .follow_box {
  padding: 10px 0px;
}

.pop_follow .follow_box li {
  padding: 15px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pop_follow .follow_box li .nickname_gender_age {
  margin-left: 20px;
  margin-right: auto;
}

.pop_follow .follow_box li .nickname {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.pop_follow .follow_box li .gender_age {
  font-size: 12px;
  margin-top: 4px;
}

.pop_follow .follow_box li img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.pop_follow .btn_red {
  font-size: 14px;
  font-weight: 700;
  background-color: #e24c4d;
  color: white;
  border-radius: 10px;
  padding: 7px 15px;
  text-align: center;
  margin: 0 3px;
}

.pop_follow .btn_gray {
  font-size: 14px;
  font-weight: 700;
  background-color: #999;
  color: white;
  border-radius: 10px;
  padding: 7px 15px;
  text-align: center;
  margin: 0 3px;
}

.scroll {
  overflow-y: scroll;
  height: 500px;
}

@media only screen and (max-width:766px) {
  .pop_follow {
    width: calc(100% - 20px);
    left: 0;
    bottom: 0;
    top: auto;
    margin-left: 0;
    margin-top: 0;
    padding: 10px;
    border-radius: 15px;
    z-index: 101;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
