<template>
  <span class="footer">{{ message.time }}</span>
</template>

<script>
export default {
  name: "TimeItem",
  emits: [],
  props: { message: { type: Object } },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.footer {
  display: block;
  font-size: 11px;
  opacity: 0.4;
}
</style>