import { EVENT_NAME, XMPP_SERVER, XMPP_SERVER_DOMAIN } from "./constant";
import { Strophe } from "strophe.js";
import store from "../store";

export const PacketType = {
  Chat: 0,
  Message: 1,
  Video: 2,
  Gift: 3,
  Voice: 4
};

export const PacketSubType = {
  ChatRequest: 0,
  ChatAccept: 1,
  ChatRefuse: 2,
  ChatStart: 3,
  ChatEnd: 4,
  ChatBusy: 5,
  ChatText: 6,
  ChatImage: 7,
  MessageText: 8,
  MessageImage: 9,
  Video: 10,
  CancelVideo: 11,
  GiftItemMulti: 12,
  GiftItem: 13,
  Voice: 14,
  CancelVoice: 15,
  MessageDelete: 16
};
// todo: delete export
export const fromApiUserInfo = apiUserInfo => {
  // return XmppUserInfo
  if (!apiUserInfo) {
    return {};
  }

  return {
    class: parseInt(apiUserInfo.user_class),
    userid: apiUserInfo.uid.toString(),
    nickname: apiUserInfo.user_nickname,
    sex: parseInt(apiUserInfo.user_sex),
    age: parseInt(apiUserInfo.user_age),
    motto: apiUserInfo.user_motto,
    profileimage: apiUserInfo.user_profile_image,
    profilevideo: '',
    area: parseInt(apiUserInfo.user_area),
    latitude: parseFloat(apiUserInfo.user_latitude),
    longitude: parseFloat(apiUserInfo.user_longitude),
    like_cnt: parseInt(apiUserInfo.user_like_cnt),
    busy: parseInt(apiUserInfo.user_line_busy),
    roomid: 0,
    countryCode: apiUserInfo.user_country_code
  };
};

export const fromXmppUserInfo = xmppUserInfo => {
  // return ApiUserInfo
  if (!xmppUserInfo) {
    return {};
  }

  return {
    user_class: parseInt(xmppUserInfo.class),
    uid: xmppUserInfo.userid,
    user_nickname: xmppUserInfo.nickname,
    user_sex: xmppUserInfo.sex.toString(),
    user_age: xmppUserInfo.age.toString(),
    user_motto: xmppUserInfo.motto,
    user_profile_image: xmppUserInfo.profileimage,
    user_profile_video: '',
    user_area: parseInt(xmppUserInfo.area),
    user_latitude: parseFloat(xmppUserInfo.latitude),
    user_longitude: parseFloat(xmppUserInfo.longitude),
    user_like_cnt: parseInt(xmppUserInfo.like_cnt),
    user_line_busy: parseInt(xmppUserInfo.busy),
    user_country_code: xmppUserInfo.countryCode
  };
};

const XmppPacket = function (type, subType, fromUser, toUser, content) {
  this.type = type;
  this.subType = subType;
  this.fromUser = fromApiUserInfo(fromUser);
  this.toUser = fromApiUserInfo(toUser);
  this.content = content;
  this.timeStamp = Date.now();
};
XmppPacket.prototype.toJsonStr = function () {
  return JSON.stringify({
    type: this.type,
    subtype: this.subType,
    from_user: this.fromUser,
    to_user: this.toUser,
    content: this.content,
    timestamp: this.timeStamp
  });
};
XmppPacket.prototype.fromJsonStr = function (json) {
  let data = JSON.parse(json);
  this.type = data.type;
  this.subType = data.subtype;
  this.fromUser = data.from_user;
  this.toUser = data.to_user;
  this.content = data.content;
  this.timeStamp = data.timestamp;
  return this;
};

const xmpp = {
  emitter: null,
  connection: null,

  directVideo: false,
  isConnected: false,

  getJidFromUid: (uid) => {
    return `${uid}@${XMPP_SERVER_DOMAIN}`;
  },

  setupConnection: () => {
    xmpp.connection = new Strophe.Connection(XMPP_SERVER);
    xmpp.connection.rawInput = xmpp.onReceive;
    xmpp.connection.rawOutput = xmpp.onSend;
  },

  connect: (id, pwd) => {
    if (!xmpp.connection) {
      xmpp.setupConnection();
    }

    xmpp.isConnected = false;
    xmpp.connection.connect(xmpp.getJidFromUid(id), pwd, xmpp.onConnect);
  },

  disconnect: () => {
    if (!xmpp.connection) {
      return;
    }

    xmpp.connection.disconnect();
  },

  start: () => {
    const myInfo = store.getters.me;
    if (myInfo) {
      xmpp.connect(myInfo.uid, myInfo.xmpp_password);
    }
  },

  send: packet => {
    if (!xmpp.connection) {
      return;
    }
    if (xmpp.isConnected === false) {
      xmpp.start();
      return;
    }

    let body = $build('body', null);
    body.h(packet.toJsonStr());

    let m = $msg({
      type: 'chat',
      to: xmpp.getJidFromUid(packet.toUser.userid),
      from: xmpp.getJidFromUid(packet.fromUser.userid)
    }).cnode(body.tree());
    xmpp.connection.send(m.tree());
  },

  sendMessageText: (user, content) => {
    const myInfo = store.getters.me;
    const packet = new XmppPacket(PacketType.Message, PacketSubType.MessageText, myInfo, user, content);
    xmpp.send(packet);
  },

  sendMessageImage: (user, imageUrl) => {
    const myInfo = store.getters.me;
    const packet = new XmppPacket(PacketType.Message, PacketSubType.MessageImage, myInfo, user, imageUrl);
    xmpp.send(packet);
  },

  sendDirectVideo: (user, roomId, xmppUser = false) => {
    const myInfo = store.getters.me;
    const packet = new XmppPacket(PacketType.Video, PacketSubType.Video, myInfo, xmppUser ? fromXmppUserInfo(user) : user, roomId);
    xmpp.send(packet);
  },

  sendDirectVideoCancel: (user, roomId) => {
    const myInfo = store.getters.me;
    const packet = new XmppPacket(PacketType.Video, PacketSubType.CancelVideo, myInfo, user, roomId);
    xmpp.send(packet);
  },

  sendGiftItem: (user, itemId) => {
    const myInfo = store.getters.me;
    const packet = new XmppPacket(PacketType.Gift, PacketSubType.GiftItem, myInfo, user, (itemId + 10).toString());
    xmpp.send(packet);
  },

  sendGiftItemMulti: (user, itemId, amount) => {
    const myInfo = store.getters.me;
    const packet = new XmppPacket(PacketType.Gift, PacketSubType.GiftItemMulti, myInfo, user, `${itemId}_${amount}`);
    xmpp.send(packet);
  },

  sendGiftItemInVideo: (user, itemId) => {
    const myInfo = store.getters.me;
    const packet = new XmppPacket(PacketType.Gift, PacketSubType.GiftItem, myInfo, user, itemId);
    xmpp.send(packet);
  },

  processMessage: packet => {
    const myInfo = store.getters.me;

    if (packet.fromUser.userid == myInfo.uid) {
      return;
    }
    if (packet.toUser.userid != myInfo.uid) {
      return;
    }

    switch (packet.type) {
      case PacketType.Message:
        if (packet.subType == PacketSubType.MessageText) {
          xmpp.emitter.emit(EVENT_NAME.NEW_MESSAGE_TEXT, packet);
        } else {
          xmpp.emitter.emit(EVENT_NAME.NEW_MESSAGE_IMAGE, packet);
        }
        break;
      case PacketType.Video:
        if (packet.subType == PacketSubType.Video) {
          let pathname = '';
          if (window && window.location) {
            pathname = window.location.pathname;
          }
          if (pathname.endsWith('phone_verify')) {
            xmpp.sendDirectVideo(packet.fromUser, 'busy', true);
            return;
          }

          if (myInfo.user_video_alarm == 0) {
            if (xmpp.directVideo && !['refuse', 'busy', 'accept'].includes(packet.content)) {
              xmpp.sendDirectVideo(packet.fromUser, 'busy', true);
            } else {
              xmpp.emitter.emit(EVENT_NAME.NEW_MESSAGE_VIDEO, packet);
            }
          } else {
            if (['refuse', 'busy', 'accept'].includes(packet.content)) {
              xmpp.emitter.emit(EVENT_NAME.NEW_MESSAGE_VIDEO, packet);
            } else {
              xmpp.sendDirectVideo(packet.fromUser, 'refuse', true);
            }
          }
        } else {
          xmpp.emitter.emit(EVENT_NAME.VIDEO_REQUEST_CANCEL, packet.fromUser.userid);
        }
        break;
      case PacketType.Gift:
        xmpp.emitter.emit(EVENT_NAME.NEW_MESSAGE_GIFT, packet);
        break;
      default:
    }
  },

  onMessage: message => {
    let to = message.getAttribute('to');
    let from = message.getAttribute('from');
    let type = message.getAttribute('type');
    let elems = message.getElementsByTagName('body');
    if (type == 'chat' && elems.length > 0) {
      let body = elems[0];
      let packet = new XmppPacket().fromJsonStr(body.innerHTML);
      if (packet) {
        try {
          xmpp.processMessage(packet);
        } catch (e) {
          console.log('xmpp.onMessage error', e);
        }
      }
    }

    return true;
  },

  onConnect: (status, condition, elem) => {
    switch (status) {
      case Strophe.Status.CONNECTING:
        xmpp.isConnected = false;
        break;
      case Strophe.Status.CONNFAIL:
        xmpp.isConnected = false;
        break;
      case Strophe.Status.DISCONNECTING:
        xmpp.isConnected = false;
        break;
      case Strophe.Status.DISCONNECTED:
        xmpp.isConnected = false;
        setTimeout(() => {
          xmpp.start();
        }, 100);
        break;
      case Strophe.Status.CONNECTED:
        xmpp.connection.addHandler(xmpp.onMessage, null, 'message', null, null, null);
        xmpp.isConnected = true;
        xmpp.connection.send($pres().tree());
        break;
    }
  },

  onReceive: (data) => {
  },

  onSend: () => {

  },

  init: emitter => {
    xmpp.emitter = emitter;
    xmpp.setupConnection();
  }
};

export default xmpp;
