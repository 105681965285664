<template>
  <img v-for="i in Math.round(value)" :key="i"
       src="../assets/img/ico_avg02.png">
  <img v-for="i in (5 - Math.round(value))" :key="i"
       src="../assets/img/ico_avg02.png" class="gray">
</template>
<script>
export default {
  name: "StarView",
  props: {
    value: Number,
  },
}
</script>
