import initSqlJs from 'sql.js';
import sqlWasm from "!!url-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm";
import { EVENT_NAME } from "./constant";

const localDB = {
  db: null,
  storeName: 'chat',

  open: function () {
    return new Promise(resolve => {
      let self = this;
      let request = indexedDB.open('viewchat', 1);

      request.onupgradeneeded = function () {
        // The database did not previously exist, so create object stores and indexes.
        let db = request.result;
        db.createObjectStore(self.storeName, {keyPath: 'id'});
      };

      request.onsuccess = function () {
        self.db = request.result;
        resolve();
      };

      request.onerror = function (e) {
        console.log(e);
      };

      request.onblocked = function (e) {
        console.log(e);
      }
    });
  },

  setItem: function (data) {
    let db = this.db;
    let trans = db.transaction(this.storeName, "readwrite");
    let store = trans.objectStore(this.storeName);
    let request = store.put({
      "id": 1,
      "binaryData": data
    });

    request.onsuccess = function (e) {
    };

    request.onerror = function (e) {
      console.log(e.value);
    };
  },

  getItem: function () {
    return new Promise(resolve => {
      let db = this.db;
      let trans = db.transaction(this.storeName);
      let store = trans.objectStore(this.storeName);
      let request = store.get(1);

      request.onerror = function (e) {
        console.log(e);
      };
      request.onsuccess = function (e) {
        return resolve(e.target.result ? e.target.result.binaryData : null);
      };
    });
  },
};

const dao = {
  db: null,
  emitter: null,

  checkTables: () => {
    dao.db.run(
      `
        CREATE TABLE IF NOT EXISTS "User" (
          "userId" INTEGER UNIQUE ON CONFLICT REPLACE,
          "nickname" TEXT,
          "sex" INTEGER,
          "age" INTEGER,
          "profileImage" TEXT,
          "profileVideo" TEXT,
          "latitude" INTEGER,
          "longitude" REAL,
          "likeCnt" INTEGER,
          "countryCode" TEXT,
          "appClass" INTEGER
        )
        `
    );
    dao.db.run(
      `
        CREATE TABLE IF NOT EXISTS "Notification" (
          "id" INTEGER NOT NULL,
          "uid" INTEGER,
          "nickname" TEXT,
          "sex" INTEGER,
          "age" INTEGER,
          "profileImage" TEXT,
          "message" TEXT,
          "time" REAL,
          "status" INTEGER,
          "type" INTEGER,
          "param" INTEGER,
          PRIMARY KEY ("id")
        )
        `
    );
    dao.db.run(
      `
        CREATE TABLE IF NOT EXISTS "Message" (
          "id" INTEGER NOT NULL,
          "uid" INTEGER,
          "isSent" INTEGER,
          "message" TEXT,
          "type" TEXT,
          "time" REAL,
          "status" INTEGER,
          PRIMARY KEY ("id"),
          CONSTRAINT "FK_USER" FOREIGN KEY ("uid") REFERENCES "User" ("userId") ON DELETE CASCADE ON UPDATE NO ACTION
        )
        `
    );

    dao.emitter.emit(EVENT_NAME.CHECK_DB);
    console.log('dao: Create & Check Tables -> OK');
  },

  execute: (query, param, cbOk) => {
    if (!dao.db) {
      if (cbOk) {
        cbOk();
      }
      return;
    }

    if (!param) {
      param = cbOk ? {} : [];
    }

    if (cbOk) {
      let stmt = dao.db.prepare(query);
      stmt.bind(param);

      let result = [];
      while (stmt.step()) {
        result.push(stmt.getAsObject());
      }

      stmt.free();
      cbOk(result);
    } else {
      dao.db.run(query, param);

      let data = dao.db.export();
      localDB.setItem(data);
    }
  },

  // status : 0-read, 1-unread
  insertMessage: (uid, isSent, message, type, time, status) => {
    dao.execute(
      'INSERT INTO Message VALUES (?,?,?,?,?,?,?)',
      [null, uid, isSent, message, type, time, status]
    );
  },

  getRecentMessages: () => {
    let query = "SELECT Message.*, User.*," +
      "(SELECT COUNT(*) FROM Message WHERE Message.uid = User.userId AND Message.status = 1 AND (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent')) AS unreadNumber" +
      " FROM Message INNER JOIN User ON Message.uid = User.userId" +
      " WHERE Message.id IN (" +
      " SELECT MAX(Message.id) FROM Message" +
      // " WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent')" +
      " GROUP BY Message.uid" +
      " )" +
      " ORDER BY Message.id DESC";

    return new Promise(resolve => {
      dao.execute(
        query,
        null,
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push({
                user: item,
                message: item
              });
            });
          }
          return resolve(result);
        }
      );
    });
  },

  getLastRecentMessage: () => {
    let query = "SELECT Message.*, User.*," +
      "(SELECT COUNT(*) FROM Message WHERE Message.uid = User.userId AND Message.status = 1 AND (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent')) AS unreadNumber" +
      " FROM Message INNER JOIN User ON Message.uid = User.userId" +
      " WHERE Message.id IN (" +
      " SELECT MAX(Message.id) FROM Message" +
      " WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent')" +
      " GROUP BY Message.uid" +
      " )" +
      " LIMIT 1";

    return new Promise(resolve => {
      dao.execute(
        query,
        null,
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push({
                user: item,
                message: item
              });
            });
          }
          return resolve(result);
        }
      );
    });
  },

  getMessagesWithUser: uid => {
    return new Promise(resolve => {
      dao.execute(
        // "SELECT Message.* FROM Message WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent') AND Message.uid = $uid",
        "SELECT Message.* FROM Message WHERE Message.uid = $uid",
        {$uid: uid},
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push(item);
            });
          }
          return resolve(result);
        }
      );
    });
  },

  getUnreadMessageNumber: () => {
    return new Promise(resolve => {
      dao.execute(
        "SELECT COUNT(*) cnt FROM Message WHERE Message.status = 1",
        null,
        response => {
          let result = 0;
          if (response) {
            result = response[0].cnt;
          }
          return resolve(result);
        }
      );
    });
  },

  getMessagesCountWithUser: uid => {
    return new Promise(resolve => {
      dao.execute(
        "SELECT COUNT(*) cnt FROM Message WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent') AND Message.uid = $uid",
        {$uid: uid},
        response => {
          let result = 0;
          if (response) {
            result = response[0].cnt;
          }
          return resolve(result);
        }
      );
    })
  },

  updateMessage: (id, message) => {
    dao.execute(
      "UPDATE Message SET message = ? WHERE id = ?",
      [message, id],
    );
  },

  deleteAllMessages: () => {
    dao.execute(
      "DELETE FROM Message WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent')",
    );
  },

  deleteAllMessagesWithUser: uid => {
    dao.execute(
      "DELETE FROM Message WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent') AND uid = ?",
      [uid]
    );
  },

  readAllMessages: () => {
    dao.execute(
      "UPDATE Message SET status = 0 WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent') AND status = 1"
    );
  },

  readAllMessagesWithUser: uid => {
    dao.execute(
      "UPDATE Message SET status = 0 WHERE (Message.type = 'text' OR Message.type = 'image' OR Message.type = 'gift_item_sent') AND status = 1 AND uid = ?",
      [uid]
    )
  },

  getRecentVideoCalls: () => {
    let query = "SELECT Message.*, User.*," +
      "(SELECT COUNT(*) FROM Message WHERE Message.uid = User.userId AND Message.status = 1 AND (Message.type = 'video_request' OR Message.type = 'video_chat_end')) AS unreadNumber" +
      " FROM Message INNER JOIN User ON Message.uid = User.userId" +
      " WHERE (Message.type = 'video_request' OR Message.type = 'video_chat_end')" +
      " GROUP BY User.userId ORDER BY Message.id DESC";

    return new Promise(resolve => {
      dao.execute(
        query,
        null,
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push({
                user: item,
                message: item
              });
            });
          }
          return resolve(result);
        }
      );
    });
  },

  getVideoCallsWithUser: uid => {
    return new Promise(resolve => {
      dao.execute(
        "SELECT Message.* FROM Message WHERE (Message.type = 'video_request' OR Message.type = 'video_chat_end') AND Message.uid = $uid",
        {$uid: uid},
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push(item);
            });
          }
          return resolve(result);
        }
      );
    });
  },

  getRecentGiftItems: () => {
    let query = "SELECT Message.*, User.*," +
      "(SELECT COUNT(*) FROM Message WHERE Message.uid = User.userId AND Message.status = 1 AND (Message.type = 'gift_item_sent_in_video')) AS unreadNumber" +
      " FROM Message INNER JOIN User ON Message.uid = User.userId" +
      " WHERE (Message.type = 'gift_item_sent_in_video')" +
      " GROUP BY User.userId ORDER BY Message.id DESC";

    return new Promise(resolve => {
      dao.execute(
        query,
        null,
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push({
                user: item,
                message: item
              });
            });
          }
          return resolve(result);
        }
      );
    });
  },

  getGiftItemsWithUser: uid => {
    return new Promise(resolve => {
      dao.execute(
        "SELECT Message.* FROM Message WHERE (Message.type = 'gift_item_sent_in_video') AND Message.uid = $uid",
        {$uid: uid},
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push(item);
            });
          }
          return resolve(result);
        }
      );
    });
  },

  deleteAllVideoCalls: () => {
    dao.execute(
      "DELETE FROM Message WHERE (Message.type = 'video_request' OR Message.type = 'video_chat_end')"
    );
  },

  deleteAllVideoCallsWithUser: uid => {
    dao.execute(
      "DELETE FROM Message WHERE (Message.type = 'video_request' OR Message.type = 'video_chat_end') AND uid = ?",
      [uid]
    );
  },

  readAllVideoCalls: () => {
    dao.execute(
      "UPDATE Message SET status = 0 WHERE (Message.type = 'video_request' OR Message.type = 'video_chat_end') AND status = 1"
    );
  },

  readAllVideoCallsWithUser: uid => {
    dao.execute(
      "UPDATE Message SET status = 0 WHERE (Message.type = 'video_request' OR Message.type = 'video_chat_end') AND status = 1 AND uid = ?",
      [uid]
    );
  },

  deleteAllGiftItems: () => {
    dao.execute(
      "DELETE FROM Message WHERE (Message.type = 'gift_item_sent_in_video')"
    );
  },

  deleteAllGiftItemsWithUser: uid => {
    dao.execute(
      "DELETE FROM Message WHERE (Message.type = 'gift_item_sent_in_video') AND uid = ?",
      [uid]
    );
  },

  readAllGiftItems: () => {
    dao.execute(
      "UPDATE Message SET status = 0 WHERE (Message.type = 'gift_item_sent_in_video') AND status = 1"
    );
  },

  readAllGiftItemsWithUser: uid => {
    dao.execute(
      "UPDATE Message SET status = 0 WHERE (Message.type = 'gift_item_sent_in_video') AND status = 1 AND uid = ?",
      [uid]
    );
  },

  saveUser: (user, xmppUser = false) => {
    dao.execute(
      "INSERT INTO User VALUES(?,?,?,?,?,?,?,?,?,?,?)",
      [
        xmppUser ? user.userid : user.uid,
        xmppUser ? user.nickname : user.user_nickname,
        xmppUser ? user.sex : user.user_sex,
        xmppUser ? user.age : user.user_age,
        xmppUser ? user.profileimage : user.user_profile_image,
        xmppUser ? user.profilevideo : user.user_profile_video,
        xmppUser ? user.latitude : user.user_latitude,
        xmppUser ? user.longitude : user.user_longitude,
        xmppUser ? (user.like_cnt != null ? user.like_cnt : 0) : user.user_like_cnt,
        xmppUser ? user.countryCode : user.user_country_code,
        xmppUser ? user.class : user.user_class
      ]
    )
  },

  insertNotification: notification => {
    dao.execute(
      "INSERT INTO Notification VALUES(?,?,?,?,?,?,?,?,?,?)",
      [
        null,
        notification.uid,
        notification.user_nickname,
        notification.user_sex,
        notification.user_age,
        notification.user_profile_image,
        notification.message,
        notification.time,
        notification.status,
        notification.type,
        notification.params,
      ]
    )
  },

  getNotifications: (offset, limit) => {
    return new Promise(resolve => {
      dao.execute(
        "SELECT * FROM Notification ORDER BY Notification.id DESC LIMIT $limit OFFSET $offset",
        {$limit: limit, $offset: offset},
        response => {
          let result = [];
          if (response) {
            response.forEach(item => {
              result.push(item);
            });
          }
          return resolve(result);
        }
      );
    });
  },

  getUnreadNotificationNumber: () => {
    return new Promise(resolve => {
      dao.execute(
        "SELECT COUNT(*) cnt FROM Notification WHERE Notification.status = 0",
        null,
        response => {
          let result = 0;
          if (response) {
            result = response[0].cnt;
          }
          return resolve(result);
        }
      );
    });
  },

  deleteAllNotifications: () => {
    dao.execute("DELETE FROM Notification");
  },

  readAllNotifications: () => {
    dao.execute("UPDATE Notification SET status = 1 WHERE status = 0");
  },

  readNotification: id => {
    dao.execute(
      "UPDATE Notification SET status = 1 WHERE status = 0 AND id = ?",
      [id]
    );
  },

  fromDaoEntity: (user) => {
    return {
      uid: user.uid,
      user_nickname: user.nickname,
      user_sex: user.sex,
      user_age: user.age,
      user_profile_image: user.profileImage,
      user_profile_video: user.profileVideo,
      user_latitude: user.latitude,
      user_longitude: user.longitude,
      user_like_cnt: user.likeCnt,
      user_country_code: user.countryCode,
      user_class: user.appClass,
      user_motto: '',
      user_area: 0,
      user_line_busy: 0,
    };
  },

  init: async (emitter) => {
    dao.emitter = emitter;

    const SQL = await initSqlJs({
      locateFile: () => sqlWasm
    });

    await localDB.open();

    let localData = await localDB.getItem();
    if (localData) {
      dao.db = new SQL.Database(new Uint8Array(localData));
    } else {
      dao.db = new SQL.Database();
    }

    dao.checkTables();
  }
};

export default dao;
