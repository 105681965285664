import { printDuration, requestPost, showToast } from "./util";
import store from "../store";
import xmpp from "./xmpp";
import dao from "./dao";
import i18n from "./../i18n";
import { EVENT_NAME } from "./constant";

const vchat = {
  emitter: null,
  isReported: false,

  open: (roomUrl, roomId, maxVideoTime, pointVideo, chatWaitTime, freeChatTime, peerUser, fromUser) => {
    vchat.emitter.emit(EVENT_NAME.VIDEO_CHAT, {
      roomUrl: roomUrl,
      roomId: roomId,
      maxVideoTime: maxVideoTime,
      pointVideo: pointVideo,
      chattingWaitTime: chatWaitTime,
      freeChatTime: freeChatTime,
      peerUser: peerUser,
      fromUser: fromUser
    });
  },

  doEndVideoChat: (deviceTime, seconds, toUser) => {
    const myInfo = store.getters.me;
    requestPost(
      'video/endVideoChat',
      {
        uid: myInfo.uid,
        time: deviceTime,
        to_user: toUser.uid,
        from_user: myInfo.uid,
        video_sec: seconds,
      },
      async response => {
        if (myInfo.user_sex == 1) {
          let receivedCash = Number(response.requester_cash) - Number(myInfo.cash);
          if (receivedCash > 0) {
            showToast(i18n.global.t('video.video_08', {cash: receivedCash}));
          }
        }

        myInfo.points = response.requester_point;
        myInfo.cash = response.requester_cash;
        await store.dispatch('setMyInfo', myInfo);

        xmpp.directVideo = false;

        dao.insertMessage(toUser.uid, 1, i18n.global.t('video.video_call_finished', {time: printDuration(seconds)}), 'video_chat_end', Date.now(), 0);
        vchat.finishWithReview(seconds, toUser);
      },
      (code, msg) => {
        showToast(msg);
        xmpp.directVideo = false;
        vchat.finish(toUser);
      }
    );
  },

  finish: (userInfo) => {
    if (vchat.isReported) {
      vchat.emitter.emit(EVENT_NAME.REPORT, userInfo);
    }
  },

  finishWithReview: (seconds, userInfo) => {
    if (seconds > 0) {
      vchat.emitter.emit(EVENT_NAME.VIDEO_REVIEW, userInfo);
    } else {
      vchat.finish(userInfo);
    }
  },

  jjim: peerUser => {
    const myInfo = store.getters.me;
    requestPost(
      'jjim/createJjim',
      {
        uid: myInfo.uid,
        peer_uid: peerUser.uid
      },
      () => {
        showToast(i18n.global.t('video.video_profile_02', {nickname: peerUser.user_nickname}));
      },
      (code, msg) => {
        showToast(msg);
      }
    );
  },

  transferPointToGiftItem: (target_uid, giftItemId, videoChatTimeInSeconds) => {
    const myInfo = store.getters.me;

    requestPost(
      'iap/transferPointToGiftItem',
      {
        uid: myInfo.uid,
        target_uid: target_uid,
        item_id: giftItemId,
        video_chat_time_in_seconds: videoChatTimeInSeconds
      },
      async response => {
        myInfo.points = response.point;
        myInfo.gift_item1_count = response.gift_item1_count;
        myInfo.gift_item2_count = response.gift_item2_count;
        myInfo.gift_item3_count = response.gift_item3_count;
        myInfo.gift_item4_count = response.gift_item4_count;
        myInfo.gift_item5_count = response.gift_item5_count;
        await store.dispatch('setMyInfo', myInfo);
      },
      (code, msg) => {
        showToast(msg);
      }
    );
  },

  sendGiftItem: (peerUser, fromUser, giftItemId) => {
    const myInfo = store.getters.me;
    // TODO: device time
    requestPost(
      'gift/sendGiftItem',
      {
        uid: myInfo.uid,
        target_uid: peerUser.uid,
        time: '',
        from_user: fromUser.uid,
        to_user: peerUser.uid,
        item_id: giftItemId
      },
      async response => {
        myInfo.gift_item1_count = response.gift_item1_count;
        myInfo.gift_item2_count = response.gift_item2_count;
        myInfo.gift_item3_count = response.gift_item3_count;
        myInfo.gift_item4_count = response.gift_item4_count;
        myInfo.gift_item5_count = response.gift_item5_count;
        await store.dispatch('setMyInfo', myInfo);

        xmpp.sendGiftItemInVideo(peerUser, giftItemId);

        await dao.saveUser(peerUser);
        dao.insertMessage(peerUser.uid, 1, `${giftItemId}`, 'gift_item_sent_in_video', Date.now(), 0);
      },
      (code, msg) => {
        showToast(msg);
      }
    )
  },

  getMyInfo: () => {
    const myInfo = store.getters.me;
    return {
      user_sex: myInfo.user_sex,
      cash: myInfo.cash,
      points: myInfo.points,
      gift_item1_count: myInfo.gift_item1_count,
      gift_item2_count: myInfo.gift_item2_count,
      gift_item3_count: myInfo.gift_item3_count,
      gift_item4_count: myInfo.gift_item4_count,
      gift_item5_count: myInfo.gift_item5_count
    }
  },

  init: function (emitter) {
    vchat.emitter = emitter;
  }
};

export default vchat;
