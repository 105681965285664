<template>
  <transition name="fade">
    <BasePopup :title="$t('gift_item_purchase.title')" @close="$emit('close')">
      <div class="content">
        <img :src="require(`../../assets/img/gift_item${giftItemId + 1}.png`)">
        <span class="gift_item_name">{{ giftItemName }}</span>
        <div class="amount">
          <span>{{ $t('gift_item_purchase.amount') }}</span>
          <a href="javascript:" @click="onMinus()">-</a>
          <span>{{ amount }}</span>
          <a href="javascript:" @click="onPlus()">+</a>
        </div>
        <div class="price">
          <span>{{ $t('gift_item_purchase.amount') }}</span>
          <span>{{ amount * giftItemPoints[giftItemId] }} P</span>
        </div>

        <PrimaryButton style="width: 200px;margin-top: 20px;" :title="$t('gift_items.buy')" @click="onBuy" />
      </div>
    </BasePopup>
  </transition>
</template>
<script>

import { requestPost, showToast } from "../../lib/util";
import { GIFT_ITEM_POINTS } from "../../lib/constant";
import PrimaryButton from "../../components/buttons/PrimaryButton.vue";
import BasePopup from "../../components/popups/BasePopup.vue";

export default {
  name: 'GiftItemPurchasePopup',
  components: {
    PrimaryButton,
    BasePopup
  },
  props: {
    giftItemId: {
      type: Number,
    },
    isInVideo: { type: Boolean, default: false },
    videoInfo: { type: Object, default: null },  // {target_uid, chattingTime}
  },
  emits: ['close', 'purchased'],
  data() {
    return {
      myInfo: this.$store.getters.me,
      amount: 1,
      giftItemPoints: GIFT_ITEM_POINTS,
      popupOpenedTime: 0,
    }
  },
  computed: {
    giftItemName() {
      return [
        this.$t('gift_items.fingerheart'),
        this.$t('gift_items.flowers'),
        this.$t('gift_items.ring'),
        this.$t('gift_items.palace'),
        this.$t('gift_items.megaheart'),
      ][this.giftItemId];
    }
  },
  mounted() {
    this.popupOpenedTime = Date.now();
  },
  methods: {
    onMinus: function () {
      if (this.amount > 1) {
        this.amount = this.amount - 1;
      }
    },
    onPlus: function () {
      this.amount = this.amount + 1;
    },
    onBuy: function () {
      if (GIFT_ITEM_POINTS[this.giftItemId] * this.amount > this.myInfo.points) {
        showToast(this.$t('common.point_alert'));
        return;
      }

      let _app = this;
      requestPost(
        'iap/transferPointToGiftItem',
        {
          uid: this.myInfo.uid,
          target_uid: this.isInVideo ? this.videoInfo.target_uid : 0,
          item_id: this.giftItemId,
          amount: this.amount,
          video_chat_time_in_seconds: this.isInVideo ? this.videoInfo.chattingTime + (Date.now() - this.popupOpenedTime) : 0,
        },
        (response) => {
          _app.myInfo.points = response.point;
          _app.myInfo.gift_item1_count = response.gift_item1_count;
          _app.myInfo.gift_item2_count = response.gift_item2_count;
          _app.myInfo.gift_item3_count = response.gift_item3_count;
          _app.myInfo.gift_item4_count = response.gift_item4_count;
          _app.myInfo.gift_item5_count = response.gift_item5_count;
          _app.$store.dispatch('setMyInfo', _app.myInfo);

          showToast(_app.$t('common.done'));
          _app.$emit('purchased');
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    }
  }
}
</script>

<style scoped>
.content {
  position: relative;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content .gift_item_name {
  color: #333;
  font-size: 15px;
  font-weight: bold;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.content select {
  color: #333;
  background-color: #f5f5f5;
  padding: 15px 20px;
  border-radius: 10px;
  margin-right: 10px;
  width: 100%;
}

.content a {
  font-size: 15px;
  font-weight: 700;
  background-color: #e24c4d;
  color: white;
  border-radius: 15px;
  padding: 15px 0;
  display: block;
  text-align: center;
  margin-top: 10px;
}

.content a:hover {
  background-color: #333;
}

.content .amount {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.content .amount a {
  background-color: #e24c4d;
  color: white;
  border-radius: 20px;
  width: 40px;
  font-size: 15px;
  padding: 12px 0px;
  text-align: center;
}

.content .amount span {
  color: #333;
  font-size: 19px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.content .price {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.content .price span {
  color: #333;
  font-size: 19px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}
</style>